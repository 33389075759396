import React from 'react';
import { AdminContext } from '../../../../utils/store/context/adminContext';
import { useSortableData } from '../../../Helpers/useSortableData';
import SelectENURMYear from '../../../Helpers/SelectENURMYear';

const PostTestConsultAndReview = (props) => {
	const { getUsersWhere } = React.useContext(AdminContext);

	const [searchSubmitted, setSearchSubmitted] = React.useState(false);
	const [reportData, setReportData] = React.useState([]);
	const [reportYear, setReportYear] = React.useState([]);
	const [year, setYear] = React.useState();

	const { items, requestSort } = useSortableData(reportData, {
		key: 'lastName',
		direction: 'descending',
	});

	React.useEffect(() => {
		if (reportYear.length > 0) {
			let date = new Date(reportYear);
			let yr = date.getFullYear() + 1;
			setYear(yr);
		}

		return () => {
			setSearchSubmitted(false);
		};
		// eslint-disable-next-line
	}, [reportYear]);

	const handleSubmit = async () => {
		let data = await getUsersWhere(`isClient_${year}`, '==', true);
		console.info('Report Data:', data);

		let users = data.filter((u) => u.hasOwnProperty('post_test_consultation'));
		console.info('Users:', users);
		// getActiveUsers(data);
		setReportData(users);
		setSearchSubmitted(true);
	};

	return (
		<div style={{ overflowX: 'auto' }}>
			<h4>Post Test Consultation SUrvey</h4>
			<SelectENURMYear onChange={(yr) => setReportYear(yr)} />
			{!searchSubmitted && (
				<div className='input-field col s12'>
					<button
						type='submit'
						className='btn bg-brand'
						disabled={year === undefined}
						onClick={handleSubmit}>
						Submit
					</button>
				</div>
			)}
			{searchSubmitted && (
				<div>
					{reportData.length > 0 ? (
						<table className='post-test-review mt-2'>
							<thead>
								<tr>
									<td>
										<span
											className='c-pointer'
											onClick={() => requestSort('firstName')}>
											Signup
										</span>
										Nombre
									</td>
									<td>
										<span
											className='c-pointer'
											onClick={() => requestSort('lastName')}>
											Apellido
										</span>
									</td>
									<td>Ced</td>
									<td>Grade</td>
									<td>Esp1</td>
									<td>Esp2</td>
									<td>Review</td>
									<td>Rating</td>
								</tr>
							</thead>
							<tbody>
								{/* user.post_test_consultation || user.post_test_review */}
								{reportData &&
									items
										.filter((user) =>
											user.hasOwnProperty('post_test_consultation')
										)
										.map((user) => {
											return (
												<tr>
													<td>{user?.firstName}</td>
													<td>{user?.lastName}</td>
													{/* <td>{user?.cedula}</td> */}
													<td>{user?.email}</td>
													<td>{user?.post_test_consultation?.nota_final}</td>
													<td>
														{
															user?.post_test_consultation
																?.especialidad_target_1
														}
													</td>
													<td>
														{
															user?.post_test_consultation
																?.especialidad_target_2
														}
													</td>

													<td>{user?.post_test_review?.feedback}</td>
													<td>{user?.post_test_review?.rating}</td>
												</tr>
											);
										})}
							</tbody>
						</table>
					) : (
						<div>
							<p>No users found...</p>
						</div>
					)}
				</div>
			)}
		</div>
	);
};

export default PostTestConsultAndReview;
