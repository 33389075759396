// import { DEBUG_RED, DEBUG_GREEN } from './store/context/types';

/**
 * Takes in the event and validates input field
 */
export const checkValidation = (event) => {
	// let type = event.target.type;
	// console.info('Event Type:', type, 'Valid?', event.target.validity);

	// if (event.target.validity.valid) {
	// 	console.info('%c Valid input', DEBUG_GREEN);
	// } else {
	// 	console.info(`%c Input NOT valid`, DEBUG_RED);
	// }

	event.target.setCustomValidity('');
	// The input field must call onInvalid={showInvalidMessage}
	event.target.checkValidity();
};

/**
 * Takes 2 params: "event" and "message", then displays invalid message
 */
export const showInvalidMessage = (e, text) => {
	// e.stopPropagation();
	// console.info('Name Valid:', e.target.validity.valid);
	e.target.setCustomValidity(text);
	// e.target.reportValidity();
};

//? CUSTOM VALIDATIONS WITH:
// let regex = RegExp('\\s');
// regex.test(e.target.value) // RETURNS NULL if no match, value if match is found
