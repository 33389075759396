import React, { useRef } from 'react';
import LogoDisplay from './LogoDisplay';
import { deleteAllCookies, redirectToLogin } from '../../utils/helpers';
import { AdminContext } from '../../utils/store/context/adminContext';

const ContactUs = () => {
	const { addContactFormMessage } = React.useContext(AdminContext);
	const [allFieldsValid, setAllFieldsValid] = React.useState(false);
	const [showConfirmation, setShowConfirmation] = React.useState(false);
	const [emailInvalid, setEmailInvalid] = React.useState(false);
	const [message, setMessage] = React.useState({});

	//!REFS
	const name = useRef();
	const email = useRef();
	const text = useRef();

	const checkFieldsValidity = () => {
		if (
			message.name?.length > 0 &&
			message.email?.length > 0 &&
			message.text?.length > 0
		) {
			setAllFieldsValid(true);
		}
	};

	const handleSubmit = (e) => {
		if (allFieldsValid) {
			e.preventDefault();
			addContactFormMessage(message);
			setShowConfirmation(true);
		}
	};

	const reportInvalid = (e) => {
		setEmailInvalid(true);
		e.target.setCustomValidity('El correo ingresado es inválido');
		e.target.reportValidity();
		email.current.value = '';
		e.target.setCustomValidity('');
	};

	return (
		<div style={{ minHeight: '100vh' }}>
			<div className='container'>
				<div className='w-75 mx-auto hide-on-small-and-down'>
					<LogoDisplay />
				</div>
				{showConfirmation ? (
					<div className='mt-4 center'>
						<h3 className='txt-success mt-2'>
							<i className='material-icons fsize-larger mr-2'>check_circle</i>
							¡Tu mensaje fue enviado!
						</h3>
						<p className='left-align mx-auto w-65'>
							Gracias por contactarnos. Tu mensaje es importante para nosotros y
							por eso todos los correos son revisados y respondidos en orden de
							llegada. Estaremos en contacto lo antes posible.
						</p>
					</div>
				) : (
					<div>
						<h3 className='txt-brand2 mt-2 center'>
							<i className='material-icons fsize-larger mr-2'>
								contact_support
							</i>
							¿Necesitas comunicarte con nosotros?
						</h3>
						<div className='row mt-2'>
							{/* //~ INFORMATION */}
							<div className='col m4 s12 mt-2 mb-4'>
								<p>
									¿Tienes alguna pregunta, duda, observación o queja? ¡Por favor
									no dudes en contactarnos! Si tienes fotos o videos que
									enviarnos por favor escríbenos a nuestro{' '}
									<a href='mailto:ayuda@prepmed.org'>correo de soporte</a> al
									cliente: ayuda@prepmed.org.
								</p>
								<p>
									<strong>Nota:</strong> Si estás teniendo dificultades para
									cargar alguna página, trata el botón debajo para recargar la
									aplicación e intenta de nuevo. Si todavía estás teniendo
									inconvenientes, por favor escríbenos.
								</p>
								<div className='mt-2 center'>
									<button
										className='btn bg-brand mr-2'
										onClick={() => {
											deleteAllCookies();
											redirectToLogin();
										}}>
										<i className='material-icons left'>cached</i>
										Recargar App
									</button>
								</div>
							</div>

							{/* //~CONTACT FORM */}
							<div className='col m8 s12'>
								<h5 className='center txt-brand2 mt-0'>¡Escríbenos!</h5>
								<form
									className='ml-4 mt-2'
									onChange={() => {
										setMessage({
											name: name.current.value,
											email: email.current.value,
											text: text.current.value,
										});
										checkFieldsValidity();
									}}
									onSubmit={handleSubmit}>
									{/* //~ NAME */}
									<div className='input-field'>
										<i className='material-icons prefix txt-brand2'>
											account_box
										</i>
										<label
											htmlFor='nombre'
											onClick={() => name.current.focus()}>
											Nombre
										</label>
										<input type='text' max='20' ref={name} required />
									</div>
									{/* //~ EMAIL */}
									<div className='input-field'>
										<i className='material-icons prefix txt-brand2'>email</i>
										<label
											htmlFor='email'
											onClick={() => email.current.focus()}>
											Correo
										</label>
										<input
											type='email'
											title='Debe ser un correo válido.'
											// pattern='^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$'
											ref={email}
											onInvalid={reportInvalid}
											onChange={(e) => {
												setEmailInvalid(false);
												// e.target.setCustomValidity('');
											}}
											required
										/>
										{emailInvalid && (
											<p className='center txt-danger'>
												El correo es invalido.
											</p>
										)}
									</div>
									{/* //~ TEXT AREA */}
									<div className='input-field'>
										<i className='material-icons prefix txt-brand2'>message</i>
										<textarea
											className='materialize-textarea'
											ref={text}
											required></textarea>
										<label
											htmlFor='message'
											onClick={() => text.current.focus()}>
											Mensaje
										</label>
									</div>
									<div className='right-align'>
										<button
											className='btn btn-large bg-brand'
											disabled={!allFieldsValid}
											type='submit'>
											Enviar
										</button>
									</div>
								</form>
							</div>
						</div>
					</div>
				)}
			</div>
		</div>
	);
};

export default ContactUs;
