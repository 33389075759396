import React, { useContext, useRef, useState } from 'react';
import logo from '../../../assets/logo/LOGO_BLUE_FULL_COLOR.png';
import { AuthContext } from '../../../utils/store/context/authContext';
function ForgotPassword() {
	const { resetPassword } = useContext(AuthContext);
	const email = useRef();
	const [successMessage, setSuccessMessage] = useState();
	const [failMessage, setFailMessage] = useState();

	const handleSubmit = async (e) => {
		e.preventDefault();
		setFailMessage();
		setSuccessMessage();
		const res = await resetPassword(email.current.value);
		if (res === 'success') {
			setSuccessMessage(
				'Se ha enviado un enlace a tu correo. Puede tomar hasta 10 minutos en llegar.'
			);
		}
		if (res === 'error') {
			setFailMessage(
				'Hubo un problema con tu solicitud, por favor revisa el correo ingresado e intenta denuevo.'
			);
		}
	};

	return (
		<div className='container'>
			<div className='center'>
				{window.innerWidth > 790 && (
					<img
						src={logo}
						alt='prepmed-logo'
						className='img-reponsive w-65 pt-5'
					/>
				)}
				{window.innerWidth < 790 && <div className='py-4'></div>}
			</div>
			<form onSubmit={handleSubmit}>
				<div className='center'>
					<h5>Recuperación de la cuenta</h5>

					<p>
						Ingresa tu correo electrónico y recibirás un enlace para cambiar tu
						contraseña.
					</p>
					{successMessage && (
						<p className='py-4 txt-success'>{successMessage}</p>
					)}
					{failMessage && <p className='py-4 txt-danger'>{failMessage}</p>}
				</div>
				<div className='row pt-4'>
					<div className='col m8 s12'>
						<div className='input-field'>
							<i className='material-icons prefix txt-brand2'>email</i>
							<label htmlFor='email'>Correo Electrónico</label>
							<input type='email' id='email' ref={email} autoComplete='null' />
						</div>
					</div>
					<div className='col m4 s12'>
						<button
							className='btn btn-large bg-brand'
							type='submit'
							value='Submit'>
							Siguiente
						</button>
					</div>
				</div>
			</form>
		</div>
	);
}

export default ForgotPassword;
