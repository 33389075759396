import React, { useContext, useEffect, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { AuthContext } from '../../../utils/store/context/authContext';
import TestRecordTableRow from './TestRecordTableRow';

function TestRecordTable({ records }) {
	const { userTestRecords, getUserTestRecordsFromDB } = useContext(AuthContext);
	const [testRecords, setTestRecords] = useState(records);

	const getLatestRecords = () => {
		setTestRecords([]);
		getUserTestRecordsFromDB();
	};

	useEffect(() => {
		setTestRecords(userTestRecords);
		// return () => {
		// 	cleanup
		// }
	}, [userTestRecords]);

	return (
		<section className='section-1 col s12'>
			<div className='container'>
				<div
					className='d-flex'
					style={{ justifyContent: 'space-between', alignItems: 'baseline' }}>
					<h3>Récord de Exámenes</h3>

					<button class='btn bg-brand' onClick={getLatestRecords}>
						<i class='material-icons left'>cached</i>Actualizar
					</button>
				</div>
				<table id='test-records'>
					<thead>
						<tr>
							<td style={{ width: '20%' }}>Date</td>
							<td style={{ width: '20%', textAlign: 'center' }}>Hora</td>
							<td style={{ width: '20%', textAlign: 'center' }}>Modo</td>
							<td style={{ width: '15%', textAlign: 'center' }}>
								{window.innerwidth ? 'Preguntas' : '# de Preguntas'}
							</td>
							<td style={{ width: '10%', textAlign: 'center' }}>Puntuación</td>
							<td style={{ width: '15%', textAlign: 'center' }}>Detalles</td>
						</tr>
					</thead>
					<tbody>
						{testRecords &&
							testRecords
								.sort((a, b) => {
									return b.date - a.date;
								})
								.map((record) => {
									return <TestRecordTableRow record={record} key={uuidv4()} />;
								})}
					</tbody>
				</table>
			</div>
		</section>
	);
}

export default TestRecordTable;
