import React from 'react';

function Privacy() {
	return (
		<div className='container'>
			<h2>
				<i className='material-icons fsize-larger mr-2'>privacy_tip</i>
				Política de Privacidad
			</h2>
			<p>
				Su privacidad es importante para nosotros. La política de PrepMed LLC es
				respetar su privacidad en relación a cualquier información suya que
				podamos recopilar en web, , y otros sitios de nuestra propiedad y en los
				que operamos.
			</p>
			<h3>1. Información que recopilamos</h3>
			<h4>Dato de registro</h4>
			<p>
				Cuando visita nuestra web, nuestros servidores podrá automáticamente
				registrar los datos estándar facilitados por tu navegador web. Puede
				incluir la dirección de Protocolo de Internet (IP) de su ordenador, tu
				el tipo y la versión de su navegador, las páginas que visita, la hora y
				fecha de su visita, el tiempo dedicado a cada página y otros detalles.
			</p>
			<h4>Datos del dispositivo</h4>
			<p>
				También podemos recopilar datos sobre el dispositivo que está utilizando
				para acceder a nuestro sitio web. Estos datos pueden incluir el tipo de
				dispositivo, el sistema operativo, los identificadores únicos del
				dispositivo, la configuración del dispositivo y los datos de ubicación
				geográfica. Lo que recopilamos puede depender de la configuración
				individual de su dispositivo y software. Recomendamos consultar las
				políticas del fabricante de su dispositivo o del proveedor de software
				para saber qué información nos ponen a nuestra disposición.
			</p>
			<h4>Información personal</h4>
			<p>Podemos solicitar información personal, como:</p>
			<ul>
				<li>Nombre</li>
				<li>Correo electrónico</li>
				<li>Perfiles de redes sociales</li>
				<li>Fecha de nacimiento</li>
				<li>Número de teléfono/móvil</li>
				<li>Domicilio/dirección postal</li>
				<li>Información de pago</li>
			</ul>
			<h3>2. Bases legales de procesamiento</h3>
			<p>
				Procesaremos su información personal de manera legal, justa y
				transparente. Recopilamos y procesamos información sobre usted solo
				donde tenemos bases legales para hacerlo.
			</p>
			<p>
				Estas bases legales dependen de los servicios que usa y de cómo los usa,
				lo que significa que recopilamos y usamos su información solo cuando:
			</p>
			<ul>
				<li>
					es necesario para la ejecución de un contrato en el que usted es parte
					o para comenzar a avanzar en su solicitud antes de comenzar con dicho
					contrato (por ejemplo, cuando le ofrecemos un servicio que nos
					solicita);
				</li>
				<li>
					satisface un interés legítimo (que no está anulado por sus intereses
					de protección de datos), como investigación y desarrollo, para
					comercializar y promover nuestros servicios, y para proteger nuestros
					derechos e intereses legales;
				</li>
				<li>
					nos da su consentimiento para hacerlo con un propósito específico (por
					ejemplo, puede darnos su consentimiento para que le enviemos nuestro
					boletín informativo); o
				</li>
				<li>
					necesitamos procesar sus datos para cumplir con una obligación legal.
				</li>
			</ul>
			<p>
				Cuando usted acepta nuestro uso de su información para un propósito
				específico, tiene derecho a cambiar de opinión en cualquier momento
				(pero esto no afectará a ningún procesamiento que ya haya tenido lugar).
			</p>
			<p>
				No guardamos información personal por más tiempo del necesario. Mientras
				conservamos esta información, la protegeremos con medios comercialmente
				aceptables para evitar pérdidas y robos, así como el acceso,
				divulgación, copia, uso o modificación no autorizados. Dicho esto,
				recordamos que ningún método de transmisión o almacenamiento electrónico
				es 100% seguro y no pueden garantizar la seguridad absoluta de los
				datos. Si es necesario, podemos conservar su información personal para
				cumplir con una obligación legal o para proteger sus intereses vitales o
				los intereses vitales de otra persona física.
			</p>
			<h3>3. Recopilación y uso de la información</h3>
			<p>
				Podemos recopilar, retener, usar y divulgar información para los
				siguientes fines y la información personal no se procesará de manera
				incompatible con estos fines:
			</p>
			<ul>
				<li>
					para permitirle personalizar su experiencia en nuestro sitio web;
				</li>
				<li>
					para permitirle acceder y utilizar nuestro sitio web, aplicaciones
					asociadas y plataformas de redes sociales asociadas;
				</li>
				<li>para contactar y comunicar con usted;</li>
				<li>para registro interno y fines administrativos;</li>
				<li>
					para análisis, investigación de mercado y desarrollo comercial, que
					incluye operar y mejorar nuestro sitio web, aplicaciones asociadas y
					plataformas de redes sociales asociadas;
				</li>
				<li>
					para promover competiciones y/u ofrecer beneficios adicionales para
					usted;
				</li>
				<li>
					para publicidad y marketing, incluido el envío de información
					promocional sobre nuestros productos y servicios e información sobre
					terceros que consideramos pueden ser de su interés; y
				</li>
				<li>
					para cumplir con nuestras obligaciones legales y resolver cualquier
					disputa que podamos tener.
				</li>
			</ul>
			<h3>4. Divulgación de información personal a terceras partes</h3>
			<p>Podemos divulgar información personal a:</p>
			<ul>
				<li>
					proveedores de servicios de tercera parte con el fin de permitirles
					brindar sus servicios, incluidos (sin limitación) proveedores de
					servicios de informática, almacenamiento de datos, servidores de
					alojamientos web y servidores, redes publicitarias, analíticas,
					registradores de errores, cobradores de deudas, proveedores de
					servicios de mantenimiento o resolución de problemas, proveedores de
					marketing o publicidad, asesores profesionales y operadores de
					sistemas de pago;
				</li>
				<li>nuestros empleados, contratistas y/o entidades relacionadas;</li>
				<li>
					patrocinadores o promotores de cualquier competición organizada por
					nuestra empresa;
				</li>
				<li>
					agencias que reportan informes de crédito, juzgados, tribunales y
					autoridades reguladoras, en el caso de que no pague por los bienes o
					servicios que le hayamos proporcionado;
				</li>
				<li>
					juzgados, tribunales, autoridades reguladoras y funcionarios
					encargados de hacer cumplir la ley, en relación con cualquier
					procedimiento legal actual o potencial para establecer, ejercer o
					defender nuestros derechos legales;
				</li>
				<li>
					terceros, incluidos agentes o subcontratistas, que nos ayudan a
					proporcionarle información, productos, servicios o marketing directo;
					y
				</li>
				<li>terceras partes que recopilan y procesan datos.</li>
			</ul>
			<h3>5. Transferencias Internacionales de información personal</h3>
			<p>
				La información personal que recopilamos se almacena y procesa en Estados
				Unidos, o donde nosotros o nuestros socios, afiliados y proveedores
				externos tengamos instalaciones. Al proporcionarnos su información
				personal, usted acepta la divulgación a estos países terceros.{' '}
			</p>
			<p>
				Nos aseguraremos de que cualquier transferencia de información personal
				desde países del Espacio Económico Europeo (EEE) a países fuera del EEE
				se proteja mediante las medidas apropiadas, por ejemplo, mediante el uso
				de cláusulas de protección de datos estándar aprobadas por la Comisión
				Europea o el uso normas vinculantes corporativas u otros medios
				legalmente aceptados.
			</p>
			<p>
				Cuando transferimos información personal de un país no perteneciente al
				EEE a otro país, usted reconoce que terceros en otras jurisdicciones
				pueden no estar sujetos a leyes de protección de datos similares a las
				de nuestra jurisdicción. Existen riesgos si cualquiera de dichas
				terceras partes se involucra en algún acto o práctica que contravenga
				las leyes de privacidad de los datos en nuestra jurisdicción y esto
				podría significar que no podrá solicitar una rectificación conforme a
				las leyes de privacidad de nuestra jurisdicción.
			</p>
			<h3>6. Sus derechos y el control de su información personal</h3>
			<p>
				<strong>Elección y consentimiento:</strong> Al proporcionarnos
				información personal, usted nos autoriza a recopilar, retener, usar y
				divulgar su información personal de acuerdo con esta política de
				privacidad. Si tiene menos de 16 años de edad, debe tener y garantizar,
				en la medida en que lo permita la ley, que tiene el permiso de su padre
				o tutor legal para acceder y utilizar la web y que ellos (sus padres o
				tutor) han dado su consentimiento para que usted nos proporcione su
				información personal. No es necesario que nos proporcione información
				personal; sin embargo, si no lo hace, esto puede afectar al uso de la
				web o los productos y/o servicios ofrecidos a través de él.
			</p>
			<p>
				<strong>Información de terceras partes:</strong> Si recibimos
				información personal sobre usted de un tercero, la protegeremos tal como
				se establece en esta política de privacidad. Si usted es un tercero que
				proporciona información personal sobre otra persona, entonces está
				declarando y garantizando que cuenta con el consentimiento de dicha
				persona para proporcionarnos la información personal.
			</p>
			<p>
				<strong>Restricciones:</strong> Puede optar por restringir la
				recopilación o el uso de su información personal. Si anteriormente ha
				aceptado que usemos su información personal con fines de marketing
				directo, puede cambiar de opinión en cualquier momento comunicándose con
				nosotros mediante los canales indicados más abajo. Si nos solicita
				restringir o limitar la forma en que procesamos su información personal,
				le informaremos cómo la restricción afecta su uso de nuestra web o
				nuestros productos y servicios.
			</p>
			<p>
				<strong>Acceso y portabilidad de datos:</strong> Puede solicitar
				detalles de la información personal que tenemos sobre usted. Usted puede
				solicitar una copia de la información personal que tenemos sobre usted.
				Cuando sea posible, proporcionaremos esta información en formato CSV u
				otro formato de fácil lectura. Usted puede solicitar que borremos la
				información personal que tenemos sobre usted en cualquier momento.
				También puede solicitar que transfiramos esta información personal a un
				tercero.
			</p>
			<p>
				<strong>Corrección:</strong> Si cree que cualquier información que
				tengamos sobre usted es incorrecta, está desactualizada, es incompleta,
				es irrelevante o engañosa, comuníquese con nosotros utilizando los
				canales indicados a continuación. Tomaremos las medidas necesarias para
				corregir cualquier información que sea inexacta, incompleta, engañosa o
				esté desactualizada.
			</p>
			<p>
				<strong>Notificación de violaciones de datos:</strong> Cumpliremos con
				las leyes que nos sean aplicables con respecto a cualquier violación de
				datos.
			</p>
			<p>
				<strong>Reclamaciones:</strong> Si usted considera que hemos infringido
				una ley de protección de datos relevante y desea presentar una queja,
				comuníquese con nosotros utilizando los canales indicados a continuación
				y proporcionando todos los detalles de la supuesta infracción.
				Investigaremos inmediatamente su queja y le responderemos, por escrito,
				explicando el resultado de nuestra investigación y los pasos que
				tomaremos para resolver su reclamación. Usted también tiene derecho a
				comunicarse con un organismo regulador o autoridad de protección de
				datos en relación con su queja.
			</p>
			<p>
				<strong>Cancelar la suscripción:</strong> Para cancelar la suscripción a
				nuestra base de datos de correos electrónicos o para cancelar la
				suscripción de las comunicaciones (incluidas las comunicaciones de
				marketing), comuníquese con nosotros utilizando los canales indicados a
				continuación o cancele la suscripción utilizando los servicios para ello
				facilitados en la comunicación.
			</p>
			<h3>7. Cookies</h3>
			<p>
				Utilizamos "cookies" para recopilar información sobre usted y su
				actividad en nuestro sitio. Una cookie es un pequeño dato que nuestro
				sitio web almacena en su computadora y accede cada vez que lo visita,
				para que podamos entender cómo lo utiliza. Esto nos ayuda a brindarle
				contenido basado en las preferencias que ha especificado. Por favor,
				consulte nuestra Política de Cookies para más información.
			</p>
			<h3>8. Transferencias de negocios</h3>
			<p>
				Si nosotros o nuestros activos son adquiridos, o en el improbable caso
				de que salgamos del negocio o entremos en quiebra, incluiríamos datos,
				entre los activos transferidos, a cualquier parte que nos adquiera.
				Usted reconoce que tales transferencias pueden ocurrir y que cualquier
				tercera parte que nos adquiera puede seguir usando su información
				personal de acuerdo con esta política.
			</p>
			<h3>9. Límites de nuestra Política</h3>
			<p>
				Nuestro website puede enlazar con sitios externos que no están
				gestionados por nosotros. Tenga en cuenta que no tenemos control sobre
				el contenido y las políticas de esos sitios, y no podemos aceptar
				responsabilidades acerca de sus respectivas prácticas de privacidad.
			</p>
			<h3>10. Cambios de esta Política</h3>
			<p>
				A nuestra discreción, podemos cambiar nuestra política de privacidad
				para reflejar las prácticas aceptables actuales. Tomaremos las medidas
				necesarias para informar a los usuarios sobre los cambios a través de
				nuestro web. El uso continuado de este sitio después de cualquier cambio
				en esta política se considerará como aceptación de nuestras prácticas
				sobre privacidad e información personal.
			</p>
			<p>
				Si realizamos un cambio significativo en esta política de privacidad,
				por ejemplo, cambiando una base legal sobre la cual procesamos su
				información personal, le pediremos que vuelva a dar su consentimiento a
				la política de privacidad modificada.
			</p>
			<p>Esta política es efectiva desde 1 de noviembre de 2020.</p>
		</div>
	);
}

export default Privacy;
