import React from 'react';

const Covid19 = () => {
	React.useEffect(() => {
		window.location.replace('https://mailchi.mp/7f8cb4456aec/covid19');
		// return () => {
		//     cleanup
		// }
	}, []);
	return <div>Thank you...</div>;
};

export default Covid19;
