import React, { useContext, useEffect, useState } from 'react';
import { FirestoreContext } from '../../../../../utils/store/context/firestoreContext';

const SelectSubCategories = (props) => {
	/* ===== FIREBASE CONTEXT =====*/
	const { getCategories, categories } = useContext(FirestoreContext);
	// const [getCount, setGetCount] = useState(true);
	// if (getCount) {
	// 	getCategories();
	// 	setGetCount(false);
	// }
	const { changeFn } = props;

	const [selectedCat, setSelectedCat] = useState('');
	const [category, setCategory] = useState('');
	const [subCategory, setSubCategory] = useState('');
	const [showSubCat, setShowSubCat] = useState(false);
	/* ===== OTHER VARIABLES =====*/
	let selectedCategory;
	let sub_cat_select;

	/* eslint-disable */
	useEffect(() => {
		getCategories();
	}, []);
	// Get subCategories
	useEffect(() => {
		if (showSubCat) {
			sub_cat_select = document.querySelector('select#subCategory');
			selectedCategory = categories.filter((cat) => {
				return cat.name === category;
			});

			setSelectedCat(...selectedCategory);

			selectedCategory[0].sub_categories
				? selectedCategory[0].sub_categories.forEach((option) => {
						sub_cat_select.insertAdjacentHTML(
							'beforeend',
							`<option value="${option.name}"
			                            key="${option.name}">${option.name}</option>`
						);
				  })
				: setShowSubCat(false);
		}
	}, [showSubCat, category]);

	const handleSelectChange = (e) => {
		let target = e.currentTarget.id;
		if (target === 'category') {
			setCategory('');
			setSubCategory('');
			setShowSubCat(false);
			setCategory(e.target.value);
			setShowSubCat(true);
		} else if (target === 'subCategory') {
			changeFn(e);
			setSubCategory(e.target.value);
			// setShowSpecialization(true);
			// HERE GOES PROP
		} else {
			console.log("There's an issue");
		}
	};

	return (
		<div>
			<div className='input-field'>
				{/* ===== SELECT A CATEGORY ===== */}
				<select
					className='browser-default'
					id='category'
					defaultValue={selectedCat}
					onChange={handleSelectChange}
					tabIndex='1'>
					<option value='' disabled>
						-- Select a category --
					</option>
					{categories &&
						categories.map((option) => {
							return (
								<option
									value={`${option.name}`}
									key={`${option.id}`}>{`${option.name}`}</option>
							);
						})}
				</select>

				<label htmlFor='category'></label>
			</div>
			{/* ===== SELECT A SUBCATEGORY ===== */}
			{showSubCat && (
				<div className='input-field'>
					<select
						className='browser-default'
						id='subCategory'
						defaultValue={subCategory}
						onChange={handleSelectChange}
						required
						tabIndex='2'>
						<option value='' disabled>
							-- Select a subcategory --
						</option>
					</select>
					<label htmlFor='subCategory'> </label>
				</div>
			)}
		</div>
	);
};

export default SelectSubCategories;
