import React from 'react';
import { FirestoreContext } from '../../../../utils/store/context/firestoreContext';

const NeedsVideo = ({ id, question }) => {
	const { updateQuestionbyKey } = React.useContext(FirestoreContext);
	const [checked, setChecked] = React.useState(question?.needs_video);
	return (
		<div className='input-field col s12'>
			<label htmlFor='needs_video'>
				<input
					className='filled-in'
					id='needs_video'
					type='checkbox'
					onChange={(e) => {
						updateQuestionbyKey(id, 'needs_video', !checked);
						setChecked(!checked);
					}}
				/>
				<span>Question needs video (saves change automatically)</span>
			</label>
		</div>
	);
};

export default NeedsVideo;
