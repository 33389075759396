import React, { useContext } from 'react';
import { FirestoreContext } from '../../../../utils/store/context/firestoreContext';
import parse from 'html-react-parser';

const Fuente = ({ id }) => {
	const { questions } = useContext(FirestoreContext);
	let currentQuestion;
	currentQuestion = questions.filter((q) => {
		return q.id === id;
	})[0];

	return (
		<React.Fragment>
			{currentQuestion.fuente && (
				<blockquote
					style={{ borderLeftColor: 'var(--gray)' }}
					className='fuente'>
					<strong>Fuente:</strong>
					<div>{parse(currentQuestion.fuente)}</div>
				</blockquote>
			)}
		</React.Fragment>
	);
};

export default Fuente;
