import React, { useEffect, useContext, useState } from 'react';
import { FirestoreContext } from '../../../utils/store/context/firestoreContext';
import ProgressBar from '../../Helpers/ProgressBar';

const QuestionsProgressBar = ({ records }) => {
	const { questions, getQuestions } = useContext(FirestoreContext);
	const [uniqueQuestions, setUniqueQuestions] = useState();
	const [percent, setPercent] = useState(0);

	useEffect(() => {
		console.info(percent);
	}, [percent]);

	useEffect(() => {
		uniqueQuestions && calculatePercent();
	}, [uniqueQuestions]);

	useEffect(() => {
		getQuestions();
		getQuestionsTaken(records);
		// return () => {
		//     cleanup
		// }
	}, [records]);

	const getQuestionsTaken = (records) => {
		let arrayOfQuestions = [];
		records.forEach((r) => {
			arrayOfQuestions.push(...r.questions);
		});
		getQuestionIDs(arrayOfQuestions);
	};

	const getQuestionIDs = (allQuestions) => {
		let arrayOfIDs = [];
		allQuestions.forEach((q) => {
			arrayOfIDs.push(q.questionID);
		});
		makeSetOfIDs(arrayOfIDs);
	};

	const makeSetOfIDs = (allIDs) => {
		let set = new Set(allIDs);
		setUniqueQuestions(set);
	};

	const calculatePercent = () => {
		let value = uniqueQuestions.size / questions.length;
		let round = Math.round(value * 100);
		setPercent(round);
	};

	return (
		<section className='section-2' style={{ background: '#f0f9ff' }}>
			<div className='container'>
				<h3>Preguntas Realizadas</h3>
				<h6>
					Porcentaje del banco PrepMed que has completado hasta la fecha:{' '}
					<strong>{percent}%</strong>
				</h6>
			</div>

			{/* PROGRESS BAR */}
			{percent > 0 && <ProgressBar width={100} percent={percent} />}
		</section>
	);
};

export default QuestionsProgressBar;
