import React from 'react';

const SelectFromEspecialidades = ({ handleChange, id }) => {
	const ref = React.useRef();

	return (
		<select
			name={id}
			id={id}
			ref={ref}
			className='browser-default'
			style={{ marginLeft: '3rem', width: '90%' }}
			onChange={(e) => handleChange(e.target.value)}
			defaultValue={'default'}
			required>
			<option value='default' disabled>
				- Selecciona Una -
			</option>
			<option value='anatomia-patologica'>Anatomía Patológica</option>
			<option value='anestesiologia'>Anestesiología</option>
			<option value='cirugia-buco-maxilo-facial'>
				Cirugía Buco-Maxilo-Facial
			</option>
			<option value='cirugia-general'>Cirugía General</option>
			<option value='cirugia-pediatrica'>Cirugía Pediátrica</option>
			<option value='cirugia-plastica-reconstructiva'>
				Cirugía Plástica y Reconstructiva
			</option>
			<option value='citologia'>Citología</option>
			<option value='cuidados-intensivos'>Cuidados Intensivos</option>
			<option value='dermatogia'>Dermatología</option>
			<option value='diabetologia'>Diabetología</option>
			<option value='emergenciologia'>Emergenciología</option>
			<option value='fisiatria'>Fisiatría</option>
			<option value='geriatria'>Geriatría</option>
			<option value='gineco-obstetricia'>Gineco-Obstetricia</option>
			<option value='imagenologia'>Imagenología</option>
			<option value='medicina-familiar'>Medicina Familiar</option>
			<option value='medicina-forense'>Medicina Forense</option>
			<option value='medicina-interna'>Medicina Interna</option>
			<option value='neurocirugia'>Neurocirugía</option>
			<option value='neurologia'>Neurología</option>
			<option value='nutricion-hospitalaria'>Nutrición Hospitalaria</option>
			<option value='oftalmogogia'>Oftalmología</option>
			<option value='ortopedia-traumatologia'>Ortopedia y Traumatología</option>
			<option value='otorrinolaringologia'>Otorrinolaringología</option>
			<option value='pediatria'>Pediatría</option>
			<option value='psiquiatria'>Psiquiatría</option>
			<option value='radioterapia'>Radioterapia</option>
			<option value='salud-publica-epidemiologia'>
				Salud Publica y Epidemiología
			</option>
			<option value='urologia'>Urología</option>
		</select>
	);
};

SelectFromEspecialidades.propTypes = {};

export default SelectFromEspecialidades;
