import React, { useEffect } from 'react';
import { AdminContext } from '../../../../utils/store/context/adminContext';
import { useSortableData } from '../../../Helpers/useSortableData';
import SelectENURMYear from '../../../Helpers/SelectENURMYear';

const Sales = (props) => {
	const { getUsersWhere } = React.useContext(AdminContext);

	const [isMobile, setIsMobile] = React.useState();
	const [searchSubmitted, setSearchSubmitted] = React.useState(false);
	const [reportData, setReportData] = React.useState([]);
	const [reportYear, setReportYear] = React.useState([]);
	const [year, setYear] = React.useState();

	const { items, requestSort } = useSortableData(reportData, {
		key: 'lastName',
		direction: 'descending',
	});

	React.useEffect(() => {
		if (reportYear.length > 0) {
			let date = new Date(reportYear);
			let yr = date.getFullYear() + 1;
			setYear(yr);
		}

		return () => {
			setSearchSubmitted(false);
		};
		// eslint-disable-next-line
	}, [reportYear]);

	const handleSubmit = async () => {
		// let data = await getUsersWhere(`isClient_${year}`, '==', true);
		let data = await getUsersWhere(`isClient_${year}`, 'in', [true, false]);
		console.info('Report Data:', data);

		setReportData(data);
		setSearchSubmitted(true);
	};

	useEffect(() => {
		setIsMobile(window.innerWidth < 768);
		// eslint-disable-next-line
	}, []);

	var yesterday = new Date();
	yesterday.setDate(yesterday.getDate() - 1);
	let salesToday = reportData.filter((user) => {
		let dateBoolean = user.signUpDate.toMillis() > yesterday.getTime();
		return user[`isClient_${year}`] && dateBoolean;
		// return user.isClient && dateBoolean;
	});

	let clients = reportData.filter((user) => {
		return user[`isClient_${year}`];
		// return user.isClient;
	});
	let betaTesters = reportData.filter((user) => {
		return user[`membership_${year}`]?.type === 'Beta Tester';
		// return user.membership?.type === 'Beta Tester';
	});
	let signups = reportData.filter((user) => {
		return !user.isAdmin;
	});
	let totalBetaTesters = betaTesters.length;
	let totalSignups = signups.length - totalBetaTesters;
	let totalClients = clients.length;
	let conversionRate = (
		(totalClients / totalSignups + Number.EPSILON) *
		100
	).toFixed(2);

	return (
		<div>
			<h4>Sales Summary</h4>
			<SelectENURMYear onChange={(yr) => setReportYear(yr)} />
			{!searchSubmitted && (
				<div className='input-field col s12'>
					<button
						type='submit'
						className='btn bg-brand'
						disabled={year === undefined}
						onClick={handleSubmit}>
						Submit
					</button>
				</div>
			)}

			{searchSubmitted && (
				<div>
					{reportData.length > 0 ? (
						<div
							className={isMobile ? 'd-grid' : 'card-panel d-flex mb-3'}
							style={{
								justifyContent: 'space-evenly',
								gridTemplateColumns: '30% 30% 30%',
								gridTemplateRows: 'auto',
							}}>
							<div className='d-flex' style={{ flexDirection: 'column' }}>
								<div className='center fsize-display'>{salesToday.length}</div>
								<div>Sales Today</div>
							</div>
							{/* <div className='d-flex' style={{ flexDirection: 'column' }}>
								<div className='center fsize-display'>{totalBetaTesters}</div>
								<div>Beta Testers</div>
							</div> */}
							<div className='d-flex' style={{ flexDirection: 'column' }}>
								<div className='center fsize-display'>{totalSignups}</div>
								<div>Signups</div>
							</div>

							<div className='d-flex' style={{ flexDirection: 'column' }}>
								<div className='center fsize-display'>{totalClients}</div>
								<div>Sales</div>
							</div>
							<div className='d-flex' style={{ flexDirection: 'column' }}>
								<div>
									<span className='center fsize-display'>{conversionRate}</span>
									<span>%</span>
								</div>
								<div>{isMobile ? 'Conv Rate' : 'Conversion Rate'}</div>
							</div>
						</div>
					) : (
						<div>
							<p>No data found...</p>
						</div>
					)}
				</div>
			)}
		</div>
	);
};

export default Sales;
