import React, { createContext, useReducer, useContext, useState } from 'react';
import firebase from '../../store/config/fbConfig';
// import firebase from '../../store/config/firebase_dev.js';
import {
	questionsBackupDownload,
	firebaseQuestions,
} from '../config/firebaseBackup/backup';
import firebaseReducer from './reducers/firebaseReducer';
import { AuthContext } from './authContext';

/*eslint-disable*/
import {
	DATABASE,
	DATABASE_BACKUP,
	GET_CATEGORIES,
	GET_ESPECIALIDADES,
	GET_FLASHCARDS,
	GET_IMAGE,
	GET_QUESTIONS,
	GET_USER_LIST,
	DEBUG_BLUE,
	DEBUG_RED,
	DEBUG_YELLOW,
} from '../context/types';
/*eslint-enable*/
export const FirestoreContext = createContext();

const FirestoreContextProvider = (props) => {
	let fbQ = firebaseQuestions;

	const initialState = {
		questions: [],
		categories: [],
		flashcards: [],
		especialidades: [],
		imageURL: '',
	};
	const [state, dispatch] = useReducer(firebaseReducer, initialState);
	const { user } = useContext(AuthContext);
	// const [contactMessages, setContactMessages] = React.useState();

	const [uploaderProgress, setUploaderProgress] = useState(0);
	let today = Date.now();
	const ONE_DAY_IN_SECONDS = 86400;
	const THIRTY_DAYS = ONE_DAY_IN_SECONDS * 30;
	const lastPullDate = JSON.parse(
		localStorage.getItem('prepmed-last-questions-pull-date')
	);

	// ~FLASHCARDS
	//#region
	const addFlashcard = (flashcard) => {
		firebase
			.firestore()
			.collection('flashcards')
			.add({
				...flashcard,
				authorFirstName: user.firstName,
				authorLastName: user.lastName,
				authorID: user.uid,
				createTS: new Date(),
				createdBy: user.isAdmin || user.isStaff ? 'PrepMedRD' : user.uid,
			})
			.then(() => {
				console.log('%cFlashcard added:', DEBUG_BLUE, flashcard);
				// alert('Question set added succesfully.');
			})
			.catch((err) => {
				console.log('%cAdding flashcard failed', DEBUG_RED, flashcard);
			});
	};

	const deleteFlashcard = (id) => {
		let confirm = window.confirm(
			'Are you sure you want to delete this flashcard?'
		);

		if (confirm) {
			firebase
				.firestore()
				.collection('flashcards')
				.doc(id)
				.delete()
				.then(() => {
					// alert('Flashcard deleted.');

					let lsFlashcards = JSON.parse(
						localStorage.getItem('prepmed-flashcards')
					);
					// CLEAR FLASHCARDS FROM LOCAL STORAGE
					localStorage.removeItem('prepmed-flashcards');

					let newFlashcardSet = lsFlashcards.filter((f) => f.id !== id);

					localStorage.setItem(
						'prepmed-flashcards',
						JSON.stringify(newFlashcardSet)
					);
				})
				.catch((err) => {
					console.log('%cAdding flashcard failed', DEBUG_RED, err);
				});
		}
	};

	const deleteFlashcardImage = (id) => {
		let confirm = window.confirm('Are you sure you want to delete this image?');
		if (confirm) {
			firebase
				.firestore()
				.collection('flashcards')
				.doc(id)
				.update({
					image: firebase.firestore.FieldValue.delete(),
				})
				.then(() => {
					let lsFlashcards = JSON.parse(
						localStorage.getItem('prepmed-flashcards')
					);
					let thisCard = lsFlashcards.filter((c) => c.id === id)[0];
					delete thisCard.image;

					// CLEAR FLASHCARDS FROM LOCAL STORAGE
					localStorage.removeItem('prepmed-flashcards');

					let newFlashcardSet = lsFlashcards.filter((f) => f.id !== id);

					localStorage.setItem(
						'prepmed-flashcards',
						JSON.stringify([...newFlashcardSet, thisCard])
					);
				})
				.catch((err) => console.log(err));
		}
	};

	const getFlashcards = () => {
		// console.info('flashcardArray:', state.flashcards.length);

		if (state.flashcards.length) {
			return state.flashcards;
		}

		if (state.flashcards.length <= 0) {
			firebase
				.firestore()
				.collection('flashcards')
				.onSnapshot((snapshot) => {
					let allFlashcards = snapshot.docs.map((doc) => ({
						id: doc.id,
						...doc.data(),
					}));

					dispatch({
						type: GET_FLASHCARDS,
						payload: allFlashcards,
					});

					// SET TO LOCAL STORAGE
					localStorage.setItem(
						'prepmed-flashcards',
						JSON.stringify([...allFlashcards])
					);
					localStorage.setItem(
						'prepmed-last-questions-pull-date',
						JSON.stringify(today)
					);
				});
		}
	};

	const updateFlashcard = async (card) => {
		dispatch({
			type: 'updateFlashcard',
			payload: card,
		});

		await firebase
			.firestore()
			.collection('flashcards')
			.doc(card.id)
			.update({
				front: card.front,
				back: card.back,
				image: card.image,
				lastEditDate: new Date(),
				lastEditBy: user.firstName + ' ' + user.lastName,
			})
			.then(function () {
				let lsFlashcards = JSON.parse(
					localStorage.getItem('prepmed-flashcards')
				);
				let thisCard = {
					...card,
					lastEditDate: new Date(),
					lastEditBy: user.firstName + ' ' + user.lastName,
				};

				// CLEAR FLASHCARDS FROM LOCAL STORAGE
				localStorage.removeItem('prepmed-flashcards');

				let newFlashcardSet = lsFlashcards.filter((f) => f.id !== card.id);

				localStorage.setItem(
					'prepmed-flashcards',
					JSON.stringify([...newFlashcardSet, thisCard])
				);
			})
			.catch(function (error) {
				// The document probably doesn't exist.
				console.error('Error updating document: ', error);
				alert('Error updating document');
			});
	};

	const flashcard_addCategory = (card, category_id) => {
		firebase
			.firestore()
			.collection('flashcards')
			.doc(card.id)
			.update({
				categories: firebase.firestore.FieldValue.arrayUnion(category_id),
			})
			.then(() => {
				// console.log('Document successfully updated!');
			})
			.catch((error) => {
				// The document probably doesn't exist.
				console.error('Error updating document: ', error);
			});
	};

	const flashcard_removeCategory = (card, category_id) => {
		firebase
			.firestore()
			.collection('flashcards')
			.doc(card.id)
			.update({
				categories: firebase.firestore.FieldValue.arrayRemove(category_id),
			})
			.then(() => {
				// console.log('Document successfully updated!');
			})
			.catch((error) => {
				// The document probably doesn't exist.
				console.error('Error updating document: ', error);
			});
	};

	const flashcardCreatedByPrepMed = (card) => {
		firebase
			.firestore()
			.collection('flashcards')
			.doc(card.id)
			.update({
				createdBy: 'PrepMedRD',
				lastEditDate: new Date(),
				lastEditBy: user.firstName + ' ' + user.lastName,
			});
	};

	//#endregion

	// ~CATEGORIES
	/**
	 * Gets all categories from Firestore
	 */
	const getCategories = async () => {
		// console.log('%cGetting Categories from Firebase...', DEBUG_YELLOW);
		// GET QUESTIONS FROM LOCAL STORAGE
		let localStorageCategories = JSON.parse(
			localStorage.getItem('prepmed-categories')
		);

		// CODE BELOW WILL DELETE LOCAL STORAGE IF LAST PULL GREATER THAN 30 DAYS FOR USERS, ONE DAY FOR ADMINS
		if (user.isAdmin) {
			if ((today - lastPullDate) / 1000 > ONE_DAY_IN_SECONDS) {
				console.log("Clearing admin's local storage");
				localStorage.removeItem('prepmed-categories');
				localStorage.removeItem('prepmed-last-questions-pull-date');
				localStorageCategories = null;
			}
		} else if ((today - lastPullDate) / 1000 > THIRTY_DAYS) {
			// console.log('Clearing local storage... ');
			localStorage.removeItem('prepmed-categories');
			localStorage.removeItem('prepmed-last-questions-pull-date');
			localStorageCategories = null;
		} else {
			// todo: if usr not an admin and not 30 days????
		}

		if (localStorageCategories) {
			// console.info('getting CATEGORIES from local storage');
			dispatch({
				type: GET_CATEGORIES,
				payload: localStorageCategories,
			});
		} else {
			if (state.categories.length) {
				return state.categories;
			} else {
				await firebase
					.firestore()
					.collection('categories')
					.onSnapshot((snapshot) => {
						let categoriesDB = snapshot.docs.map((doc) => ({
							id: doc.id,
							...doc.data(),
						}));
						dispatch({
							type: GET_CATEGORIES,
							payload: categoriesDB,
						});

						// SET TO LOCAL STORAGE
						localStorage.setItem(
							'prepmed-categories',
							JSON.stringify([...categoriesDB])
						);
						localStorage.setItem(
							'prepmed-last-questions-pull-date',
							JSON.stringify(today)
						);
					});
			}
		}
	};

	// ~QUESTIONS
	//#region
	// ADD QUESTION TO FIRESTORE, THEN UPDATE STATE AND LOCAL STORAGE
	//#region
	const addQuestion = async (question2Add) => {
		firebase
			.firestore()
			.collection('questions')
			.add({
				...question2Add,
				exam: 'prepderm',
				authorFirstName: user.firstName,
				authorLastName: user.lastName,
				authorID: user.uid,
				createdOn: new Date(),
				questionSource: question2Add.enurm,
				imageURL: question2Add.hasOwnProperty('imagePath')
					? state.imageURL
					: null,
			})
			.then((docRef) => {
				getQuestion(docRef.id);
			})
			.catch((err) => {
				console.log('%cADDING QUESTION FAILED', DEBUG_RED, err);
				alert('No se pudo agregar la pregunta.');
			});

		setUploaderProgress(0);
	};

	const getQuestion = (id) => {
		let data;
		firebase
			.firestore()
			.collection('questions')
			.doc(id)
			.get()
			.then((doc) => {
				if (doc.exists) {
					data = doc.data();
					dispatch({
						type: 'add_question',
						payload: { id, ...data },
					});
				} else {
					console.info("This question doesn't exist!");
				}
			})
			.then(() => alert('La pregunta fue agregada.'))
			.catch((error) => {
				console.log('Error getting documents: ', error);
			});
	};
	//#endregion

	/**
	 * Gets all questions from Firestore
	 */
	const getQuestions = async () => {
		debugger;
		// GET QUESTIONS FROM LOCAL STORAGE
		let localStorageQuestions = await JSON.parse(
			localStorage.getItem('prepmed-questions')
		);
		// CODE BELOW WILL DELETE LOCAL STORAGE IF LAST PULL GREATER THAN 30 DAYS FOR USERS, ONE DAY FOR ADMINS
		if (localStorageQuestions?.length) {
			if (user.isAdmin) {
				if ((today - lastPullDate) / 1000 > ONE_DAY_IN_SECONDS) {
					console.log("Clearing admin's local storage");
					localStorage.removeItem('prepmed-questions');
					localStorage.removeItem('prepmed-last-questions-pull-date');
					localStorageQuestions = null;
				}
			}

			if (!user.isAdmin && (today - lastPullDate) / 1000 > THIRTY_DAYS) {
				// console.log('Clearing local storage... ');
				localStorage.removeItem('prepmed-questions');
				localStorage.removeItem('prepmed-last-questions-pull-date');
				localStorageQuestions = null;
			}
		}

		// GET QUESTIONS
		if (localStorageQuestions?.length) {
			dispatch({
				type: GET_QUESTIONS,
				payload: user.freeTrialActive
					? localStorageQuestions.filter((q) => q.enurm === 'enurm-2020')
					: localStorageQuestions,
			});
		} else {
			let dbData = [];
			if (state.questions.length) {
				dbData = [...state.questions];
				return dbData;
			} else {
				await firebase
					.firestore()
					.collection('questions')
					.get()
					.then((querySnapshot) => {
						querySnapshot.forEach((doc) => {
							let data = doc.data();
							console.log(doc.id, ' => ', data);
							dbData.push({ id: doc.id, ...data });
						});

						// .onSnapshot((snapshot) => {
						// 	const dbData = snapshot.docs.map((doc) => ({
						// 		id: doc.id,
						// 		...doc.data(),
						// 	}));
					})
					.catch((error) => {
						console.info(error);
					});
			}
			// SET QUESTIONS TO STATE
			dispatch({
				type: GET_QUESTIONS,
				payload: user.freeTrialActive
					? dbData.filter((q) => q.enurm === 'enurm-2020')
					: dbData,
			});

			// SET TO LOCAL STORAGE
			localStorage.setItem(
				'prepmed-questions',
				JSON.stringify(
					user.demoAccount
						? [...dbData.filter((q) => q.enurm === 'enurm-2012')]
						: [...dbData]
				)
			);
			localStorage.setItem(
				'prepmed-last-questions-pull-date',
				JSON.stringify(today)
			);
		}
	};

	/**
	 * Does a fresh pull of questions from Firestore
	 */
	const getQuestionsfromDB = async () => {
		let today = Date.now();
		console.log('GETTING DIRECTLY FROM DB');
		firebase
			.firestore()
			.collection(DATABASE)
			.onSnapshot((snapshot) => {
				const dbData = snapshot.docs.map((doc) => ({
					id: doc.id,
					...doc.data(),
				}));
				dispatch({
					type: GET_QUESTIONS,
					payload: dbData,
				});

				// SET TO LOCAL STORAGE
				localStorage.setItem('prepmed-questions', JSON.stringify([...dbData]));
				localStorage.setItem(
					'prepmed-last-questions-pull-date',
					JSON.stringify(today)
				);

				//  DATA BACKUP - HAS TO BE DONE MANUALLY
				if (DATABASE_BACKUP) {
					fbQ = JSON.stringify([...dbData]);

					let day = Date.now();
					let today = new Date(day);
					questionsBackupDownload(
						fbQ,
						`QUESTIONS_BACKUP ${
							today.getMonth() + 1
						}-${today.getDate()}-${today.getFullYear()}.json`,
						'text/plain'
					);
				}
			});
	};

	// QUESTION UPDATES
	/**
	 * Updates a question field, takes 3 params: questionID, fieldKey & newValue
	 */
	const updateQuestionbyKey = async (qID, key, newValue) => {
		dispatch({
			type: 'updateQuestions',
			payload: [qID, key, newValue],
		});

		if (key === 'enurm') {
			await firebase
				.firestore()
				.collection(DATABASE)
				.doc(qID)
				.update({
					[key]: newValue,
					questionSource: newValue,
					lastEditDate: new Date(),
					lastEditBy: user.firstName + ' ' + user.lastName,
				})
				.then(function () {
					// alert(`${key} successfully updated.`);
				})
				.catch(function (error) {
					// The document probably doesn't exist.
					console.log('Error updating document: ', error);
				});
		} else {
			await firebase
				.firestore()
				.collection(DATABASE)
				.doc(qID)
				.update({
					[key]: newValue,
					lastEditDate: new Date(),
					lastEditBy: user.firstName + ' ' + user.lastName,
				})
				.then(function () {
					// alert(`${key} successfully updated.`);
				})
				.catch(function (error) {
					// The document probably doesn't exist.
					console.log('Error updating document: ', error);
				});
		}
		await firebase
			.firestore()
			.collection(DATABASE)
			.doc(qID)
			.update({
				[key]: newValue,
				lastEditDate: new Date(),
				lastEditBy: user.firstName + ' ' + user.lastName,
			})
			.then(function () {
				// alert(`${key} successfully updated.`);
			})
			.catch(function (error) {
				// The document probably doesn't exist.
				console.log('Error updating document: ', error);
			});
	};

	/**
	 * Adds a tag to a question's tags array, takes in: questionID& newValue
	 */
	const updateQuestionTagsAdd = async (qID, newValue, tags) => {
		updateTagsLS(qID, tags);

		await firebase
			.firestore()
			.collection(DATABASE)
			.doc(qID)
			.update({
				tags: firebase.firestore.FieldValue.arrayUnion(newValue),
				lastEditDate: new Date(),
				lastEditBy: user.firstName + ' ' + user.lastName,
			})
			.then(function () {
				// alert(`${key} successfully updated.`);
			})
			.catch(function (error) {
				// The document probably doesn't exist.
				console.log('Error updating document: ', error);
			});
	};

	/**
	 * Deletes a tag from a question's tags array, takes in: questionID& newValue
	 */
	const updateQuestionTagsDelete = async (qID, newValue, tags) => {
		updateTagsLS(qID, tags);

		await firebase
			.firestore()
			.collection(DATABASE)
			.doc(qID)
			.update({
				tags: firebase.firestore.FieldValue.arrayRemove(newValue),
				lastEditDate: new Date(),
				lastEditBy: user.firstName + ' ' + user.lastName,
			})
			.then(function () {
				// alert(`${key} successfully updated.`);
			})
			.catch(function (error) {
				// The document probably doesn't exist.
				console.log('Error updating document: ', error);
			});
	};

	// Updates a question's tags in localStorage
	const updateTagsLS = (qID, tags) => {
		dispatch({
			type: 'updateQuestions',
			payload: [qID, 'tags', tags],
		});
	};

	const deleteQuestionField = (id, key) => {
		// console.log(id, key);
		if (key === 'needs_image') {
			dispatch({
				type: 'deleteKeyFromQuestion',
				payload: { id, key },
			});
			firebase
				.firestore()
				.collection(DATABASE)
				.doc(id)
				.update({
					[key]: firebase.firestore.FieldValue.delete(),
				})
				.then(() => console.info(`${key} deleted succesfully`))
				.catch((err) => console.log(err));
			return;
		}

		if (
			window.confirm(
				`Are you sure you want to delete ${key} from the question?`
			)
		) {
			dispatch({
				type: 'deleteKeyFromQuestion',
				payload: { id, key },
			});
			firebase
				.firestore()
				.collection(DATABASE)
				.doc(id)
				.update({
					[key]: firebase.firestore.FieldValue.delete(),
				})
				.then(() => alert(`${key} deleted succesfully`))
				.catch((err) => console.log(err));
		}
	};
	//#endregion

	/// IMAGES
	//#region
	const imgUpload = (file, imageFolder = 'question-images') => {
		// Create a root reference
		const storageRef = firebase.storage().ref(`${imageFolder}/${file.name}`);
		// const storageRef = firebase.storage().ref(`question-images/${file.name}`);
		// upload the file to the above reference
		const uploadTask = storageRef.put(file);

		uploadTask.on(
			'state_changed',
			function progress(snapshot) {
				let percentage =
					(snapshot.bytesTransferred / snapshot.totalBytes) * 100;
				setUploaderProgress(percentage);
			},
			function error(err) {
				alert(err);
			},
			function complete() {
				getImageURL(file.name);
				state.imageURL = null;
			}
		);
	};

	/**
	 * Takes in 'imagePath' & 'imageFolder' (default is 'question-images')
	 */
	const getImageURL = (imagePath, imageFolder = 'question-images') => {
		let imageLocation;
		// console.info(imagePath);
		// Create a reference with an initial file path and name
		const storage = firebase.storage();
		// const pathReference = storage.ref(`question-images/${imagePath}`);
		const pathReference = storage.ref(`${imageFolder}/${imagePath}`);
		pathReference
			.getDownloadURL()
			.then(function (url) {
				// console.info('IMAGE URL INSIDE THEN:', url);
				imageLocation = url;
				dispatch({
					type: GET_IMAGE,
					payload: url,
				});
			})
			.catch((err) => {
				console.log(err);
			});
		// console.info('imageLocation:', imageLocation);
		// console.info('imageURL:', state.imageURL);

		return imageLocation;
	};
	//#endregion

	//~ HISTORIC GRADES
	//#region
	/**
	 * Takes in a key
	 */
	const getHistoricGrades = (docName) => {
		let data;
		var especialidadRef = firebase
			.firestore()
			.collection('enurm_grade_history')
			.doc(docName);

		especialidadRef
			.get()
			.then((doc) => {
				if (doc.exists) {
					console.log('Document data:', doc.data());
					let data = doc.data();
					dispatch({
						type: GET_ESPECIALIDADES,
						payload: data,
					});
					return data;
				} else {
					console.log('No such document!');
					data = null;
				}
			})
			.catch((error) => {
				console.log('Error getting document:', error);
				data = 'error';
			});
		return data;
	};
	//#endregion

	return (
		<FirestoreContext.Provider
			value={{
				flashcardCreatedByPrepMed,
				addFlashcard,
				deleteFlashcard,
				deleteFlashcardImage,
				flashcard_addCategory,
				flashcard_removeCategory,
				getFlashcards,
				addQuestion,
				deleteQuestionField,
				getCategories,
				getHistoricGrades,
				getQuestions,
				getQuestionsfromDB,
				updateQuestionbyKey,
				updateQuestionTagsAdd,
				updateQuestionTagsDelete,
				updateTagsLS,
				getImageURL,
				imgUpload,
				uploaderProgress,
				setUploaderProgress,
				updateFlashcard,
				questions: state.questions,
				users: state.users,
				categories: state.categories,
				especialidades: state.especialidades,
				flashcards: state.flashcards,
				imageURL: state.imageURL,
			}}>
			{props.children}
		</FirestoreContext.Provider>
	);
};

export default FirestoreContextProvider;
