import React from 'react';

/**
 * Takes in message={} prop and displays loader
 */
const LoadingFull = (props) => {
	// https://icons8.com/preloaders/
	return (
		<div
			// className='center grey darken-2'
			className='center'
			style={{
				backgroundColor: 'var(--black-darker)',
				height: '100%',
				minHeight: '100vh',
				width: '100vw',
				position: 'absolute',
				top: '0',
				left: '0',
				zIndex: '1000',
				opacity: '0.95',
				display: 'flex',
				alignItems: 'center',
				justifyContent: 'center',
			}}>
			<div className='w-100'>
				{props.message && (
					<h5
						className='white-text'
						style={{
							maxWidth: '60%',
							margin: '0 auto',
							paddingBottom: '5vmax',
						}}>
						{props.message}
					</h5>
				)}
				<div className='preloader-wrapper big active '>
					<div className='spinner-layer spinner-blue-only'>
						<div className='circle-clipper left'>
							<div className='circle'></div>
						</div>
						<div className='gap-patch'>
							<div className='circle'></div>
						</div>
						<div className='circle-clipper right'>
							<div className='circle'></div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default LoadingFull;
