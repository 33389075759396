import React, { useState, useContext, useEffect } from 'react';
import { Redirect } from 'react-router-dom';
// import {
// 	firebaseAuth,
// 	firebaseFirestore,
// 	logError,
// 	logEvent,
// } from '../../../utils/store/config/fbConfig';
import { AuthContext } from '../../../utils/store/context/authContext';
import { AdminContext } from '../../../utils/store/context/adminContext';
import M from 'materialize-css';
// import LogoDisplay from '../LogoDisplay';
import {
	// checkValidation,
	showInvalidMessage,
} from '../../../utils/validation_helpers';
import { scrollToTop } from '../../../utils/helpers';
// import LoadingFull from '../../Helpers/LoadingFull';

import ProgressbarWithSteps from '../../Helpers/ProgressbarWithSteps';
import ChooseEnurm from './sign-up/ChooseEnurm';
import Step1 from './sign-up/Step1';
import Step2 from './sign-up/Step2';
import Step3 from './sign-up/Step3';
import { EMAIL_VERIFICATION } from '../../../utils/ROUTES';
import LoadingFull from '../../Helpers/LoadingFull';

const SignUpNew = (props) => {
	const {
		newUserSignup,
		user,
		updateUserByEmail,
		updateUser,
		updateNewUserInformationInFirestore,
	} = useContext(AuthContext);
	const {
		getCurrentEnurm,
		nextEnurm,
		updateTotalSignups,
		getMarketingData,
		marketing,
	} = useContext(AdminContext);
	//~ REFS
	//#region

	// const phoneRef = useRef();
	//#endregion

	//~ SIGNUP CODE
	//#region
	const [loading, setLoading] = useState(true);
	//! If code to signup needed again, just change the below to false
	// const signupCode = useRef();
	// // code is prepmed157
	const [showSignupForm, setShowSignupForm] = useState(false);
	const [showWrongCode, setShowWrongCode] = useState(false);
	//#endregion

	// ~INPUT FIELDS STATE
	//#region
	const [thisUser, setThisUser] = useState({});
	const [chooseEnurm, setChooseEnurm] = useState();
	const [signupStep, setSignupStep] = useState(0);

	// const [cellPhone, setCellPhone] = useState();

	//#endregion

	//~ VALIDATION STATES
	//#region

	// const [showFormatoValidoTelefono, setShowFormatoValidoTelefono] = useState(
	// 	false
	// );
	//#endregion

	/*eslint-disable */
	useEffect(() => {
		getCurrentEnurm();
		getMarketingData();
		marketing?.hasOwnProperty('salesSeasonOpen') && setLoading(true);
	}, []);

	useEffect(() => {
		M.AutoInit();
	}, [showSignupForm]);

	React.useEffect(() => {
		scrollToTop();
		marketing?.hasOwnProperty('salesSeasonOpen') && setLoading(false);
	}, [chooseEnurm, signupStep, marketing]);
	/*eslint-enable */

	//~ LOGIC
	//#region
	//~ HELPERS
	//#region
	// const checkIfAllFieldsValid = () => {
	// 	setShowErrorMessage('');

	// 	if (
	// 		// phoneRef.current.validity.valid &&

	// 	) {
	// 		setSignupErrorMessage(false);
	// 		setAllFieldsValid(true);
	// 		setShowErrorMessage('');
	// 		// console.info('All fields valid.');
	// 		return;
	// 	} else {
	// 		setAllFieldsValid(false);
	//
	//

	// 		// if (!phoneRef.current.validity.valid) {
	// 		// 	setShowErrorMessage(
	// 		// 		'Por favor verifica el campo "Telefono de Contacto".'
	// 		// 	);
	// 		// 	return;
	// 		// }

	// 	}
	// };
	//#endregion

	// const handleSubmitOfSignUpCode = (e) => {
	// 	e.preventDefault();
	// 	if (signupCode.current.value.toLowerCase() === 'prepmed157') {
	// 		setShowSignupForm(true);
	// 	} else {
	// 		setShowWrongCode(true);
	// 	}
	// };

	const handleChange = (e) => {
		// console.log('HANDLE CHANGE', e.target.value);
		e.target.setCustomValidity('');
		switch (e.target.id) {
			// case 'celular':
			// 	checkValidation(e);
			// 	if (e.target.validity.valid) {
			// 		setCellPhone(e.target.value);
			// 	}
			// 	break;
			// case 'cedula':
			// 	checkValidation(e);
			// 	if (e.target.validity.valid) {
			// 		setCedula(e.target.value);
			// 	}
			// 	break;
			default:
				break;
		}
	};

	const handleSubmit = (e) => {
		e.preventDefault();
		scrollToTop();
		// setLoading(true);

		let uuid;

		// if (user.uid) {
		// 	setTimeout(() => {
		// 		setLoading(false);
		// 	}, 1000);
		// 	return <Redirect to='/dashboard' />;
		// }
	};

	//#endregion

	if (loading) return <LoadingFull />;

	return (
		<div className='mt-2'>
			{/*//~ CHOOSE ENURM YEAR */}
			{!chooseEnurm && (
				<ChooseEnurm
					onClick={(enurm) => setChooseEnurm(enurm)}
					nextEnurm={nextEnurm}
					salesSeasonOpen={marketing?.salesSeasonOpen}
					updateThisUser={(data) => setThisUser(data)}
				/>
			)}

			{/*//~ SIGN UP STEPS */}
			{thisUser.hasOwnProperty('enurmTargetYear') && (
				<div className='container '>
					<h3 className='center s12 mb-2 txt-brand2'>
						{signupStep === 3 ? 'Verifica tu Correo' : 'Crea tu Cuenta'}
					</h3>
					<ProgressbarWithSteps
						activeStep={signupStep}
						descriptions={['Paso 1', 'Paso 2', 'Paso 3', 'Paso 4']}
					/>
					<div
						className={window.innerWidth < 790 ? 'mt-4' : 'w-50 mt-4 mx-auto'}>
						{/* //~ STEP 1 */}
						{signupStep === 0 && (
							<Step1
								thisUser={thisUser}
								newUserSignup={newUserSignup}
								updateThisUser={(e, p) => setThisUser(e, p)}
								nextStep={() => setSignupStep(signupStep + 1)}
							/>
						)}

						{/* //~ STEP 2 - Datos Personales */}
						{signupStep === 1 && (
							<Step2
								thisUser={thisUser}
								updateThisUser={(data) => setThisUser(data)}
								updateUserDoc={(data) => updateUserByEmail(data)}
								updateNewUserInformationInFirestore={(i, data) =>
									updateNewUserInformationInFirestore(i, data)
								}
								nextStep={() => setSignupStep(signupStep + 1)}
							/>
						)}

						{/* //~ STEP 3  - Datos Profesionales*/}
						{signupStep === 2 && (
							<Step3
								thisUser={thisUser}
								updateThisUser={(data) => setThisUser(data)}
								updateNewUserInformationInFirestore={(i, data) =>
									updateNewUserInformationInFirestore(i, data)
								}
								nextStep={() => setSignupStep(signupStep + 1)}
							/>
						)}

						{/* //~ STEP 4 - EMAIL VERIFICATION */}
						{signupStep === 3 && <Redirect to={EMAIL_VERIFICATION} />}
					</div>
				</div>
			)}

			{/* //!SIGNUP FORM */}
			{/* <div className='container d-none'>
				<form
					onSubmit={handleSubmit}
					onChange={() => {
						// checkIfAllFieldsValid();
					}}
					className='white row'> */}
			{/* CELULAR	 */}
			{/* <div className='input-field col s12 m6'>
						<i className='material-icons prefix txt-brand2'>phone</i>
						<label htmlFor='celular'>Telefono de Contacto</label>
						<input
							type='tel'
							id='celular'
							onFocus={(e) => setShowFormatoValidoTelefono(true)}
							onBlur={(e) => setShowFormatoValidoTelefono(false)}
							onChange={handleChange}
							minLength={10}
							maxLength={14}
							ref={phoneRef}
							pattern='[0-9]{3}[-][0-9]{3}[-][0-9]{4}|[0-9]{1}[-][0-9]{3}[-][0-9]{3}[-][0-9]{4}|[0-9]{10}|[0-9]{11}'
							onInvalid={(event) =>
								showInvalidMessage(
									event,
									'El formato ingresado es inválido o está incompleto.'
								)
							}
							autoComplete='off'
							required
						/>
						<span className={phoneRef.current?.validity && 'invalid'}></span>
						<sup className='ml-4'>(requerido)</sup>
					</div>
					{showFormatoValidoTelefono && (
						<div className='col s12'>
							<div className='col offset-m6 m6 s12'>
								<ul className='center fsize-1 txt-danger m-0'>
									<li>
										<b>Formatos Válidos:</b>
									</li>
									<li>1 - *** - *** - ****</li>
									<li>*** - *** - ****</li>
									<li>1**********</li>
									<li>**********</li>
								</ul>
							</div>
						</div>
					)} */}
			{/* DATE OF BIRTH */}
			{/* <div className='input-field col s12 m6'>
						<i className='material-icons prefix txt-brand2'>event</i>
						<label htmlFor='fecha-de-nacimiento' onClick={openDatepicker}>
							Fecha de Nacimiento
						</label>
						<input
							type='text'
							id='date-of-birth'
							className='datepicker'
							ref={dobRef}
							onFocus={openDatepicker}
							onInvalid={reportInvalid}
							onBlur={(e) => e.target.setCustomValidity('')}
							required
						/>
						<sup className='ml-4'>(requerido)</sup>
					</div> */}
			{/* </form> */}
			{/* </div> */}
		</div>
	);
};

export default SignUpNew;
