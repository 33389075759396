import React from 'react';

/**
 * Takes in steps descriptions in array form
 */
const ProgressbarWithSteps = ({ descriptions, activeStep }) => {
	return (
		<div className='steps-progress-bar-container'>
			<div className='steps-progress-bar'>
				{descriptions?.map((description) => {
					return (
						<div
							className={`progress-step ${
								descriptions[activeStep] === description && 'is-active'
							}`}
							key={descriptions.indexOf(description)}>
							<div className='step-count'></div>
							<div className='step-description'>{description}</div>
						</div>
					);
				})}
				{/* <div className='progress-step is-active'>
					<div className='step-count'></div>
					<div className='step-description'>Paso 2</div>
				</div>
				<div className='progress-step'>
					<div className='step-count'></div>
					<div className='step-description'>Paso 3</div>
				</div>
				<div className='progress-step'>
					<div className='step-count'></div>
					<div className='step-description'>Empieza</div>
				</div> */}
			</div>
		</div>
	);
};

export default ProgressbarWithSteps;
