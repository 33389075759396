import React, { useContext } from 'react';
import { FirestoreContext } from '../../../utils/store/context/firestoreContext';
import { AuthContext } from '../../../utils/store/context/authContext';

function Stats() {
	const { questions, categories } = useContext(FirestoreContext);
	const { userTestRecords } = useContext(AuthContext);

	const getCountOfQuestionsbyCategory = (category) => {
		let totalDePreguntasEnEstaCategoría = 0;
		userTestRecords.forEach((record) => {
			let tempSum = record.questions?.reduce((sum, currentValue) => {
				let currentQuestion = questions.filter(
					(q) => q.id === currentValue.questionID
				)[0];
				if (currentQuestion?.category === category) {
					return sum + 1;
				}
				return sum;
			}, 0);
			totalDePreguntasEnEstaCategoría =
				tempSum + totalDePreguntasEnEstaCategoría;
		});

		return totalDePreguntasEnEstaCategoría;
	};

	const getCountOfCorrectQuestionsByCategory = (category) => {
		let totalDePreguntasCorrectasEnEstaCategoría = 0;
		userTestRecords.forEach((record) => {
			let tempSum = record.questions?.reduce((sum, currentValue) => {
				let currentQuestion = questions.filter(
					(q) => q.id === currentValue.questionID
				)[0];

				if (currentQuestion?.category === category) {
					if (currentValue.correctAnswer === currentValue.selectedAnswer)
						return sum + 1;
				}
				return sum;
			}, 0);
			totalDePreguntasCorrectasEnEstaCategoría =
				tempSum + totalDePreguntasCorrectasEnEstaCategoría;
		});
		return totalDePreguntasCorrectasEnEstaCategoría;
	};

	const getCategoryPercentage = (category) => {
		let result =
			getCountOfCorrectQuestionsByCategory(category) /
			getCountOfQuestionsbyCategory(category);
		if (result > 0) return `${Math.round((result + Number.EPSILON) * 100)}%`;
		return '-';
	};

	if (!userTestRecords) {
		return <div className='d-none'></div>;
	}
	return (
		<section className='section-2'>
			<div className='container'>
				<div className='row'>
					<div className='col s12'>
						<h3>Estadísticas</h3>
						<h6>
							Nota Promedio por Categoría (Preguntas Correctas / Preguntas de la
							Categoría)
						</h6>
						<div className='stat-cards'>
							{categories.map((category) => {
								return (
									<div
										key={category.name}
										className='card-panel center mx-1'
										style={{
											width: '160px',
											borderRadius: '20px',
										}}>
										<div className='circle2 m-auto d-flex-center'>
											<span className='circle data'>
												{getCategoryPercentage(category.name)}
											</span>
										</div>
										<p className='mb-0'>
											{/* <i className='material-icons'>close</i>*/}
											<strong>{category.name}</strong>
										</p>
										<small>
											{getCountOfCorrectQuestionsByCategory(category.name)} /
											{getCountOfQuestionsbyCategory(category.name)}
										</small>
									</div>
								);
							})}
						</div>
					</div>
				</div>
			</div>
		</section>
	);
}

export default Stats;
