import React, { useContext, useEffect, useState } from 'react';
import { FirestoreContext } from '../../../utils/store/context/firestoreContext';
import { UtilContext } from '../../../utils/store/context/utilContext';
// import { useQuill } from 'react-quilljs';
import 'quill/dist/quill.snow.css';
import AddExplanation from './add-questions/AddExplanation';
import AddQuestion from './add-questions/AddQuestion';
import AddFuente from './add-questions/AddFuente';

const AddQuestions = (props) => {
	/* ===== CONTEXT =====*/
	const {
		addQuestion,
		getCategories,
		categories,
		imgUpload,
		uploaderProgress,
		setUploaderProgress,
	} = useContext(FirestoreContext);
	const { scrollTo, refreshPage } = useContext(UtilContext);

	const [newQuestion, setNewQuestion] = useState({});
	const [selectedCat, setSelectedCat] = useState('');
	const [category, setCategory] = useState('');
	const [subCategory, setSubCategory] = useState('');
	const [showSubCat, setShowSubCat] = useState(false);
	const [showSpecialization, setShowSpecialization] = useState(false);
	const [enurm, setEnurm] = useState('');
	const [tags, setTags] = useState([]);
	const [disabled, setDisabled] = useState('disabled');
	const [showIMGCheckbox, setShowIMGCheckbox] = useState(false);
	const [isChecked, setIsChecked] = useState(false);

	/* ===== OTHER VARIABLES =====*/
	let selectedCategory;
	let selectedSubCategory;
	let sub_cat_select;

	/* eslint-disable */
	useEffect(() => {
		cleanupState();
		getCategories();
		M.AutoInit();
		return () => {
			cleanupState();
		};
	}, []);

	// Get subCategories
	useEffect(() => {
		sub_cat_select = document.querySelector('select#subCategory');

		if (showSubCat) {
			selectedCategory = categories.filter((cat) => {
				return cat.name === category;
			});
			setSelectedCat(...selectedCategory);

			category.length && selectedCategory[0].sub_categories
				? selectedCategory[0].sub_categories.forEach((option) => {
						sub_cat_select.insertAdjacentHTML(
							'beforeend',
							`<option value=${option.name}
							key=${option.name}>${option.name}</option>`
						);
				  })
				: setShowSubCat(false);
		}
	}, [showSubCat, category]);

	/* Get specializations */
	useEffect(() => {
		const specializations_select = document.querySelector(
			'select#specialization'
		);

		if (category.length && showSpecialization) {
			selectedSubCategory = selectedCat.sub_categories.filter((subCat) => {
				return subCat.name === subCategory;
			});

			category.length &&
			selectedSubCategory.length &&
			selectedSubCategory[0].hasOwnProperty('specializations')
				? selectedSubCategory[0].specializations.forEach((i) => {
						specializations_select.insertAdjacentHTML(
							'beforeend',
							`<option value=${i}
							key=${i}>${i}</option>`
						);
						// I need to make the specializations into a map instead of an array and then uncommentout the code below:
						// specializations_select.insertAdjacentHTML(
						// 	'beforeend',
						// 	`<option value=${option.name}
						// 		key=${option.name}>${option.name}</option>`
						// );
				  })
				: setShowSpecialization(false);
		}
	}, [showSpecialization, subCategory]);
	/* eslint-enable */

	const addTags = () => {
		setNewQuestion({
			...newQuestion,
			tags: [...tags],
		});
	};

	const handleUpload = (e) => {
		imgUpload(e.target.files[0]);
		setShowIMGCheckbox(true);
		setNewQuestion({
			...newQuestion,
			imagePath: e.target.files[0].name,
		});
	};

	const handleTags = (e) => {
		e.preventDefault();
		let tag = e.target.dataset.value;
		if (
			newQuestion.hasOwnProperty('tags') &&
			newQuestion.tags.includes(e.target.dataset.value)
		) {
			e.target.classList.remove('selected');
			return;
		} else {
			setTags((tags) => tags.concat(tag));
			e.target.classList.add('selected');
		}

		setNewQuestion({
			...newQuestion,
			tags: [...tags],
		});
	};

	const handleChange = (e) => {
		// console.info('NewQuestion:', newQuestion);
		let target = e.currentTarget.id;

		if (target === 'category') {
			setSubCategory('');
			setShowSubCat(false);
			setShowSpecialization(false);
			setCategory(e.target.value);
			setNewQuestion({
				...newQuestion,
				[target]: e.target.value,
			});
			setShowSubCat(true);
		} else if (target === 'subCategory') {
			setSubCategory(e.target.value);
			setShowSpecialization(true);
			setNewQuestion({
				...newQuestion,
				subCategory: e.target.value,
			});
			// } else if (target === 'question') {
			// 	setNewQuestion({
			// 		...newQuestion,
			// 		[target]: e.target.innerHTML.replace(/(<p><br><\/p>)/g, ''),
			// 	});
			// } else if (target === 'explanation') {
			// 	setNewQuestion({
			// 		...newQuestion,
			// 		[target]: e.target.innerHTML.replace(/(<p><br><\/p>)/g, ''),
			// 	});
		} else if (target === 'showImageInQuestion') {
			setIsChecked(!isChecked);
			setNewQuestion({
				...newQuestion,
				[target]: !isChecked,
			});
		} else if (target === 'enurm') {
			setEnurm(e.target.value);
			setNewQuestion({
				...newQuestion,
				[target]: e.target.value,
			});
		} else {
			setNewQuestion({
				...newQuestion,
				[target]: e.target.value,
			});
		}

		if (category && enurm) {
			setDisabled('');
		}
	};

	const handleExplanationChange = (content) => {
		setNewQuestion({
			...newQuestion,
			explanation: content.replace(/(<p><br><\/p>)/g, ''),
		});
	};

	const handleFuenteChange = (content) => {
		setNewQuestion({
			...newQuestion,
			fuente: content.replace(/(<p><br><\/p>)/g, ''),
		});
	};

	const handleQuestionChange = (content) => {
		// console.info(content.replace(/(<p><br><\/p>)/g, ''));
		setNewQuestion({
			...newQuestion,
			question: content.replace(/(<p><br><\/p>)/g, ''),
		});
	};

	const handleSubmit = async (e) => {
		e.preventDefault();
		addTags();
		document.querySelectorAll('span.tag.selected').forEach((elem) => {
			elem.classList.remove('selected');
		});
		// // REMOVE ALL QUESTIONS FROM LOCAL STORAGE
		// localStorage.removeItem('prepmed-questions');

		// ADD THE QUESTION
		addQuestion(newQuestion);

		// RESET FORM AND SCROLL TO TOP
		document.querySelector('form#add-questions-form').reset();
		scrollTo('#add-questions-form');

		// CLEAN UP STATES
		cleanupState();

		// GO BACK TO QUESTIONS DASHBOARD
		props.history.goBack();
	};

	const cleanupState = () => {
		setNewQuestion({});
		setNewQuestion({});
		setSelectedCat('');
		setCategory('');
		setSubCategory('');
		setShowSubCat(false);
		setShowSpecialization(false);
		setEnurm('');
		setTags([]);
		setDisabled('disabled');
		setShowIMGCheckbox(false);
		setUploaderProgress(0);
		let tags = document.querySelectorAll('.tag');
		tags.forEach((t) => {
			t.classList.remove('selected');
		});
	};

	return (
		<div className='container'>
			<div className='form-padding'>
				<div className='input-field'>
					<button
						className='btn red lighten-1 right'
						title='Clilck this and you will lose your current work..'
						onClick={refreshPage}>
						Volver a Empezar
					</button>
				</div>
				<form
					onSubmit={handleSubmit}
					className='white'
					autoComplete='off'
					id='add-questions-form'>
					<div>
						<h5 className='grey-text text-darken-3'>
							Agrega una nueva pregunta
						</h5>
						{/* <p>
							This should only be accesible to admins & authorized employees.
							Note: We track who adds each question.
						</p> */}
					</div>
					{/* ENURM LABEL */}
					<div className='input-field'>
						<select
							// className='browser-default'
							id='enurm'
							name='enurm'
							defaultValue={'default'}
							onChange={handleChange}
							tabIndex='0'
							autoFocus>
							<option value='default' disabled>
								-- Elige Uno --
							</option>
							<option value='prepderm'>PrepDerm</option>
							<option value='bogaert'>Bogaert</option>
						</select>
						<label htmlFor='enurm'>Label</label>
					</div>
					{/* ===== SELECT A CATEGORY ===== */}
					{categories && (
						<div className='input-field'>
							<select
								className='browser-default'
								id='category'
								name='category'
								defaultValue={'default'}
								onChange={handleChange}
								tabIndex='1'
								required>
								<option value='default' disabled>
									-- Select a category --
								</option>
								{categories &&
									categories.map((option) => {
										return (
											<option
												value={`${option.name}`}
												key={`${option.id}`}>{`${option.name}`}</option>
										);
									})}
							</select>
							<label htmlFor='category'></label>
						</div>
					)}
					{/* ===== SELECT A SUBCATEGORY ===== */}
					{showSubCat && (
						<div className='input-field'>
							<select
								className='browser-default'
								id='subCategory'
								name='subCategory'
								defaultValue={'default'}
								onChange={handleChange}
								required
								tabIndex='2'>
								<option value='default' disabled>
									-- Select a subcategory --
								</option>
							</select>
							<label htmlFor='subCategory'></label>
						</div>
					)}
					{/* ===== SELECT A SPECIALIZATION ===== */}
					{showSpecialization && (
						<div className='input-field'>
							<select
								className='browser-default'
								id='specialization'
								name='sub-especialidad'
								defaultValue={'default'}
								onChange={handleChange}
								tabIndex='3'>
								<option value='default' disabled>
									-- Select a specialization --
								</option>
							</select>
							<label htmlFor='sub-especialidad'></label>
						</div>
					)}
					{/* ===== ENTER THE QUESTION ===== */}
					<div className='input-field'>
						<div>Question</div>
						<AddQuestion action={handleQuestionChange} tabIndex='4' />
					</div>
					{/* ===== ENTER THE ANSWERS ===== */}
					<div className='input-field'>
						<label htmlFor='answer'>Answer</label>
						<input
							type='text'
							id='answer'
							onInput={handleChange}
							required
							tabIndex='5'
						/>
					</div>
					<div className='input-field'>
						<label htmlFor='option1'>Option 1</label>
						<input
							type='text'
							id='option1'
							onInput={handleChange}
							required
							tabIndex='6'
						/>
					</div>
					<div className='input-field'>
						<label htmlFor='option2'>Option 2</label>
						<input
							type='text'
							id='option2'
							onInput={handleChange}
							required
							tabIndex='7'
						/>
					</div>
					<div className='input-field'>
						<label htmlFor='option3'>Option 3</label>
						<input
							type='text'
							id='option3'
							onInput={handleChange}
							required
							tabIndex='8'
						/>
					</div>

					{/* EXPLANATION - IMAGES - FUENTES */}
					<blockquote className='bl-teal'>
						{/* ===== ENTER THE EXPLANATION ===== */}
						<div className='input-field'>
							<div>Explanation</div>
							<AddExplanation action={handleExplanationChange} tabIndex='9' />
						</div>
						{/* Upload Image */}
						<div className='input-field flex-center'>
							{/* how to style this progress bar: https://youtu.be/SpxHVrpfGgU?t=109 */}
							<progress value={uploaderProgress} max='100' id='uploader'>
								0%
							</progress>
							{uploaderProgress === 100 ? (
								<p style={{ marginLeft: '10px' }}>Upload Successful!</p>
							) : (
								<input
									type='file'
									name='fileUpload'
									id='fileButton'
									onChange={handleUpload}
									tabIndex='10'
								/>
							)}
						</div>
						{showIMGCheckbox && (
							<div className='input-field'>
								<p>
									<label>
										<input
											type='checkbox'
											onChange={handleChange}
											id='showImageInQuestion'
											className={isChecked ? 'filled-in' : ''}
										/>
										<span>Show the above image in the question?</span>
									</label>
								</p>
							</div>
						)}
						{/* FUENTES */}
						<div className='input-field'>
							<div>Fuente</div>
							<AddFuente action={handleFuenteChange} tabIndex='11' />
						</div>
					</blockquote>

					{/* TAGS */}
					<div className='input-field'>
						<p className='grey-text'>Tags:</p>
						<div className='row' style={{ marginBottom: '10px !important' }}>
							<div className='col s12 mb-2' onMouseLeave={addTags}>
								{categories &&
									categories.map((option) => {
										return (
											<div
												// className='tag'
												className='chip tag'
												// style={{ padding: '0 10px' }}
												data-value={option.name.toLowerCase()}
												onClick={handleTags}
												key={option.id}
												id='tags'>
												{option.name}
											</div>
										);
									})}
							</div>
						</div>
					</div>

					{/*Warnings*/}
					{newQuestion.hasOwnProperty('question') &&
					newQuestion.question !== '<p><br></p>' ? (
						''
					) : (
						<div className='red darken-2 white-text'>
							<p style={{ padding: '1vh' }}>
								Aún no hay una <strong>PREGUNTA</strong> agregada...
							</p>
						</div>
					)}
					{newQuestion.hasOwnProperty('answer') ? (
						''
					) : (
						<div className='red darken-2 white-text'>
							<p style={{ padding: '1vh' }}>
								Aún no hay una <strong>RESPUESTA</strong> agregada...
							</p>
						</div>
					)}
					{newQuestion.hasOwnProperty('option1') ? (
						''
					) : (
						<div className='red darken-2 white-text'>
							<p style={{ padding: '1vh' }}>
								Aún no hay una <strong>OPTION1</strong> agregada...
							</p>
						</div>
					)}
					{newQuestion.hasOwnProperty('option2') ? (
						''
					) : (
						<div className='red darken-2 white-text'>
							<p style={{ padding: '1vh' }}>
								Aún no hay una <strong>OPTION2</strong> agregada...
							</p>
						</div>
					)}
					{newQuestion.hasOwnProperty('option3') ? (
						''
					) : (
						<div className='red darken-2 white-text'>
							<p style={{ padding: '1vh' }}>
								Aún no hay una <strong>OPTION3</strong> agregada...
							</p>
						</div>
					)}
					{newQuestion.hasOwnProperty('explanation') ? (
						''
					) : (
						<div className='red darken-2 white-text'>
							<p style={{ padding: '1vh' }}>
								Aún no hay una <strong>EXPLICACION</strong> agregada...
							</p>
						</div>
					)}
					{newQuestion.hasOwnProperty('fuente') ? (
						''
					) : (
						<div className='yellow'>
							<p style={{ padding: '1vh' }}>
								Aún no hay una FUENTE agregada...
							</p>
						</div>
					)}
					{disabled && (
						<div className='red lighten-2 center'>
							<p style={{ padding: '1vh' }}>
								You must add a category to enable this button.
							</p>
						</div>
					)}

					{/*Clear and Submit Buttons*/}
					<div className='input-field right-align' onMouseEnter={addTags}>
						<button
							className='btn z-depth-0 grey lighten-1'
							type='reset'
							tabIndex='14'
							style={{ marginRight: '5px' }}
							onClick={cleanupState}>
							Clear
						</button>
						<button
							className='btn z-depth-0'
							type='submit'
							tabIndex='13'
							disabled={disabled}>
							<i className='material-icons right'>send</i>Add Question
						</button>
					</div>
				</form>
			</div>
		</div>
	);
};

export default AddQuestions;
