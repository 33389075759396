import React, { useContext, useEffect, useState } from 'react';
import Stats from './Stats';
// import Search from '../admin/Search';
// import MakeAdmin from './MakeAdmin';
// import { AuthContext } from '../../../utils/store/context/authContext';
import { FirestoreContext } from '../../../utils/store/context/firestoreContext';
import QuestionSummaryList from './QuestionsSummaryList';
// import IDSummary from './reports/IDSummary';

const QuestionsDashboard = (props) => {
	const {
		getQuestionsfromDB,
		getQuestions,
		questions,
		getCategories,
		categories,
	} = useContext(FirestoreContext);

	const [showCounter, setShowCounter] = useState(true);
	const [showQuestions, setShowQuestions] = useState(false);

	/* eslint-disable */
	useEffect(() => {
		questions.length <= 0 && getQuestions();
		getCategories();
	}, []);

	/* eslint-enable */

	if (questions.length <= 0) return <div>Loading questions...</div>;

	return (
		<div className='dashboard white'>
			{/* {user.isAdmin ? <MakeAdmin /> : null} */}

			<div className='row'>
				{/* ===== STATS =====  */}
				{showCounter && (
					<div className='col l4 s12 m6'>
						<div className='stats '>
							<Stats questions={questions} />
						</div>
					</div>
				)}

				{/* ===== QUESTION LIST ===== */}
				<div
					className={`col inset-shadow s12 bg-lighter-shade ${
						showCounter ? 'm6 l8' : 'm12 l12'
					}`}
					style={{
						minHeight: '100vmax',
					}}>
					<button
						className='btn btn-flat'
						onClick={() => setShowCounter(!showCounter)}>
						{setShowCounter ? 'Hide' : 'Show'} Counter
					</button>

					<div className='row'>
						<div className={`col s12  ${showCounter ? 'pw-2' : 'pw-4'}`}>
							{questions && (
								<QuestionSummaryList
									questions={questions}
									getQuestionsFromDB={getQuestionsfromDB}
									categories={categories}
								/>
							)}
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default QuestionsDashboard;
