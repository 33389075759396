import React, { useContext } from 'react';
import { AuthContext } from '../../../utils/store/context/authContext';
import {
	customDateFormatInSpanish,
	redirectToVerifyEmail,
} from '../../../utils/helpers';
const Profile = () => {
	const { user, resetPassword, changeEmailAddress } = useContext(AuthContext);
	// console.info(user.signUpDate);

	const [showEmailInput, setShowEmailInput] = React.useState(false);
	const [email, setEmail] = React.useState(false);

	const handleEmailChange = (params) => {
		changeEmailAddress(email);
		setShowEmailInput(false);
	};

	return (
		<div className='container'>
			<div className='card mt-4'>
				<div className='card-content bg-gray-1'>
					<h5>Tu Perfil</h5>
				</div>
				<div className='card-content'>
					<p>
						Esta es toda la información que tenemos actualmente sobre ti. Por el
						momento solo puedes cambiar tu correo. Disculpa los inconvenientes.
					</p>
					{/* <p>
						Esta es toda la información que tenemos actualmente sobre ti. Solo
						puedes cambiar tu correo y/o tu contraseña.
					</p> */}
					<p>
						Si los enlaces no están disponibles en este momento por favor trata
						más adelante.
					</p>

					{!user?.emailVerified && (
						<div className='card-panel bg-alternate1-white py-1'>
							<h5 className='txt-alternate1'>
								<i className='material-icons fsize-larger mr-2'>announcement</i>
								Aun no hemos podido verificar tu correo.
							</h5>
							<p className='txt-black my-1'>
								Es opcional pero recomendado verificar tu correo para confirmar
								que eres tu.
							</p>
							<div className='right-align'>
								<button
									onClick={redirectToVerifyEmail}
									className='btn bg-alternate1-1'>
									<i className='material-icons right'>send</i>
									VERIFICAR CORREO
								</button>
							</div>
						</div>
					)}
					<ul className='container ml-4'>
						<li>
							Nombre: {user.firstName} {user.lastName}
						</li>
						<li>
							Fecha de Nacimiento: {user.dateOfBirth}
							{/* {customDateFormatInSpanish(new Date(user.dateOfBirth))} */}
						</li>
						<li>Universidad: {user.universidad} </li>
						<li>Año de Graduación: {user.gradYear} </li>
						<li>
							Cuenta Creada:{' '}
							{customDateFormatInSpanish(new Date(user.signUpDate.seconds))}
						</li>
						<li>Telefono: {user.telefonoDeContacto} </li>
						<li>
							Correo: {user.email}{' '}
							{!showEmailInput && (
								<button onClick={() => setShowEmailInput(true)}>
									Cambia tu correo
								</button>
							)}
						</li>

						{showEmailInput && (
							<div>
								<input type='text' onChange={(e) => setEmail(e.target.value)} />
								<button onClick={handleEmailChange}> Submit</button>
								<button onClick={() => setShowEmailInput(false)}>Cancel</button>
							</div>
						)}

						<li>Cambia tu contraseña (pronto)</li>
						{/* <li>Request a refund</li> */}
					</ul>
				</div>
			</div>
		</div>
	);
};

export default Profile;
