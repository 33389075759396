import React from 'react';
import { AdminContext } from '../../../utils/store/context/adminContext';
// import { FirestoreContext } from '../../../utils/store/context/firestoreContext';

const AdminDashboard = () => {
	// const { getUserList, users } = React.useContext(FirestoreContext);

	const {
		contactMessages,
		contactMessageAnswered,
		enurmData,
		errors,
		getContactMessage,
		getErrors,
		getCurrentENURMData,

		// getUserList,
		// users,
		// updateUser,
	} = React.useContext(AdminContext);

	// const { contactMessages, getContactMessage } = React.useContext(
	// 	FirestoreContext
	// );

	React.useEffect(() => {
		getErrors();
		getContactMessage();
		Object.keys(enurmData).length === 0 && getCurrentENURMData();
		// getUserList();
		return () => {};
		// eslint-disable-next-line
	}, []);

	const updateContactMessage = (e) => {
		let id = e.target.id;
		contactMessageAnswered(id);
	};

	return (
		<div className='container mt-4'>
			<div className='row'>
				<div className='col m6 s12'>
					{/* //~SALES INFO */}
					<div
						className='card d-flex'
						style={{
							justifyContent: 'space-around',
						}}>
						<div
							className='card-content d-flex center'
							style={{ flexDirection: 'column' }}>
							<div className='fsize-display'>{enurmData?.totalSignups}</div>
							<div>Total Signups:</div>
						</div>
						<div
							className='card-content d-flex center '
							style={{ flexDirection: 'column' }}>
							<div className='fsize-display'>{enurmData?.freeTrialsActive}</div>
							<div>Free Trials:</div>
						</div>
						<div
							className='card-content d-flex center'
							style={{ flexDirection: 'column' }}>
							<div className='fsize-display'>{enurmData?.totalSales}</div>
							<div>Total Sales:</div>
						</div>
						{/* <div
							className='card-content d-flex center'
							style={{ flexDirection: 'column' }}>
							<div>{latest.totalSignups}</div>
							<div>Total Collected:</div>
						</div> */}
					</div>

					{/* //~ERRORS */}
					<div className='card bg-danger white-text'>
						<div className='card-content'>
							<h5>Logged Errors: {errors.length}</h5>
						</div>
					</div>

					{/* //~SUPPORT MESSAGES */}
					<div>
						{contactMessages?.length < 0 ? (
							<h5>Support Messages</h5>
						) : (
							<h5 className='txt-success mt-2'>
								<i className='material-icons mr-1'>check_circle</i>
								No pending support messages
							</h5>
						)}

						{/* LIST OF MESSAGES */}
						{contactMessages &&
							contactMessages
								.filter((message) => {
									return !message?.isAnswered;
								})
								.sort((a, b) => {
									return b.isAnswered - a.isAnswered;
								})
								.reverse()
								.map((msg) => {
									return (
										<div key={msg.id} className='card bg-warning white-text'>
											<div className='card-content'>
												<details
													className={`left-align ${
														msg.isAnswered ? 'text-strikethrough' : ''
													}`}>
													<summary className='contact-message'>
														<div
															className='d-flex'
															style={{
																justifyContent: 'space-between',
															}}>
															{/* CONTACT DETAILS */}
															<div>
																<div>
																	{/* ICON */}
																	{msg.isAnswered ? (
																		<span className='material-icons left'>
																			mark_email_read
																		</span>
																	) : (
																		<span className='material-icons left white-text'>
																			email
																		</span>
																	)}
																	<span className='pl-1'>{msg.name}</span>
																</div>
																<div className='pl-2'>{msg.email}</div>
															</div>

															{/* STATUS */}
															{msg.isAnswered ? (
																<span className='badge white-text fsize-small'>
																	Resolved
																	<i className='material-icons'>done</i>
																</span>
															) : (
																<span className='badge white fsize-small'>
																	Pending
																</span>
															)}
														</div>
													</summary>
													<div className='p-2'>{msg.text}</div>
													<div
														className='d-flex'
														style={{ justifyContent: 'flex-end' }}>
														<button
															id={msg.id}
															onClick={updateContactMessage}
															className='btn btn-sm blue-grey lighten-2'>
															Mark as Answered
														</button>
													</div>
												</details>
											</div>
										</div>
									);
								})}
					</div>
				</div>
				<div className='col m6 s12 right-align'>
					<h5>Direct link to pages will be here...</h5>
					<p>(coming soon)</p>
					{/* <div>
						{users.map((u) => {
							if (
								!u.isAdmin &&
								!u.isStaff &&
								!u.hasOwnProperty('enurmTargetYear')
							) {
								return (
									<li>
										<div>{u.id}</div>
										{u.firstName + ' ' + u.lastName}
										<button onClick={() => updateUser(u.id)}>update</button>
									</li>
								);
							}
						})}
					</div>
					<hr /> */}
				</div>
			</div>
		</div>
	);
};

export default AdminDashboard;
