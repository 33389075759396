import React from 'react';
import ReportAnIssueModal from '../modals/ReportAnIssueModal';
import parseHTML from 'html-react-parser';

const Question = ({ currentQuestion, update }) => {
	// TODO: Add grayscale logo as watermark float:right bottom corner
	// TODO: Add image conditionally if question has property'imagePath'

	/*eslint-disable*/
	React.useEffect(() => {
		M.AutoInit();
	}, []);

	/*eslint-enable*/

	if (currentQuestion === undefined) {
		return <div></div>;
	}

	return (
		<div className='question z-depth-3'>
			<div className='right'>
				<ReportAnIssueModal update={update} questionID={currentQuestion.id} />
			</div>
			{parseHTML(currentQuestion.question)}
		</div>
	);
};

export default Question;
