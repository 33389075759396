import React from 'react';
import { Link } from 'react-router-dom';
import { LOGIN_PAGE } from '../../../../utils/ROUTES';
import { AuthContext } from '../../../../utils/store/context/authContext';

const VerifyYourEmail = (props) => {
	const { user } = React.useContext(AuthContext);
	const { sendVerificationEmail } = React.useContext(AuthContext);

	React.useEffect(() => {
		if (user) {
			sendVerificationEmail();
		}
		// eslint-disable-next-line
	}, []);

	// const handleClick = async () => {
	// 	window.location.reload();
	// };

	return (
		<div className='container txt-brand2'>
			<h4>¡Listo! Ya puedes empezar...</h4>
			<p>Te hemos enviado un correo para verificar que eres tú.</p>
			<p>
				<strong>Es opcional</strong>, pero recomendado, por favor, revisa tu
				bandeja de entrada o tu bandeja de correo no deseado. Solo tienes que
				darle click al enlace en el correo y podrás continuar con la creación de
				tu cuenta.
			</p>
			<p>
				<strong>ESTAR VERIFICADO O NO, NO LIMITARÁ TU NIVEL DE ACCESO.</strong>
			</p>

			<p>
				La verificación del correo solo nos ayuda a confirmar que el correo es
				tuyo. Puedes proceder a iniciar sesión cuendo gustes.
			</p>
			<div className='right-align mt-2 mb-4'>
				{/* <button
					className='btn bg-secondary mr-2'
					onClick={sendVerificationEmail}>
					{window.innerWidth < 767 ? 'Re-enviar' : 'No recibí correo'}
				</button> */}

				<Link to={LOGIN_PAGE} className=' btn btn-larg bg-brand'>
					Iniciar Sesión
					<i className='material-icons right'>send</i>
				</Link>
			</div>
		</div>
	);
};

export default VerifyYourEmail;
