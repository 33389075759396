import React from 'react';
import { AuthContext } from '../../../utils/store/context/authContext';
import ReactStars from 'react-rating-stars-component';

const ReviewRequest = (props) => {
	const { storePostTestReview } = React.useContext(AuthContext);
	const [rating, setRating] = React.useState(0);
	const [review, setReview] = React.useState('');

	const skipReview = () => {
		let data = {
			isValid: false,
			feedback: 'Chose not to leave review.',
			rating,
		};
		storePostTestReview(data);
	};

	const ratingChanged = (newRating) => {
		setRating(newRating);
	};

	const handleSubmit = (e) => {
		e.preventDefault();
		let data = {
			isValid: true,
			feedback: review,
			rating,
		};
		storePostTestReview(data);
	};

	return (
		<div className='row'>
			<form className='col s12' onSubmit={handleSubmit}>
				<h3 className='txt-brand2'>
					¿Tienes un minuto para dejarnos un review?
				</h3>
				<p>
					Para nosotros tu opinión como usuario es lo más importante y solo tú
					puedes dejarnos saber qué tenemos que mejorar, qué te gustó, que no te
					gustó y qué te hubiese gustado tener que aún no tenemos.
				</p>
				<div className='row'>
					<div className='input-field col s12'>
						<div className='mb-1'>
							<b>Rating</b>
						</div>
						<ReactStars
							count={5}
							onChange={ratingChanged}
							size={50}
							isHalf={true}
							emptyIcon={<i className='far fa-star'></i>}
							halfIcon={<i className='fa fa-star-half-alt'></i>}
							fullIcon={<i className='fa fa-star'></i>}
							activeColor='#ffd700'
						/>
					</div>
					<div className='input-field col s12'>
						<label htmlFor='review'>
							<i
								className='material-icons txt-brand2'
								style={{ marginRight: '1rem' }}>
								message
							</i>
							¿Cómo fue tu experiencia con PrepMedRD?
						</label>

						<textarea
							id='review'
							className='materialize-textarea'
							onChange={(e) => setReview(e.target.value)}></textarea>
					</div>
					<button onClick={skipReview}>No voy a dejar review</button>
					{review.length > 5 && rating > 0 && (
						<div className='right-align'>
							<button className='btn btn-large bg-brand' onClick={handleSubmit}>
								<i className='material-icons right'>send</i>Continuar
							</button>
						</div>
					)}
				</div>
			</form>
		</div>
	);
};

export default ReviewRequest;
