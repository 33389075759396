// TODO: backup users
// TODO: backup categories
// TODO: https://medium.com/@BrodaNoel/how-to-backup-firebase-firestore-8be94a66138c

export const questionsBackupDownload = (data, fileName, contentType) => {
	var a = document.createElement('a');
	var file = new Blob([data], { type: contentType });
	a.href = URL.createObjectURL(file);
	a.download = fileName;
	a.click();
};

/*eslint-disable*/
export let firebaseQuestions = '';

// let firebaseUsers = '';
// let firebaseCategories = '';
