import React from 'react';

const DiscountOffer = ({ priceStripe, pricePaypal }) => {
	const defaultDiscount = true;

	if (defaultDiscount) {
		return (
			<div className='alert center fsize-medium'>
				<p>
					¡Aprovecha nuestra oferta de apertura para tener acceso con{' '}
					<strong>un solo pago</strong> de RD${priceStripe} (US${pricePaypal})!
				</p>
				{/* <p>
					Usa el código:<strong>prepmed2022</strong>
				</p> */}
				<p>
					Este único pago te cubre acceso hasta la fecha en que se otorgue el
					examen.
				</p>
			</div>
		);
	} else {
		return <div></div>;
	}
};

export default DiscountOffer;
