import React from 'react';
import { Link } from 'react-router-dom';
import M from 'materialize-css';
import { AuthContext } from '../../../utils/store/context/authContext';
import { FirestoreContext } from '../../../utils/store/context/firestoreContext';
import { FLASHCARDS_MODE } from '../../../utils/ROUTES';
import TestSelectionsModal from '../quiz/modals/InfoModal';

const TakeFlashcards = (props) => {
	const [showModal, setShowModal] = React.useState(false);
	const [banco, setBanco] = React.useState('todos');
	const [length, setLength] = React.useState(false);
	const [mode, setMode] = React.useState('aleatorio');
	const [topics, setTopics] = React.useState([]);
	const [showOtherQty, setShowOtherQty] = React.useState(false);
	const [availableFlashcards, setAvailableFlashcards] = React.useState([]);

	const { user } = React.useContext(AuthContext);
	const { categories, flashcards, getFlashcards } =
		React.useContext(FirestoreContext);

	React.useEffect(() => {
		M.AutoInit();
		getFlashcards();
	}, []);

	React.useEffect(() => {
		flashcards && calculateFlashcards();
		// eslint-disable-next-line
	}, [flashcards]);

	const calculateFlashcards = () => {
		let userFlashcards, prepmedFlashcards;

		userFlashcards = flashcards.filter((f) => {
			return f.createdBy === user.uid;
		});

		prepmedFlashcards = flashcards.filter((f) => {
			return f.createdBy === 'PrepMedRD';
		});

		setAvailableFlashcards([...userFlashcards, ...prepmedFlashcards]);
	};

	const handleCategoryToggle = (e) => {
		let topicClicked = e.target.name;
		if (topics.includes(topicClicked)) {
			let arr = [];
			arr = topics.filter((t) => {
				return t !== topicClicked;
			});
			return setTopics(arr);
		}
		setTopics([...topics, e.target.name]);
	};

	const handleSubmit = (e) => {
		e.preventDefault();

		setShowModal(true);
	};

	return (
		<div className='container mt-4'>
			<h2 className='center-on-small-only txt-brand2'>Elige tus Flashcards</h2>
			<div className='row'>
				{/* ===== DESCRIPTION ===== */}
				<div className='col s12 m6 '>
					<p>
						Las flashcards (tarjetas o fichas didácticas) son una herramienta de
						estudio enfocada en ayudar con la memorización de conceptos.
					</p>
					<strong>¿Cómo funcionan?</strong>
					<p>
						Cada flashcard tiene 2 lados, en uno de los lados de la ficha verás
						"la pregunta". Mientras que, en la otra parte (la que no ves), verás
						"la respuesta".
					</p>
					<p>
						Cuando veas "la pregunta" debes responderla (en tu mente o en voz
						alta), darle a continuar y luego marcar si acertaste con tu
						respuesta o no.
					</p>
				</div>

				{/* ===== FORM ===== */}
				<div className='col s12 m6'>
					<h4 className='hide-on-med-and-up center'>¿Qué quieres hacer?</h4>
					<form onSubmit={handleSubmit} className='flex-center'>
						{/* Elige un tipo de examen */}
						<div className='input-field col s12'>
							<i className='material-icons prefix center txt-brand2'>ballot</i>
							<select
								name='flashcard-qty'
								defaultValue={'elige'}
								onChange={(e) => {
									e.target.value === 'otra-cantidad' && setShowOtherQty(true);
									setLength(e.target.value);
								}}>
								<option value='elige'>- elige una-</option>
								<option value='10'>10 flashcards</option>
								<option value='20'>20 flashcards</option>
								<option value='30'>30 flashcards</option>
								<option value='40'>40 flashcards</option>
								<option value='50'>50 flashcards</option>
								<option value='otra-cantidad'>
									Elige otra cantidad de preguntas
								</option>
							</select>
							<label htmlFor='flashcard-qty'>Elige una cantidad</label>
						</div>
						{showOtherQty && (
							<div className='input-field col s12'>
								<i className='material-icons prefix center txt-brand2'>
									dialpad
								</i>
								<label htmlFor='testLengthInput'>
									¿Qué cantidad de preguntas quieres?
								</label>
								<input
									type='number'
									id='testLengthInput'
									onChange={(e) => setLength(e.target.value)}
								/>
							</div>
						)}
						{/* Elige un banco de flashcards */}
						<div className='input-field col s12'>
							<i className='material-icons prefix center txt-brand2'>label</i>
							<select
								name='flashcard-bank'
								defaultValue={banco}
								onChange={(e) => setBanco(e.target.value)}>
								<option value='todos'>Todos los flashcards</option>
								<option value='prepmed'>Flashcards de PrepMedRD</option>
								<option value='personales'>Tus flashcards</option>
							</select>
							<label htmlFor='flashcard-bank'>
								Elige un banco de Flashcards
							</label>
						</div>
						{/* Elige un modo de flashcards */}
						<div className='input-field col s12'>
							<i className='material-icons prefix center txt-brand2'>label</i>
							<select
								name='flashcard-mode'
								defaultValue={banco}
								onChange={(e) => setMode(e.target.value)}>
								<option value='aleatorio'>Aleatorio</option>
								<option value='por-categoria'>Categoría</option>
							</select>
							<label htmlFor='flashcard-mode'>Elige un modo</label>
						</div>
						{/* Elige las categorias de interes */}
						{mode === 'por-categoria' && (
							<div
								className='input-field col s12 mt-0'
								// onChange={(e) =>

								// 	// setTopics([...topics, e.target.name])
								// }
							>
								<div className='row m-1'>
									<div className='col offset-s1 s11'>
										<label htmlFor='categorias'>
											Elige las categorias de interés
										</label>
									</div>
								</div>
								<div className='col s12'>
									{categories.map((category) => {
										return (
											<div key={category.id} className='col s6 px-0 pb-1'>
												<label>
													<input
														type='checkbox'
														className='filled-in'
														name={category.name}
														checked={
															topics.includes(category.name) ? 'checked' : null
														}
														onChange={handleCategoryToggle}
													/>
													<span>{category.name}</span>
												</label>
											</div>
										);
									})}
								</div>
							</div>
						)}

						<div className='input-field col s12'>
							<button
								className='btn brand-bg right'
								type='submit'
								disabled={
									length <= 0 ||
									isNaN(length) ||
									availableFlashcards.length < length
								}>
								Empezar <i className='material-icons'>send</i>
							</button>
						</div>
						{/* <div>correctas/incorrectas</div> */}
					</form>
				</div>
			</div>

			{/* Link de empiezar el examen */}
			<TestSelectionsModal
				testReady={showModal}
				content={
					<div className='flex-center'>
						<p className='fsize-medium'>¿Estás list@ para empezar?</p>
					</div>
				}
				submit={
					<Link
						className='modal-close btn-flat'
						to={{
							pathname: FLASHCARDS_MODE,
							// pathname: '/flashcards-mode',
							state: {
								banco,
								length: parseInt(length, 10),
								mode,
								topics,
								availableFlashcards,
							},
						}}>
						<span>
							Sí <i className='material-icons right'>send</i>
						</span>
					</Link>
				}
			/>
		</div>
	);
};

export default TakeFlashcards;
