import React from 'react';
import { Link } from 'react-router-dom';
import { AuthContext } from '../../../utils/store/context/authContext';
import { FirestoreContext } from '../../../utils/store/context/firestoreContext';
import ListOfAllFlashcards from './ListOfAllFlashcards';

const StaffDashboard = () => {
	const { categories, getCategories, flashcards, getFlashcards } =
		React.useContext(FirestoreContext);

	const { user } = React.useContext(AuthContext);

	const [hideCategoryCount, setHideCategoryCount] = React.useState(true);
	const [searching, setSearching] = React.useState(false);
	const [filteredFlashcards, setFilteredFlashcards] = React.useState('');
	const [availableFlashcards, setAvailableFlashcards] =
		React.useState(flashcards);

	React.useEffect(() => {
		getFlashcards();
		getCategories();
		// return () => {
		// 	cleanup
		// }

		// eslint-disable-next-line
	}, []);

	React.useEffect(() => {
		if (flashcards) {
			if (!user.isAdmin && !user.isStaff) {
				return getFlashcardsByUser();
			}
			if (user.isAdmin || user.isStaff) {
				return setAvailableFlashcards(flashcards);
			}
		}

		// eslint-disable-next-line
	}, [flashcards]);

	const getFlashcardsByUser = () => {
		let cards;
		cards = flashcards.filter((f) => {
			return f.authorID === user.uid;
		});
		setAvailableFlashcards(cards);
	};

	const filterBy = (e) => {
		let arr = availableFlashcards.filter((f) => {
			return (
				f.front.includes(e.target.value) || f.back.includes(e.target.value)
			);
		});
		setFilteredFlashcards(arr);
	};

	if (!availableFlashcards) return <div>No flashcards</div>;

	return (
		<div className='container mt-4'>
			<h5 className='txt-brand2'>Lista de Flashcards</h5>
			<div className='d-flex' style={{ justifyContent: 'space-between' }}>
				<div className='d-flex' style={{ justifyContent: 'start' }}>
					{/* ///TOTAL DE FLASHCARDS */}
					<div className='card-panel'>
						<div className='d-flex'>
							<div className='d-flex' style={{ flexDirection: 'column' }}>
								<div className='center fsize-display'>
									{
										availableFlashcards.filter((f) => {
											return f.authorID === user.uid;
										}).length
									}
								</div>
								<div>
									Total de Flashcards
									{(user.isAdmin || user.isStaff) && <span> Usuarios</span>}
								</div>
							</div>
						</div>
					</div>

					{/* ///TOTAL DE FLASHCARDS POR USUARIOS */}
					{(user.isAdmin || user.isStaff) && (
						<div className='card-panel bg-alternate2-offwhite ml-2'>
							<div className='d-flex'>
								<div className='d-flex' style={{ flexDirection: 'column' }}>
									<div className='center fsize-display'>
										{
											flashcards.filter((f) => {
												return f.createdBy === 'PrepMedRD';
											}).length
										}
									</div>
									<div>Flashcards PrepMedRD</div>
								</div>
							</div>
						</div>
					)}
				</div>

				{/* ///BUTTONS */}
				<div>
					<div
						className='d-flex'
						style={{
							flexDirection: 'column',
							justifyContent: 'space-evenly',
							height: '100%',
						}}>
						<Link to='add-flashcard' className='btn bg-brand'>
							Crea tus Flashcards
						</Link>
						{availableFlashcards.length > 0 && (
							<button
								className='btn btn-flat mt-1'
								onClick={() => setHideCategoryCount(!hideCategoryCount)}>
								{hideCategoryCount
									? 'VER CONTEO POR CATEGORIA'
									: 'ESCONDER CONTEO'}
							</button>
						)}
					</div>
				</div>

				{/* </div> */}
			</div>
			{/* // ~ COUNT BY CATEGORIES */}
			{availableFlashcards.length > 0 && (
				<div className='card-panel' hidden={hideCategoryCount}>
					<h6>Flashcards por Categoría</h6>
					{categories.map((c) => {
						return (
							<div key={c.id}>
								{c.name}{' '}
								<span className='badge bg-yellow' style={{ float: 'none' }}>
									{
										availableFlashcards?.filter((f) =>
											f.categories?.includes(c.id)
										).length
									}
								</span>{' '}
							</div>
						);
					})}
				</div>
			)}

			{/* // ~SEARCH */}
			{availableFlashcards.length > 0 && (
				<div className='input-field'>
					<input
						type='text'
						name='search'
						id='search'
						onChange={filterBy}
						onFocus={() => setSearching(true)}
						onBlur={() => setSearching(false)}
						autoComplete='off'
					/>
					<label htmlFor='search'>
						<i className='material-icons'>search</i> Buscar
					</label>
				</div>
			)}
			{/* HERE */}
			{searching && filteredFlashcards ? (
				<ListOfAllFlashcards
					flashcards={filteredFlashcards}
					categories={categories}
				/>
			) : (
				<ListOfAllFlashcards
					flashcards={availableFlashcards}
					categories={categories}
				/>
			)}
		</div>
	);
};

export default StaffDashboard;
