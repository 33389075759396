import React, { useContext, useState, useEffect, useRef } from 'react';
import { Link, NavLink } from 'react-router-dom';
import { motion } from 'framer-motion';
import SignedInLinks from './SignedInLinks';
import SignedOutLinks from './SignedOutLinks';

import { AuthContext } from '../../../utils/store/context/authContext';

import logo from '../../../assets/logo/PrepDerm.png';
import LoadingFull from '../../Helpers/LoadingFull';
import {
	ADD_FLASHCARD,
	FLASHCARDS_LIST,
	QUESTION_LIST,
	REPORTS,
	TAKE_FLASHCARDS,
} from '../../../utils/ROUTES';
import { AdminContext } from '../../../utils/store/context/adminContext';

const Navbar = (props) => {
	const { user, userAuthState, signOut, isUserLoggedIn } =
		useContext(AuthContext);
	const { enurmData } = useContext(AdminContext);
	const nav = useRef();
	const [showLoading, setShowLoading] = useState(false);
	const [links2Display, setLinks2Display] = useState();
	// const [pathName, setPathName] = useState(window.location.pathname);

	/* eslint-disable */
	useEffect(() => {
		nav.current.scrollIntoView({
			alignToTop: true,
			behavior: 'smooth',
		});
		// getCurrentENURMData();
	}, []);

	useEffect(() => {
		//!code below is working but need to add matching code and also make into an object with 2 keys
		// if (user.uid && userAuthState === false) {
		// 	setShowLoading(true);
		// 	setTimeout(() => {
		// 		setShowLoading(false);
		// 		signOut();
		// 	}, 5000);
		//}
	}, [userAuthState]);

	useEffect(() => {
		// console.log('User:', user);
		// if (user.isAdmin) {
		// 	isUserLoggedIn();
		// }
		// user.hasOwnProperty('enurmTargetYear') &&
		// 	getCurrentENURMData(`enurm_${user.enurmTargetYear}`);

		if (user?.uid && !user.isAdmin) {
			runSecurityMeausures();
		}

		user?.uid
			? setLinks2Display(<SignedInLinks />)
			: setLinks2Display(<SignedOutLinks />);
	}, [user]);

	/* eslint-enable */

	// const links2Display = user?.uid ? <SignedInLinks /> : <SignedOutLinks />;

	/**
	 *
	 */
	const runSecurityMeausures = () => {
		console.info('Running security measures.');
		//! DO NOT RUN IF LOCALHOST
		// if (window.location.hostname === 'localhost') {
		// 	return;
		// }
		if (user.isAdmin) {
			return;
		}

		// SECURITIES & PROTECTIONS
		//* PREVENT CONTEXT MENU FROM OPENING
		document.addEventListener(
			'contextmenu',
			function (evt) {
				evt.preventDefault();
			},
			false
		);

		//* PREVENT CLIPBOARD COPYING
		document.addEventListener(
			'copy',
			function (evt) {
				// Change the copied text if you want
				evt.clipboardData.setData(
					'text/plain',
					'Copiar nuestro contenido es una violación directa a los acuerdos de usuario de ©PrepMedRD y a nuestros derechos de propiedad intelectual. Esta es una acción que rastreamos y queda a nuestra decisión la severidad de penalidad contra esta falta.'
				);

				// Prevent the default copy action
				evt.preventDefault();
			},
			false
		);

		//* para mas opciones de bloqueo de copy/pase: https://code-boxx.com/disable-copy-text-javascript-css/
		//* BLOQUEO DE SCREENSHOTS
		// window.addEventListener('keyup', kPress, false);
		// function kPress(e) {
		// 	// if (window.location.host === 'localhost:3000') {
		// 	// 	console.log(e);
		// 	// }
		// 	var c = e.keyCode || e.charCode;
		// 	let key = e.key;
		// 	if (c === 44 || key === 'PrintScreen') {
		// 		e.preventDefault();
		// 		//need to give a warning message since nothing I can do to prevent this
		// 	}
		// }
		// return () => {

		// };
	};

	return (
		<nav className=' z-depth-0' ref={nav}>
			{showLoading && (
				<LoadingFull
					// message={
					// 	'Se accedio a tu cuenta desde otro dispositivo. Por favor inicia tu sesión nuevamente.'
					// }
					message={'Debes re-iniciar tu sesión...'}
				/>
			)}
			<div className='nav-wrapper bg-brand2'>
				<Link
					to={user?.uid ? '/dashboard' : '/'}
					className={`brand-logo ${
						window.innerWidth < 790 ? 'center' : 'ml-2'
					}`}>
					<motion.img
						src={logo}
						alt='prepmed-logo'
						className='logo'
						initial={{ y: '-100vh' }}
						animate={{ y: 0 }}
						transition={{
							type: 'spring',
							delay: 0.1,
							duration: 0.15,
							stiffness: 155,
						}}
					/>
				</Link>
				<motion.div
					className='d-inline'
					initial={{ y: '-100vh' }}
					animate={{ y: 0 }}
					transition={{
						type: 'spring',
						delay: 0.5,
						duration: 0.15,
						stiffness: 155,
					}}>
					{links2Display}
				</motion.div>
			</div>

			{/*// Mobile Sidenav - Signed OUT links */}
			<ul className='sidenav' id='mobile-nav-menu-signed-out'>
				<li>
					<NavLink to='/' className='sidenav-close'>
						PrepMed
					</NavLink>
				</li>
				<li>
					<NavLink to='/sobre-nosotros' className='sidenav-close'>
						Sobre Nosotros
					</NavLink>
				</li>
				<li>
					<NavLink to='/acceder' className='sidenav-close'>
						Iniciar Sesión
					</NavLink>
				</li>
				<li>
					<NavLink to='/registrate' className='sidenav-close'>
						Crea tu Cuenta
					</NavLink>
				</li>
			</ul>

			{/* // Mobile Sidenav - Signed IN links */}
			<ul className='sidenav' id='mobile-menu-signed-in'>
				<li>
					<NavLink to='/dashboard' className='sidenav-close'>
						<i className='material-icons'>dashboard</i>
						Dashboard
					</NavLink>
				</li>
				{!enurmData.testResultsOut &&
					(user?.isClient_2022 || user?.freeTrialActive) && (
						<>
							<li>
								<NavLink to='/elige' className='sidenav-close'>
									<i className='material-icons'>quiz</i>
									Examínate
								</NavLink>
							</li>
							<li className='divider'></li>
							<li>
								<Link to={TAKE_FLASHCARDS} className='sidenav-close'>
									<i className='material-icons'>indeterminate_check_box</i>
									Flashcards
								</Link>
							</li>
							<li>
								<Link to={FLASHCARDS_LIST} className='sidenav-close'>
									<i className='material-icons'>format_list_bulleted</i>
									Edita
								</Link>
							</li>
							<li>
								<Link to={ADD_FLASHCARD} className='sidenav-close'>
									<i className='material-icons'>add</i>
									Agrega
								</Link>
							</li>
							<li className='divider'></li>
						</>
					)}

				{/* //!Admin only pages */}
				{user.isAdmin && (
					<React.Fragment>
						{/* <!-- Dropdown Trigger --> */}
						<li className='divider'></li>
						<li>
							<NavLink to={QUESTION_LIST} className='sidenav-close'>
								<i className='material-icons'>question_answer</i>Questions
							</NavLink>
						</li>
						<li>
							<NavLink to='/add-questions' className='sidenav-close'>
								<i className='material-icons'>add</i>
								Add Questions
							</NavLink>
						</li>
						<li>
							<NavLink to='/edit-mode' className='sidenav-close'>
								<i className='material-icons'>edit</i>Edit Mode
							</NavLink>
						</li>
						<li className='divider'></li>
						<li>
							<NavLink to={FLASHCARDS_LIST} className='sidenav-close'>
								<i className='material-icons'>indeterminate_check_box</i>
								Flashcards
							</NavLink>
						</li>
						{/* <li>
							<NavLink to={ADD_FLASHCARD} className='sidenav-close'>
								<i className='material-icons'>add</i>Add Flashcards
							</NavLink>
						</li> */}
						<li>
							<Link to={FLASHCARDS_LIST} className='sidenav-close'>
								<i className='material-icons'>format_list_bulleted</i>
								Edita
							</Link>
						</li>
						<li>
							<Link to={ADD_FLASHCARD} className='sidenav-close'>
								<i className='material-icons'>add</i>
								Agrega
							</Link>
						</li>
						<li className='divider'></li>
						<li>
							<NavLink to={REPORTS} className='sidenav-close'>
								<i className='material-icons'>analytics</i> Reports
							</NavLink>
						</li>
					</React.Fragment>
				)}
				<li>
					{/* <a href='#!' onClick={logout}>
						Log Out
					</a> */}
					<NavLink to='/' onClick={signOut} className='sidenav-close'>
						<i className='material-icons'>logout</i> Cerrar Sesión
					</NavLink>
				</li>
			</ul>
		</nav>
	);
};

export default Navbar;
