import React from 'react';
import LogoDisplay from './LogoDisplay';

function AboutUs() {
	return (
		<div className='container'>
			<LogoDisplay />
			<h4>¡Hola!</h4>

			{/* <p>
				El sueño de todo médico es ayudar a sus pacientes. A lo largo de la
				evolución de la Medicina han ido surgiendo especialidades y
				sub-especialidades que se enfocan en diferentes enfermedades. Una parte
				importante en el desarrollo profesional de cualquier médico es elegir
				una especialidad, una vez elegida la especialidad el médico candidato
				debe demostrar que es el mejor candidato posible para los puestos
				disponibles en dicha especialidad.
			</p>
			<p>
				En República Dominicana el proceso de encontrar al mejor candidato
				involucra diferentes partes: historial académico del candidato, su
				pasantía médica, una entrevista profesional y tomar un examen
				estandarizado nacional.
			</p>
			<p>
				Este examen es conocido como Examen Nacional Único para Residencias
				Médicas (ENURM) y es administrado una vez al año. Aunque todas las
				partes de la aplicación tienen su mérito, el examen determina el grueso
				del puntaje final del candidato a la hora de aplicar para su
				especialidad deseada.
			</p>
			<p>
				PrepMed nace de la necesidad de estandarizar la preparación para el
				examen nacional y tener una herramienta de estudio que optimice la nota
				alcanzada por el candidato. PrepMed es una herramienta 100% criolla,
				diseñada basándose en el temario de contenido del ENURM y enfocado en
				ampliar el conocimiento del candidato. PrepMed crea simulaciones del
				examen, las cuales permiten al candidato acostumbrarse a la
				interpretación de preguntas e identificar áreas de debilidad en su
				preparación.
			</p>
			<p>
				Al utilizar PrepMed el candidato tiene acceso a un contenido médico
				validado con fuentes confiables y actualizadas. Las preguntas han sido
				creadas para ser iterativas, interactivas y progresivas: las preguntas
				de los diferentes temas refuerzan los puntos de aprendizaje y expanden
				progresivamente el conocimiento del usuario, mientras que el algoritmo
				propietario de PrepMed expone al usuario a preguntas aleatorias y que
				van adaptándose según su dominio de los temas evaluados.
			</p>
			<p>
				PrepMed representa la herramienta para estudiar que los creadores
				hubiéramos deseado tener disponible durante nuestra formación académica.
				Con mucho amor le entregamos a este producto y esperamos en un futuro
				ser colegas.
			</p>
			<p>
				Gracias a ustedes futuros colegas y esperamos esta herramienta los ayude
				a cumplir sus sueños y dar un mejor servicio a sus futuros pacientes.
			</p> 
			*/}

			<p>
				Sabemos que tu sueño es ayudar a tus pacientes. A lo largo de la
				evolución de la Medicina, han surgido especialidades enfocadas en cada
				necesidad y una parte importante en tu desarrollo profesional será
				elegir una de estas especialidades, y demostrar que eres el mejor
				candidato para los puestos que estén disponibles dentro de la misma.
			</p>

			<p>
				Para integrarte a la Medicina en el país, tendrás que tener un buen
				historial académico, una pasantía, una entrevista profesional, y también
				tendrás que tomar el examen nacional estandarizado “Examen Nacional
				Único para Residencias Médicas (ENURM)”, el cual es facilitado una vez
				al año. El mismo, identificará tu nivel de conocimiento del área en que
				te quieres especializar.
			</p>
			<p>
				Identificando la necesidad de estandarizar la preparación para el examen
				nacional y facilitar información actualizada de alto nivel, nos unimos
				para poner a tu disposición “PrepMed”, una herramienta especialmente
				diseñada para especializarte y prepararte para el ENURM.
			</p>
			<p>
				Tendrás acceso 24/7 a contenido actualizado para tu estudio, incluyendo
				simulaciones del examen para entrenarte, además de preguntas, respuestas
				y explicaciones que aumentarán tus conocimientos del área en que te
				especializarás.
			</p>
			<p>
				Con PrepMed, creamos la herramienta para estudiar que hubiéramos deseado
				tener durante nuestra formación académica. Con mucho amor te entregamos
				este proyecto. ¡Gracias! Esperamos escuchar tu testimonio de haber
				cumplido tu sueño, brindando el mejor de los servicios a tus pacientes.
			</p>
			{/* <div className='row mt-2 mb-4 mx-2 mx-0-m'>
				<div className='col s6' style={{ textAlign: 'left' }}>
					<p className='mb-0'>
						<strong>Dr. Felix M. Reyes</strong>
					</p>
					<p className='m-0'>Co-fundador</p>
					<p className='m-0'>Director de Contenido</p>
				</div>
				<div className='col s6' style={{ textAlign: 'right' }}>
					<p className='mb-0'>
						<strong>Lic. Cesar A. Peguero</strong>
					</p>
					<p className='m-0'>Co-fundador</p>
					<p className='m-0'>Director de Desarrollo </p>
				</div>
			</div> */}
		</div>
	);
}

export default AboutUs;
