import React from 'react';
import ReactDOM from 'react-dom';
import './utils/styles/index.css';
import App from './components/App';
import M from 'materialize-css';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import ErrorBoundary from './components/ErrorBoundary';
// export const stripePromise = loadStripe('pk_test_E4DJhrF06gq49daJZA25obttRmc', {
// 	locale: 'es',
// });
// export const stripePromise = loadStripe(
// 	process.env.REACT_APP_STRIPE_PUBLIC_TEST
// );

// const public_key =
// process.env.NODE_ENV === 'production'
// ? process.env.REACT_APP_STRIPE_PUBLIC_LIVE
// : process.env.REACT_APP_STRIPE_PUBLIC_TEST;

const public_key = process.env.REACT_APP_STRIPE_PUBLIC;

export const stripePromise = loadStripe(public_key);

//import fbConfig from './config/fbConfig';
//import firebase from 'firebase/app';

ReactDOM.render(
	<React.StrictMode>
		<Elements stripe={stripePromise}>
			<ErrorBoundary>
				<App />
			</ErrorBoundary>
		</Elements>
	</React.StrictMode>,
	document.getElementById('root')
);

M.AutoInit();
