import React, { useState, useEffect } from 'react';

// import Search from './Search';
import { Link } from 'react-router-dom';
import QuestionLi from './QuestionLi';
import LoadingSection from '../../Helpers/LoadingSection';
// import SelectSubCategories from './modals/select-categories/SelectSubCategories';
import Pagination from '../../Helpers/Pagination';

let PageSize = 10;

const QuestionsSummaryList = ({
	questions,
	getQuestionsFromDB,
	categories,
}) => {
	let localQuestions = JSON.parse(localStorage.getItem('prepmed-questions'));

	const [loading, setLoading] = useState(true);
	const [showFilter, setShowFilter] = useState(false);
	const [showSubCat, setShowSubCat] = useState(false);
	const [showNoResultsMSG, setShowNoResultsMSG] = useState(false);
	const [issuesFilter, setIssuesFilter] = useState(false);
	const [needsImage, setNeedsImage] = useState(false);
	const [needsVideo, setNeedsVideo] = useState(false);
	const [filteredQuestions, setFilteredQuestions] = useState([]);
	const [categorySelect, setCategorySelect] = useState('default');
	const [enurmSelect, setEnurmSelect] = useState('default');
	const [selectedCat, setSelectedCat] = useState('');
	const [searchBy, setSearchBy] = useState('question');

	const handleSearch = (e) => {
		setShowNoResultsMSG(false);
		setShowFilter(false);
		setIssuesFilter(false);
		// if (filteredQuestions.length > 0) {
		// 	setShowFilter(true);
		// } else if (showFilter && filteredQuestions.length < 0) {
		// 	setShowFilter(false);
		// } else {
		// 	setShowFilter(false);
		// }

		let result = questions.filter((question) => {
			return (
				(question.category &&
					question.category
						.toLowerCase()
						.includes(e.target.value.toLowerCase())) ||
				(question[searchBy] &&
					question[searchBy]
						.toLowerCase()
						.includes(e.target.value.toLowerCase())) ||
				(question.id &&
					question.id.toLowerCase().includes(e.target.value.toLowerCase())) ||
				(question.id && question.id.includes(e.target.value))
			);
		});

		setFilteredQuestions(result);
		if (result.length < 1) {
			return setShowNoResultsMSG(true);
		}

		if (e.target.value === '') {
			setShowFilter(false);
		}
	};

	const filterByIssues = (e) => {
		setCategorySelect('default');
		setIssuesFilter(!issuesFilter);

		if (!issuesFilter) {
			setFilteredQuestions(
				questions.filter((question) => {
					return (
						question.hasOwnProperty('issues') ||
						!question.hasOwnProperty('fuente') ||
						!question.hasOwnProperty('question') ||
						!question.hasOwnProperty('answer') ||
						!question.hasOwnProperty('option1') ||
						!question.hasOwnProperty('option2') ||
						!question.hasOwnProperty('option3')
					);
				})
			);
		} else {
			setFilteredQuestions([]);
		}
	};

	const filterByCategory = (e) => {
		if (e.target.value === 'all') return handleShowFilterClick(e);
		setIssuesFilter(false);
		setShowSubCat(true);
		setCategorySelect(e.target.value);
		if (e.target.value !== 'all') {
			setFilteredQuestions(
				questions.filter((question) => {
					return question.category === e.target.value;
				})
			);
		}
	};

	const filterBySubCat = (e) => {
		if (e.target.value === 'all') {
			setFilteredQuestions(
				questions.filter((question) => {
					return question.category === categorySelect;
				})
			);
		}
		// return handleShowFilterClick(e);
		// setIssuesFilter(false);

		if (e.target.value !== 'all') {
			if (e.target.value !== 'no-categories') {
				setFilteredQuestions(
					questions.filter((question) => {
						if (question.hasOwnProperty('subCategory')) {
							return question.subCategory === e.target.value;
						} else {
							return question.sub_category === e.target.value;
						}
					})
				);
			} else {
				setFilteredQuestions(
					questions.filter((question) => {
						return (
							question.category === categorySelect && !question.subCategory
						);
					})
				);
			}
		}
	};

	const filterByEnurm = (e) => {
		setShowNoResultsMSG(false);
		if (e.target.value === 'all') return handleShowFilterClick(e);
		setIssuesFilter(false);
		setEnurmSelect(e.target.value);

		if (e.target.value !== 'all') {
			let result = questions.filter((question) => {
				return question.enurm === e.target.value;
			});
			// setFilteredQuestions(
			// 	questions.filter((question) => {
			// 		return question.enurm === e.target.value;
			// 	})
			// );
			console.info('RESULTS:', result, result.length);
			if (result.length < 1) {
				return setShowNoResultsMSG(true);
			} else {
				setFilteredQuestions(result);
			}
		}
	};

	const filterByNeedsImage = (e) => {
		let result = [];
		setShowNoResultsMSG(false);

		setNeedsImage(!needsImage);
		setNeedsVideo(false);
		setIssuesFilter(false);
		if (needsImage) {
			setFilteredQuestions(questions);
			return;
		} else {
			result = questions.filter((question) => {
				return question?.hasOwnProperty('needs_image');
			});
			setFilteredQuestions(result);
			if (result.length < 1) {
				return setShowNoResultsMSG(true);
			}
			return;
		}
	};
	const filterByNeedsVideo = (e) => {
		let result = [];
		setShowNoResultsMSG(false);

		setNeedsVideo(!needsVideo);
		setNeedsImage(false);
		setIssuesFilter(false);
		if (needsVideo) {
			setFilteredQuestions(questions);
			return;
		} else {
			result = questions.filter((question) => {
				return question?.hasOwnProperty('needs_video');
			});
			setFilteredQuestions(result);
			if (result.length < 1) {
				return setShowNoResultsMSG(true);
			}
			return;
		}
	};

	const filterByIssuesList = (e) => {
		console.info(e.target.value, e.target.value === 'repeatAnswers');
		let result = [];
		setShowNoResultsMSG(false);
		setNeedsImage(false);
		setNeedsVideo(false);
		if (e.target.value === 'message') {
			setFilteredQuestions(
				(result = questions.filter((question) => {
					return (
						question.hasOwnProperty('issues') &&
						question.issues.hasOwnProperty('message') &&
						question.issues.message.length > 0
					);
				}))
			);
			setFilteredQuestions(result);
			if (result.length < 1) {
				return setShowNoResultsMSG(true);
			}
			return;
		}

		// ~--
		if (e.target.value === 'isMissingAnswers') {
			questions.forEach((question) => {
				if (
					question?.issues?.hasOwnProperty('isMissingAnswers') ||
					!question.hasOwnProperty('answer') ||
					!question.hasOwnProperty('option1') ||
					!question.hasOwnProperty('option2') ||
					!question.hasOwnProperty('option3')
				) {
					result.push(question);
				}
			});

			setFilteredQuestions(result);
			if (result.length < 1) {
				return setShowNoResultsMSG(true);
			}
			return;
		}

		// ~--
		if (e.target.value === 'hasGramaticalError') {
			setFilteredQuestions(
				(result = questions.filter((question) => {
					return question?.issues?.hasOwnProperty('hasGramaticalError');
				}))
			);
			setFilteredQuestions(result);
			if (result.length < 1) {
				return setShowNoResultsMSG(true);
			}
			return;
		}

		// ~--
		if (e.target.value === 'isMissingQuestion') {
			questions.forEach((question) => {
				if (
					question?.issues?.hasOwnProperty('isMissingQuestion') ||
					!question.hasOwnProperty('question')
				) {
					result.push(question);
				}
			});
			setFilteredQuestions(result);
			if (result.length < 1) {
				return setShowNoResultsMSG(true);
			}
			return;
		}

		// ~--
		if (e.target.value === 'isMissingExplanation') {
			questions.forEach((question) => {
				if (
					question?.issues?.hasOwnProperty('isMissingExplanation') ||
					!question.hasOwnProperty('explanation')
				) {
					result.push(question);
				}
			});
			setFilteredQuestions(result);
			if (result.length < 1) {
				return setShowNoResultsMSG(true);
			}
			return;
		}

		// ~--
		if (e.target.value === 'isMissingTheExplanationSource') {
			questions.forEach((question) => {
				if (question?.issues?.hasOwnProperty('isMissingTheExplanationSource')) {
					result.push(question);
				}
				if (!question.hasOwnProperty('fuente')) {
					console.info('NO TIENE FUENTE:', question);
					result.push(question);
				}
			});
			setFilteredQuestions(result);
			if (result.length < 1) {
				return setShowNoResultsMSG(true);
			}
			return;
		}

		// ~--
		if (e.target.value === 'noCategory') {
			setFilteredQuestions(
				(result = questions.filter((question) => {
					return !question?.hasOwnProperty('category');
				}))
			);
			setFilteredQuestions(result);
			if (result.length < 1) {
				return setShowNoResultsMSG(true);
			}
			return;
		}

		// ~--
		if (e.target.value === 'repeatAnswers') {
			questions.forEach((q) => {
				if (
					q.question === q.option1 ||
					q.question === q.option2 ||
					q.question === q.option3 ||
					q.question === q.answer ||
					q.option1 === q.question ||
					q.option1 === q.option2 ||
					q.option1 === q.option3 ||
					q.option1 === q.answer ||
					q.option2 === q.option1 ||
					q.option2 === q.question ||
					q.option2 === q.option3 ||
					q.option2 === q.answer ||
					q.option3 === q.option1 ||
					q.option3 === q.option2 ||
					q.option3 === q.question ||
					q.option3 === q.answer ||
					q.answer === q.option1 ||
					q.answer === q.option2 ||
					q.answer === q.option3 ||
					q.answer === q.question
				) {
					result.push(q);
				}
			});

			setFilteredQuestions(result);
			if (result.length < 1) {
				return setShowNoResultsMSG(true);
			}
			return;
		}

		// ~--
		if (e.target.value !== 'all') {
			result = questions.filter((question) => {
				return (
					question.hasOwnProperty('issues') &&
					question.issues.hasOwnProperty('list') &&
					question.issues.list.includes(e.target.value)
				);
			});
			setFilteredQuestions(result);
			if (result.length < 1) {
				return setShowNoResultsMSG(true);
			}
			return;
		}
		setFilteredQuestions(
			questions.filter((question) => {
				return question.hasOwnProperty('issues');
			})
		);
	};

	const handleDirectPull = (e) => {
		e.preventDefault();
		if (window.confirm('Are you sure you want to make a fresh pull?')) {
			getQuestionsFromDB();
		} else {
			alert('No request was made to Firestore DB');
		}
	};

	const handleShowFilterClick = (e) => {
		e.preventDefault();
		setShowFilter(!showFilter);
		setFilteredQuestions([]);
		setShowSubCat(false);
		setIssuesFilter(false);
		setEnurmSelect('default');
		setCategorySelect('default');
		setSelectedCat('');
		setSearchBy('question');
	};

	/*eslint-disable */
	useEffect(() => {
		localQuestions = JSON.parse(localStorage.getItem('prepmed-questions'));
	}, []);

	useEffect(() => {
		loading && questions && setLoading(false);
		// setTimeout(() => {
		// 	loading && questions && setLoading(false);
		// }, 500);
	}, [questions, loading]);

	useEffect(() => {
		if (showSubCat) {
			let sub_cat_select = document.querySelector('select#subCategory');
			let selectedCategory = categories.filter((cat) => {
				return cat.name === categorySelect;
			});

			setSelectedCat(...selectedCategory);

			selectedCategory[0].sub_categories
				? selectedCategory[0].sub_categories.forEach((option) => {
						sub_cat_select.insertAdjacentHTML(
							'beforeend',
							`<option value=${option.name}
			                            key=${option.name}>${option.name}</option>`
						);
				  })
				: setShowSubCat(false);
		}
	}, [showSubCat, categorySelect]);
	/*eslint-enable */

	const [currentPage, setCurrentPage] = useState(1);
	const [total, setTotal] = useState();

	const currentTableData = React.useMemo(() => {
		let data =
			filteredQuestions.length > 0
				? filteredQuestions
				: questions.sort((a, b) => {
						let sortMethod;

						if (
							b.createdOn?.hasOwnProperty('seconds') &&
							a.createdOn?.hasOwnProperty('seconds')
						) {
							sortMethod = b.createdOn.seconds - a.createdOn.seconds;
						}

						if (!b.createdOn?.hasOwnProperty('seconds')) {
							let dateInJSON = b.createdOn;
							let date = new Date(dateInJSON.substr(0, 10)).toDateString();

							sortMethod =
								new Date(date) - new Date(a.createdOn.seconds).toDateString();
						}

						if (!a.createdOn?.hasOwnProperty('seconds')) {
							let dateInJSON = a.createdOn;
							let date = new Date(dateInJSON.substr(0, 10)).toDateString();

							sortMethod =
								new Date(b.createdOn.seconds).toDateString() - new Date(date);
						}

						return sortMethod;
				  });

		setTotal(data);
		const firstPageIndex = (currentPage - 1) * PageSize;
		const lastPageIndex = firstPageIndex + PageSize;
		return data.slice(firstPageIndex, lastPageIndex);
	}, [currentPage, filteredQuestions, questions]);

	if (!questions) {
		return <div>Cargando...</div>;
	}
	return (
		<React.Fragment>
			{localQuestions && (
				<h6 className='right txt-danger'>
					Getting questions from localStorage
				</h6>
			)}
			<div
				className={showFilter ? 'card' : 'card border-0 z-depth-0'}
				style={
					showFilter ? { background: 'white' } : { background: 'transparent' }
				}>
				{/* //!SEARCH & FILTER */}
				<form className='card-content'>
					<div className='row mb-0'>
						<div className='col s12'>
							<div className='input-field mb-0'>
								<input
									type='text'
									id='search'
									onChange={handleSearch}
									placeholder='Search by ID, category or question content...'
									style={{ paddingLeft: '4rem', width: 'calc(100% - 4rem)' }}
									autoComplete='off'
								/>

								<label className='label-icon' htmlFor='search'>
									<i className='small material-icons'>search</i>
								</label>
							</div>
						</div>
						<div className='col s8'>
							<label>
								<input
									type='checkbox'
									className='filled-in'
									checked={searchBy !== 'question' ? 'checked' : null}
									onClick={() =>
										searchBy === 'question'
											? setSearchBy('explanation')
											: setSearchBy('question')
									}
								/>
								<span>Search by 'explanation' instead of 'question'</span>
							</label>
						</div>
						<div className='col s4 right-align'>
							<button className='link pr-2' onClick={handleShowFilterClick}>
								{showFilter
									? 'Clear & Hide Filter Options'
									: 'Show Filter Options'}
							</button>
						</div>
						<div className='col s12'>
							<label>
								<input
									type='checkbox'
									className='filled-in'
									checked={issuesFilter ? 'checked' : null}
									onChange={filterByIssues}
									onClick={filterByIssues}
								/>
								<span>Show Questions w/ Issues Only </span>
							</label>
						</div>
						<div className='col s12'>
							<label>
								<input
									type='checkbox'
									className='filled-in'
									checked={needsImage ? 'checked' : null}
									onChange={filterByNeedsImage}
									onClick={filterByNeedsImage}
								/>
								<span>Show Questions w/ "Needs Image" </span>
							</label>
						</div>
						<div className='col s12'>
							<label>
								<input
									type='checkbox'
									className='filled-in'
									checked={needsVideo ? 'checked' : null}
									onChange={filterByNeedsVideo}
									onClick={filterByNeedsVideo}
								/>
								<span>Show Questions w/ "Needs Video" </span>
							</label>
						</div>
					</div>

					{issuesFilter && (
						<div className='card-panel z-depth-0'>
							<select
								className='browser-default'
								defaultValue='default'
								onChange={filterByIssuesList}>
								<option value='default' disabled>
									-- Select a filter option --
								</option>
								<option value='all'> All Questions with Issues </option>
								<option value='message'>Questions with Issue Message</option>
								<option value='hasGramaticalError'>Error Gramatical</option>
								<option value='isMissingQuestion'>No hay pregunta</option>
								<option value='isMissingAnswers'>Faltan respuestas</option>
								<option value='isMissingExplanation'>No hay explicación</option>
								<option value='isMissingTheExplanationSource'>
									No hay fuente de explicación
								</option>
								<option value='hasFormattingIssue'>Error de formato</option>
								<option value='noCategory'>No Category</option>
								<option value='repeatAnswers'>Respuestas repetidas</option>
							</select>
						</div>
					)}

					{showFilter && (
						<div>
							<div className='row pb-1'>
								{!issuesFilter && <div className='col s12'>Filter By:</div>}
								{/* CATEGORY */}
								{issuesFilter === false && enurmSelect === 'default' && (
									<div className='col 12'>
										<select
											className='browser-default'
											id='category'
											name='category'
											defaultValue={categorySelect}
											onChange={filterByCategory}
											tabIndex='1'
											required>
											<option value='default' disabled>
												-- Category --
											</option>
											<option value='all'>Show All</option>
											{categories &&
												categories.map((option) => {
													return (
														<option
															value={`${option.name}`}
															key={`${option.id}`}>{`${option.name}`}</option>
													);
												})}
										</select>
									</div>
								)}

								{/* SUB-CATEGORIES */}
								{/* ===== SELECT A SUBCATEGORY ===== */}
								{showSubCat && (
									<div className='col 12'>
										<select
											className='browser-default'
											id='subCategory'
											defaultValue={'default'}
											onChange={filterBySubCat}
											required
											tabIndex='2'>
											<option value='default' disabled>
												-- Select a subcategory --
											</option>
											<option value='all'>-- Todas --</option>
											<option value='no-categories'>
												-- Sin Sub Categoría --
											</option>
										</select>
										<label htmlFor='subCategory'> </label>
									</div>
								)}

								{/* ENURM */}
								{issuesFilter === false && categorySelect === 'default' && (
									<div className='col 12'>
										<select
											className='browser-default'
											id='category'
											name='category'
											defaultValue={enurmSelect}
											onChange={filterByEnurm}
											tabIndex='1'
											// disabled={issuesFilter ? 'disabled' : 'false'}
											required>
											<option value='default' disabled>
												-- ENURM or PrepDerm --
											</option>
											<option value='all'>Show All</option>
											<option value='prepderm'>Propietarias de PrepDerm</option>
											<option value='bogaert'>Bogaert</option>
										</select>
									</div>
								)}
							</div>
							{/* Get Questions Directly from DB */}
							{!issuesFilter && (
								<div className='row hide-on-med-and-down'>
									<div className='col s12'>
										<button
											className='btn bg-secondary'
											onClick={handleDirectPull}>
											Get Questions Directly from DB
										</button>
									</div>
								</div>
							)}
						</div>
					)}

					<div className='right-align'>
						{filteredQuestions.length > 0 && (
							<span className='brand-text pb-2 pr-2'>
								Total Matches Found:{' '}
								{filteredQuestions.length ? (
									<b>{filteredQuestions.length}</b>
								) : (
									0
								)}
							</span>
						)}
					</div>
				</form>
			</div>

			{showNoResultsMSG && (
				<div className='center'>
					<p>Sorry, found no questions with this search or filter...</p>
				</div>
			)}
			{/* ----- QUESTION LIST ----- */}
			{/* {!showNoResultsMSG && (
				<div className='quiz-list section p-0'>
					{loading ? (
						<LoadingSection padding={'10%'} />
					) : (
						<React.Fragment>
							{filteredQuestions.length > 0
								? filteredQuestions.map((question) => {
										return (
											<Link
												to={'/question-details/' + question.id}
												key={question.id}>
												<QuestionLi question={question} />
											</Link>
										);
								  })
								: questions &&
								  questions
										.sort((a, b) => {
											let sortMethod;

											if (
												b.createdOn?.hasOwnProperty('seconds') &&
												a.createdOn?.hasOwnProperty('seconds')
											) {
												sortMethod = b.createdOn.seconds - a.createdOn.seconds;
											}

											if (!b.createdOn?.hasOwnProperty('seconds')) {
												let dateInJSON = b.createdOn;
												let date = new Date(
													dateInJSON.substr(0, 10)
												).toDateString();

												sortMethod =
													new Date(date) -
													new Date(a.createdOn.seconds).toDateString();
											}

											if (!a.createdOn?.hasOwnProperty('seconds')) {
												let dateInJSON = a.createdOn;
												let date = new Date(
													dateInJSON.substr(0, 10)
												).toDateString();

												sortMethod =
													new Date(b.createdOn.seconds).toDateString() -
													new Date(date);
											}

											return sortMethod;
										})
										.map((question) => {
											return (
												<Link
													to={'/question-details/' + question.id}
													key={question.id}>
													<QuestionLi question={question} />
												</Link>
											);
										})}
						</React.Fragment>
					)}
				</div>
			)} */}

			{!showNoResultsMSG && (
				<div className='quiz-list section p-0'>
					{loading ? (
						<LoadingSection padding={'10%'} />
					) : (
						<>
							{/* PAGINATION */}
							{/* {(filteredQuestions.length === 0 ||
								filteredQuestions.length > PageSize) && (
								<div className='d-flex' style={{ justifyContent: 'flex-end' }}>
									<Pagination
										className='pagination-bar'
										currentPage={currentPage}
										totalCount={total.length}
										pageSize={PageSize}
										onPageChange={(page) => setCurrentPage(page)}
									/>
								</div>
							)} */}

							<div className='d-flex' style={{ justifyContent: 'flex-end' }}>
								<Pagination
									className='pagination-bar'
									currentPage={currentPage}
									totalCount={total.length}
									pageSize={PageSize}
									onPageChange={(page) => setCurrentPage(page)}
								/>
							</div>

							{/* DATA */}
							{currentTableData.map((question) => {
								return (
									<div key={question.id}>
										<Link to={'/question-details/' + question.id}>
											<QuestionLi question={question} key={question.id} />
										</Link>
									</div>
								);
							})}
						</>
					)}
				</div>
			)}
		</React.Fragment>
	);
};

export default QuestionsSummaryList;
