import React, { useEffect } from 'react';

function CookiePolicy() {
	return (
		<div className='container'>
			<h2>Política de Cookies</h2>
			<p>
				Utilizamos cookies para ayudarle a mejorar su experiencia en{' '}
				<a href='https://www.prepmedrd.com'>https://www.prepmedrd.com</a>. Esta
				política de cookies forma parte de la política de privacidad de la
				PrepMed LLC y cubre el uso de cookies entre su dispositivo y nuestro
				sitio. También proporcionamos información básica sobre servicios de
				terceros que podemos usar, quienes también pueden usar cookies como
				parte de su servicio, aunque no están cubiertos por nuestra política.
			</p>
			<p>
				Si no desea aceptar nuestras cookies, debe indicar a su navegador que
				rechace las cookies de{' '}
				<a href='https://www.prepmedrd.com'>https://www.prepmedrd.com</a>,
				siendo conocedor de que ello puede impedir proporcionarle algunos de los
				contenidos y servicios que desea.
			</p>
			<h3>¿Qué es una cookie?</h3>
			<p>
				Una cookie es una pequeña porción de datos que un sitio web almacena en
				su dispositivo cuando la visita, generalmente incluyendo información
				sobre el propio sitio web, con un identificador único que le permite al
				sitio reconocer su navegador web cuando regresa a ella, datos
				adicionales que sirven para su propósito, y la vida útil de la propia
				cookie.
			</p>
			<p>
				Las cookies se utilizan para habilitar ciertas funciones (por ejemplo,
				iniciar sesión), para rastrear el uso del sitio (por ejemplo, análisis),
				para almacenar sus configuraciones de usuario (por ejemplo, zona
				horaria, preferencias de notificación) y para personalizar su contenido
				(por ejemplo, publicidad, idioma).
			</p>
			<p>
				Las cookies establecidas por el sitio web que está visitando normalmente
				se denominan "cookies de primera parte" y, por lo general, solo rastrean
				su actividad en ese sitio en particular. Las cookies establecidas por
				otros sitios y compañías (es decir, terceros) se denominan "cookies de
				tercera parte" y se pueden usar para rastrearlo en otros sitios web que
				utilizan el mismo servicio a terceras partes.
			</p>
			<h3>Tipos de cookies y cómo las usamos</h3>
			<h4>Cookies Esenciales</h4>
			<p>
				Las cookies esenciales son cruciales para su experiencia de un sitio
				web, ya que habilitan funciones básicas como el inicio de sesión de
				usuarios, la administración de cuentas, los carritos de compras y el
				procesamiento de pagos. Utilizamos cookies esenciales para habilitar
				ciertas funciones en nuestro sitio web.
			</p>
			<h4>Cookies de rendimiento</h4>
			<p>
				Las cookies de rendimiento se utilizan en el seguimiento de cómo utiliza
				un sitio web durante su visita, sin recopilar información personal sobre
				usted. Por lo general, esta información es anónima y unida a la
				información que se rastrea a través de todos los usuarios del sitio,
				ayuda a las compañías a comprender los patrones de uso de los
				visitantes, identificar y diagnosticar problemas o errores que puedan
				encontrar, y tomar decisiones estratégicas para mejorar la experiencia
				general del sitio web. Estas cookies pueden ser establecidas por el
				sitio web que está visitando (primera parte) o por servicios de
				terceros. Nosotros utilizamos cookies de rendimiento en nuestro sitio.
			</p>
			<h4>Cookies de funcionalidad</h4>
			<p>
				Las cookies de funcionalidad se utilizan para recopilar información
				sobre su dispositivo y cualquier configuración que pueda establecer en
				el sitio web que está visitando (como la configuración de idioma y zona
				horaria). Con esta información, los sitios web pueden proporcionarle
				contenido y servicios personalizados, mejorados u optimizados. Estas
				cookies pueden ser establecidas por el sitio web que está visitando
				(primera parte) o por un servicio de terceros. Utilizamos cookies de
				funcionalidad para funciones seleccionadas en nuestro sitio.
			</p>
			<h4>Cookies de Publicidad o Direccionamiento</h4>
			<p>
				Las cookies de publicidad/direccionamiento se utilizan para determinar
				qué contenido promocional es más relevante y apropiado para usted y sus
				intereses. Los sitios web pueden usarlos para entregar publicidad
				dirigida o limitar el número de veces que ve un anuncio. Esto ayuda a
				las empresas a mejorar la efectividad de sus campañas y la calidad del
				contenido que se le presenta. Estas cookies pueden ser establecidas por
				el sitio web que está visitando (primera parte) o por servicios de
				terceros. Las cookies de publicidad/direccionamiento establecidas por
				terceros pueden usarse para rastrearle en otros sitios web que usan el
				mismo servicio de terceros. Utilizamos este tipo de cookies en nuestro
				sitio.
			</p>
			<h3>Cookies de terceras partes en nuestro sitio</h3>
			<p>
				Es posible que contratemos a terceros empresas e individuos en nuestros
				sitios web, por ejemplo, proveedores de análisis y colaboradores de
				contenido. Otorgamos acceso a estos terceros a información seleccionada
				para realizar tareas específicas en nuestro nombre. También pueden
				establecer cookies de tercera parte para ejecutar los servicios que
				están proporcionando. Las cookies de terceros se pueden usar para
				rastrearle en otros sitios web que utilizan el mismo servicio que las
				terceras partes. Como no tenemos control sobre las cookies de terceros,
				no están cubiertas por la política de cookies de PrepMed LLC.
			</p>
			<h3>Cómo controlar o rechazar las cookies</h3>
			<p>
				Si no desea aceptar nuestras cookies, puede indicar a su navegador que
				rechace las cookies de nuestro sitio web. La mayoría de los navegadores
				están configurados para aceptar cookies de forma predeterminada, pero
				puede actualizar estas configuraciones para rechazarlas por completo o
				para notificarle cuando un sitio web está intentando configurar o
				actualizar una cookie.
			</p>
			<p>
				Si navega por sitios web desde múltiples dispositivos, es posible que
				deba actualizar su configuración en cada dispositivo de forma
				individual.
			</p>
			<p>
				Aunque algunas cookies se pueden bloquear con poco impacto sobre su
				experiencia de navegación en un sitio web, el bloqueo de todas las
				cookies puede significar que no se pueda acceder a ciertas funciones y
				contenidos en los sitios que visita.
			</p>
		</div>
	);
}

export default CookiePolicy;
