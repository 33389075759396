import React from 'react';
import { FirestoreContext } from '../../../../utils/store/context/firestoreContext';

const ImageUpload = ({ questionID }) => {
	const {
		imgUpload,
		uploaderProgress,
		setUploaderProgress,
		updateQuestionbyKey,
		deleteQuestionField,
	} = React.useContext(FirestoreContext);

	const handleUpload = (e) => {
		imgUpload(e.target.files[0]);

		updateQuestionbyKey(questionID, 'imagePath', e.target.files[0].name);
		updateQuestionbyKey(questionID, 'needs_image', false);
		deleteQuestionField(questionID, 'needs_image');
	};

	React.useEffect(() => {
		return () => {
			setUploaderProgress(false);
		};
		// eslint-disable-next-line
	}, []);

	return (
		<>
			<div
				className='input-field flex-center'
				style={{ flexDirection: 'column' }}>
				<progress
					value={uploaderProgress}
					max='100'
					id='uploader'
					className='mb-2'>
					0%
				</progress>

				{uploaderProgress === 100 ? (
					<div>Upload successful!</div>
				) : (
					<input
						type='file'
						name='fileUpload'
						id='fileButton'
						onChange={handleUpload}
					/>
				)}
			</div>
		</>
	);
};

export default ImageUpload;
