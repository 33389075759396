import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import M from 'materialize-css';
import ReportAnIssueModal from '../quiz/modals/ReportAnIssueModal';
import parse from 'html-react-parser';
import parseHTML from 'html-react-parser';
import { secondsToHoursMinutesSeconds } from '../../../utils/helpers';
import Fuente from '../quiz/questionaire/Fuente';
import { FirestoreContext } from '../../../utils/store/context/firestoreContext';

const Question = ({ currentQuestion, update }) => {
	// TODO: Add grayscale logo as watermark float:right bottom corner
	// TODO: Add image conditionally if question has property'imagePath'

	/*eslint-disable*/
	useEffect(() => {
		M.AutoInit();
	}, []);
	/*eslint-enable*/

	return (
		<div className='d-inline'>
			<div className='right'>
				<ReportAnIssueModal update={update} questionID={currentQuestion.id} />
			</div>
			{parseHTML(currentQuestion.question)}

			{currentQuestion.hasOwnProperty('imagePath') &&
				currentQuestion.showImageInQuestion && (
					<div style={{ marginTop: '1rem', textAlign: 'center' }}>
						<img
							className='responsive-img'
							src={currentQuestion.imageURL}
							alt={currentQuestion.imagePath}
						/>
					</div>
				)}
		</div>
	);
};

const PreviousTestReview = (props) => {
	const { updateQuestionbyKey } = React.useContext(FirestoreContext);
	const record = { ...props.location.state };
	let count = 1;
	// const score = Math.round((record.score / record.testLength) * 100);

	const [score, setScore] = useState();
	const [testLength, setTestLength] = useState();
	const [showQuestionsSummary, setShowQuestionsSummary] = useState(false);
	const [isFiltered, setIsFiltered] = useState(false);
	const [testRecord, setTestRecord] = useState(record.questions);
	const [correctAnswers, setCorrectAnswers] = useState(0);

	useEffect(() => {
		M.AutoInit();
		setScore(Math.round((record.score / record.testLength) * 100));
		setTestLength(record.testLength);

		// gets correct answers
		record.questions.forEach((item) => {
			if (item.correctAnswer === item.selectedAnswer) {
				setCorrectAnswers((correctAnswers) => correctAnswers + 1);
			}
		});

		// CLEANUP
		return () => {
			setShowQuestionsSummary(false);
			setIsFiltered(false);
		};
	}, []);

	const scrollToTop = () => {
		const top = document.querySelector('div.prepmed');
		top.scrollIntoView({
			alignToTop: true,
			behavior: 'smooth',
		});
	};

	const filterRecordsBy = (condition) => {
		setIsFiltered(true);
		if (condition === 'all') {
			setIsFiltered(false);
			setTestRecord(record.questions);
		}
		// Incorrectas
		if (condition === 'incorrectas') {
			setTestRecord(
				record.questions.filter((each) => {
					return each.selectedAnswer !== each.correctAnswer;
				})
			);
		}
		// Correctas
		if (condition === 'correctas') {
			setTestRecord(
				record.questions.filter((each) => {
					return each.selectedAnswer === each.correctAnswer;
				})
			);
		}
	};

	return (
		<React.Fragment>
			<div className='container'>
				{showQuestionsSummary && (
					<div style={{ position: 'sticky', top: '90vh', zIndex: '1' }}>
						<button
							onClick={scrollToTop}
							title='Edit Question'
							className='btn btn-floating right bg-brand'
							style={
								window.innerWidth < 790
									? { margin: '0.10vmin', position: 'absolute', right: '-5vw' }
									: { margin: '0.10vmin', position: 'absolute', right: '-3vw' }
							}>
							<i className='material-icons'>north</i>
						</button>
					</div>
				)}
				{/* //!RESUMEN DEL EXAMEN */}
				<section className='row mb-0'>
					<div className='col s12'>
						<main className='test-results card'>
							<h4 className='d-inline'>Resumen del Examen</h4>
							<blockquote style={{ borderLeftColor: 'var(--brand-accent)' }}>
								<b>Tipo de Examen:</b>{' '}
								{record.testType === 'otro' ? 'Personalizado' : record.testType}
							</blockquote>
							<p>
								{parse(
									`En este examen viste un total de <strong>${testLength}</strong> preguntas, para una nota final de:`
								)}
							</p>
							<div className='row'>
								<div
									className='col s6 center d-flex'
									style={{ flexDirection: 'column' }}>
									<strong style={{ fontSize: 'xx-large' }}>
										{`${Math.round(((score + Number.EPSILON) / 100) * 100)}%`}
									</strong>
									<small>Nota</small>
								</div>
								<div
									className='col s6 center d-flex'
									style={{ flexDirection: 'column' }}>
									<div>
										<strong style={{ fontSize: 'xx-large' }}>
											{correctAnswers}
										</strong>
										/{testLength}
									</div>
									<small>Detalle</small>
								</div>
								{/* <div className='col s6' style={{ display: 'none' }}>
									{score > 70 ? (
										<h3 className='green-text text-darken-2 d-inline'>
											<i>APROVADO</i>
										</h3>
									) : (
										<h3 className='red-text text-darken-2 d-inline'>
											<i>REPROBADO</i>
										</h3>
									)}
								</div> */}
							</div>
							{record.useTimer && (
								<p>
									Terminaste tu{' '}
									{record.testType === 'otro' ? 'examen' : record.testType} en
									un tiempo total de:{' '}
									{secondsToHoursMinutesSeconds(record.timeElapsedInSeconds)}
								</p>
							)}

							{/* <div className='card-action center'> */}
							<div
								className={
									showQuestionsSummary ? 'card-action' : 'card-action center'
								}>
								<div className='row m-0'>
									{!showQuestionsSummary && (
										<button
											className='btn bg-brand my-1 col m6'
											onClick={(e) => setShowQuestionsSummary(true)}>
											Ver Preguntas
										</button>
									)}
									{showQuestionsSummary && (
										<React.Fragment>
											<button
												className='btn btn-flat my-1 col m6'
												onClick={() => filterRecordsBy('correctas')}>
												<span className='material-icons'>filter_alt</span>
												Ver Solo Correctas
											</button>
											<Link to='/elige' className='btn btn-flat my-1 col m6'>
												<span className='material-icons'>refresh</span>{' '}
												<span>Examínate Denuevo</span>
											</Link>
											<button
												className='btn btn-flat my-1 col m6'
												onClick={() => filterRecordsBy('incorrectas')}>
												<span className='material-icons'>filter_alt</span>
												Ver Solo Incorrectas
											</button>
										</React.Fragment>
									)}
									<Link to='/dashboard' className='btn btn-flat my-1 col m6'>
										<span className='material-icons'>dashboard</span>{' '}
										<span>Panel Principal</span>
									</Link>
									{showQuestionsSummary && isFiltered && (
										<button
											className='btn bg-brand mt-3 col m12'
											onClick={() => filterRecordsBy('all')}>
											Ver Todas las Preguntas
										</button>
									)}
								</div>
							</div>
						</main>
					</div>
				</section>

				{/* //!QUESTIONS LIST */}
				{showQuestionsSummary && (
					<section className='row'>
						<div className='col s12'>
							{testRecord.map(
								({
									questionID,
									question,
									correctAnswer,
									explanation,
									selectedAnswer,
									fuente,
								}) => {
									return (
										<div className='card' key={questionID}>
											<div className='card-content'>
												{/* // ! May not be working because of multiple modals on the same page. */}

												<div
													key={questionID}
													className={
														selectedAnswer === correctAnswer
															? 'test-result-right-answer'
															: 'test-result-wrong-answer'
													}>
													{/* //!QUESTION */}
													<article>
														<span>
															{/* {`${count++})`} {parse(question)} */}
															{`${count++})`}{' '}
															<Question
																currentQuestion={{ question, id: questionID }}
																update={updateQuestionbyKey}
															/>
														</span>
													</article>
													{/* //!RESULTS SUMMARY */}
													<div>
														<p>
															<i
																className='material-icons'
																style={{ marginTop: '-4px' }}>
																{selectedAnswer === correctAnswer
																	? 'check_box'
																	: 'error'}
															</i>
															<strong>Tu selección:</strong> {selectedAnswer}
														</p>
														{selectedAnswer !== correctAnswer && (
															<p>
																<i
																	className='material-icons'
																	style={{ marginTop: '-4px' }}>
																	forward
																</i>
																<strong>Respuesta Correcta:</strong>{' '}
																{correctAnswer}
															</p>
														)}
													</div>
												</div>
												{/* // !EXPLICACION */}
												<details>
													<summary className='txt-brand-accent'>
														{/* <span className='material-icons'>visibility</span> */}
														Explicación
													</summary>

													<div className='row'>
														<div className='col s12'>
															<div className='card-panel white grey-text text-darken-3 left-align z-depth-0'>
																<h5>Explicación:</h5>
																<div className='explanation'>
																	{parse(explanation)}
																</div>
																<Fuente id={questionID} />
															</div>
														</div>
													</div>
												</details>
											</div>
										</div>
									);
								}
							)}
						</div>
					</section>
				)}
			</div>
		</React.Fragment>
	);
};

export default PreviousTestReview;
