import React, { useContext, useState, useEffect } from 'react';
import { Link, Redirect } from 'react-router-dom';
import { EMAIL_VERIFICATION } from '../../../utils/ROUTES';
import firebase from '../../../utils/store/config/fbConfig';
import { firebaseAuth, logEvent } from '../../../utils/store/config/fbConfig';
import { AuthContext } from '../../../utils/store/context/authContext';

import LoadingFull from '../../Helpers/LoadingFull';
// import logo from '../../../assets/logo/LOGO_BLUE_FULL_COLOR.png';
import LogoDisplay from '../LogoDisplay';
import VerifyYourEmail from './sign-up/VerifyYourEmail';

const LogIn = (props) => {
	// CONTEXT
	const { user, updateUser, getCurrentUserFromFirebaseAuth, signOut } =
		useContext(AuthContext);

	// STATE
	const [email, setEmail] = useState('');
	const [password, setPassword] = useState('');
	const [authError, setAuthError] = useState('');
	const [showLogin, setShowLogin] = useState(false);
	const [loading, setLoading] = useState(true);
	const [loadingMSG, setLoadingMSG] = useState('');

	useEffect(() => {
		//! Need to re-enable this
		// setTimeout(() => {
		// 	checkAuth();
		// }, 1000);

		// let userState;
		// firebase.auth().onAuthStateChanged(async (firebaseUser) => {
		// 	debugger;
		// 	if (firebaseUser) {
		// 		updateUser(firebaseUser);
		// 		setLoading(false);
		// 		setShowLogin(true);
		// 		// setShowLogin(true);
		// 	} else {
		// 	}
		// });
		checkAuthStatus();
		setShowLogin(true);

		setTimeout(() => {
			setLoading(false);
		}, 1250);
		// cleanup;
		return () => {
			setEmail('');
			setPassword('');
			setAuthError('');
			setShowLogin(true);
			setLoading(false);
			setLoadingMSG('');
		};
		// eslint-disable-next-line
	}, []);

	const checkAuthStatus = async () => {
		await getCurrentUserFromFirebaseAuth();
	};

	// const lS = localStorage.getItem('prepmed-uuid');

	const handleChange = (e) => {
		if (e.target.id === 'email') {
			setEmail(e.target.value);
		} else if (e.target.id === 'password') {
			setPassword(e.target.value);
		} else {
			console.log('Error');
		}
	};

	const handleSubmit = async (e) => {
		e.preventDefault();
		setLoadingMSG('Validando credenciales...');
		setLoading(true);
		setTimeout(() => {
			firebaseAuth
				.signInWithEmailAndPassword(email, password)
				.then((res) => {
					firebase.analytics().logEvent('login');
					// updateUser(res.user);
				})
				.catch((err) => {
					if (err.code === 'auth/user-not-found') {
						console.log(err.code, err.message);
						// Default Message: There is no user record corresponding to this identifier. The user may have been deleted.
						setAuthError('No existe un usuario con este correo.');
					}
					if (err.code === 'auth/wrong-password') {
						console.log(err.code, err.message);
						// Default Message:  The password is invalid or the user does not have a password.
						setAuthError(
							'La contraseña es incorrecta. Por favor trata de nuevo o usa el link de "¿Olvidaste tu Contraseña?".'
						);
					}
					if (err.code === 'auth/invalid-email') {
						console.log(err.code, err.message);
						// Default Message:  The email address is badly formatted.
						setAuthError('El formato del correo electrónico es incorrecto.');
					}
					setLoading(false);
				});
		}, 850);
	};

	// const checkAuth = async () => {
	// 	// TODO: i NEED TO check if user.isLoggedIn === true, then sign them out if they are at the same location
	// 	// todo: navigator.userAgent
	// 	let userState, userData;
	// 	firebase.auth().onAuthStateChanged(async (fb_user) => {
	// 		if (fb_user) {
	// 			userState = await firebase
	// 				.firestore()
	// 				.collection('users')
	// 				.doc(firebaseAuth.currentUser.uid)
	// 				.get()
	// 				.then((doc) => {
	// 					if (!doc.exists) {
	// 						alert('ERROR: No user found!');
	// 					} else {
	// 						userData = { ...doc.data() };
	// 					}
	// 					return userData.isLoggedIn;
	// 				});

	// 			if (userState) {
	// 				setLoadingMSG(
	// 					'Tu sesión está activa en otro dispositivo. Inicia una nueva sesión...'
	// 				);
	// 				setTimeout(() => {
	// 					signOut();
	// 					setShowLogin(true);
	// 					setLoading(false);
	// 				}, 3000);
	// 			} else {
	// 				setLoadingMSG('Iniciando sesión...');
	// 				setTimeout(() => {
	// 					updateUser(firebaseAuth.currentUser);
	// 				}, 500);
	// 				// Above should be 1100
	// 			}
	// 		} else {
	// 			setShowLogin(true);
	// 			setLoading(false);
	// 		}
	// 	});
	// };

	if (loading) {
		return <LoadingFull message={loadingMSG} />;
	}

	return (
		<div className='container'>
			{/* {setTimeout(() => {
				checkAuth();
			}, 1500)} */}
			{showLogin && (
				<React.Fragment>
					{/* <div className='col s12 center'>
						{window.innerWidth > 790 && (
							<img
								src={logo}
								alt='prepmed-logo'
								className='img-reponsive w-65 pt-5'
							/>
						)}
						{window.innerWidth < 790 && <div className='py-4'></div>}
					</div> */}
					<div className='hide-on-small-and-down'>
						<LogoDisplay />
					</div>
					<div className='row pt-3' style={{ minHeight: '500px' }}>
						<div className='col m6 offset-m3 s12'>
							<form
								onSubmit={handleSubmit}
								className='white'
								autoComplete='off'>
								<h3 className='center txt-brand2 pb-2'>Inicia tu Sesión</h3>
								<div className='input-field'>
									<i className='material-icons prefix txt-brand2'>email</i>
									<label htmlFor='email'>Correo Electrónico</label>
									<input
										type='email'
										id='email'
										onChange={handleChange}
										autoComplete='null'
									/>
								</div>
								<div className='input-field'>
									<i className='material-icons prefix txt-brand2'>vpn_key</i>
									<label htmlFor='password'>Contraseña</label>
									<input
										type='password'
										id='password'
										onChange={handleChange}
									/>
								</div>
								<div style={{ textAlign: 'right' }}>
									<p className='fsize-small m-0'>
										Si aún no tienes cuenta,{' '}
										<Link to='/registrate'>¡registrala hoy!</Link>
									</p>
									<p className='fsize-small m-0'>
										<Link to='/nuevo-password'>¿Olvidaste tu Contraseña?</Link>
									</p>
								</div>
								{/* AUTH ERROR */}
								<div className='red-text center'>
									{authError ? <p>{authError}</p> : null}
								</div>
								<div className='input-field'>
									<button className='btn btn-large bg-brand z-depth-0 right'>
										<i className='material-icons right'>send</i>
										Acceder
									</button>
								</div>
							</form>
							{/* <div
								className='col m6 offset-m3 s12 d-none'
								style={{ borderTop: '1px solid #e1e1e1', marginTop: '5vh' }}>
								<p className='center teal-text text-darken-2'>
									OAuth Coming Soon...
								</p>
							</div> */}
						</div>
					</div>
				</React.Fragment>
			)}
		</div>
	);
};

export default LogIn;
