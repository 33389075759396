import React from 'react';
import { Link } from 'react-router-dom';
import { customDateFormatInSpanish } from '../../../utils/helpers';

const TestRecordTableRow = ({ record }) => {
	return (
		<tr>
			<td>
				{record.date &&
					customDateFormatInSpanish(new Date(record.date.seconds * 1000))}
				{/* {record.date &&
					customDateFormatInSpanish(
						ls ? new Date(record.date.seconds) : record.date.toDate().toString()
					)} */}
			</td>
			<td style={{ textAlign: 'center' }}>
				{record.date &&
					new Date(record.date.seconds * 1000).toLocaleTimeString()}
			</td>
			<td style={{ textAlign: 'center' }}>
				{record.testType === 'otro' ? 'Personalizado' : record.testType}
			</td>
			<td style={{ textAlign: 'center' }}>{record.testLength}</td>
			<td style={{ textAlign: 'center' }}>
				{`${Math.round(
					((record.score + Number.EPSILON) / record.testLength) * 100
				)}%`}
			</td>
			<td style={{ textAlign: 'center' }}>
				{
					<Link
						to={{
							pathname: '/resultados-anteriores',
							state: { ...record },
						}}
						className='txt-brand-accent'>
						Ver más...
					</Link>
				}
			</td>
		</tr>
	);
};

export default TestRecordTableRow;
