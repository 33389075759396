// TODO: Fix image upload bug
// TODO: Add Enurm 2017 counter
// TODO: Fix sorting bug when pulling date from localstorage
// TODO: Add filter by enurm or prepmed

/**
 * Current Selling Price
 */

export const PREPMED_PRICE = 6000;
export const PRODUCT = {
	name: 'PrepMedRD',
	description:
		'Con un pago exitoso tendrás acceso a la plataforma y herramientas de estudio que provee PrepMedRD para facilitar tu preparación para el examen de dermatología: simulaciones, flashcards, preguntas corregidas y validadas con explicaciones, entre otras cosas...',
	amount: PREPMED_PRICE * 100,
	// REMEMBER THE AMOUNT ABOVE IS IN CENTS AND SHOULD BE DIVIDED BY 100 TO GET REAL
	currency: 'dop',
	quantity: 1,
	images: [
		'https://firebasestorage.googleapis.com/v0/b/prep-med.appspot.com/o/PrepMedRD.png?alt=media&token=cee40a46-90a9-466f-8a13-a33ad84c995a',
	],
};
export const PRODUCT_PAYPAL = {
	name: 'PrepDerm',
	description:
		'Con un pago exitoso tendrás acceso a la plataforma y herramientas de estudio que provee PrepMedRD para facilitar tu preparación para el examen de dermatología: simulaciones, flashcards, preguntas corregidas y validadas con explicaciones, entre otras cosas...',
	price: 92.99,
	// REMEMBER THE AMOUNT ABOVE IS IN CENTS AND SHOULD BE DIVIDED BY 100 TO GET REAL
	// currency: 'dop',
	// quantity: 1,
	images:
		'https://firebasestorage.googleapis.com/v0/b/prep-med.appspot.com/o/PrepMedRD.png?alt=media&token=cee40a46-90a9-466f-8a13-a33ad84c995a',
};

/**
 * CONNECTION STRING
 */
export const DATABASE = 'questions';
const user_id = localStorage.getItem('prepderm-uuid');
export const DATABASE_BACKUP =
	user_id === 'CL3rF3pttcUYKq6ikJsFLYs8f3z1' ? true : false;

/**
 * AUTHENTICATION
 */
export const LOGIN = 'LOGIN_USER';

/**
 * FIREBASE
 */
export const GET_CATEGORIES = 'GET_CATEGORIES';
export const GET_ESPECIALIDADES = 'GET_ESPECIALIDADES';
export const GET_FLASHCARDS = 'GET_FLASHCARDS';
export const GET_IMAGE = 'GET_IMAGE';
export const SET_LOADING = 'SET_LOADING';
export const GET_QUESTIONS = 'GET_QUESTIONS';
export const GET_USER_LIST = 'GET_USER_LIST';

/**
 * QUIZZING
 */
export const FILTER_QUESTIONS = 'FILTER_QUESTIONS';
export const FILTER_QUESTIONS_BY_SUBCATEGORIES =
	'FILTER_QUESTIONS_BY_SUBCATEGORIES';
export const NEXT_QUESTION = 'NEXT_QUESTION';
export const SELECTED_ANSWER = 'SELECTED_ANSWER';
export const SET_TEST_QUESTIONS = 'SET_TEST_QUESTIONS';
export const TEST_LABEL = 'TEST_LABEL';
export const TEST_MODE = 'TEST_MODE';
export const TEST_TIMER_DURATION = 'TEST_TIMER_DURATION';
export const TEST_TOPICS = 'TEST_TOPICS';
export const TEST_TOPIC_SUBCATEGORIES = 'TEST_TOPIC_SUBCATEGORIES';
export const TEST_TYPE = 'TEST_TYPE';
export const UPDATE_SCORE = 'UPDATE_SCORE';
export const UPDATE_TIMER = 'UPDATE_TIMER';
export const UPDATE_TIMER_START = 'UPDATE_TIMER_START';
export const USE_TIMER = 'USE_TIMER';
export const USE_TUTOR_MODE = 'USE_TUTOR_MODE';

/**
 * UTILS
 */
export const REPORT_ERROR = 'REPORT_ERROR';
//! TO USE DEBUG HELPERS BELOW THE LOG MUST BE CUNSTRUCTED LIKE THIS:
// console.log(
// 	'%c Current User State: ',
// 	DEBUG_BLUE,
// 	'SOMETHING TO LOG'
// );
export const DEBUG_RED =
	'background: #ff7676; color: #212121; padding: 0.5vh 2vmax;';
export const DEBUG_BLUE =
	'background: #8A8AFF; color: #212121; padding: 0.5vh 2vmax;';
export const DEBUG_YELLOW =
	'background: #ffd700; color: black; font-weight: 600; padding: .5vmax 1vmax;';
export const DEBUG_GREEN =
	'background: #61b16c; color: white; font-weight: 600; padding: .5vh 1vmax;';
