import React from 'react';
import { Link } from 'react-router-dom';
import {
	DASHBOARD,
	ADD_QUESTIONS,
	QUESTION_LIST,
	EDIT_MODE,
	REPORTS,
	ADD_FLASHCARD,
	FLASHCARDS_LIST,
} from '../../../utils/ROUTES';
const NavLinksStaff = () => {
	return (
		// <!-- Dropdown Structure -->
		<React.Fragment>
			<ul id='dropdown1' className='dropdown-content'>
				<li>
					<Link to={DASHBOARD}>
						<i className='material-icons'>dashboard</i>
						Dashboard
					</Link>
				</li>
				<li className='divider'></li>

				<li>
					<Link to={QUESTION_LIST}>
						<i className='material-icons'>question_answer</i>
						Questions
					</Link>
				</li>
				{/* <li>
					<Link to={ADD_QUESTIONS}>
						<i className='material-icons'>add</i>
						Add Questions
					</Link>
				</li> */}
				{/* <li>
					<Link to={EDIT_MODE}>
						<i className='material-icons'>edit</i>
						Edit Mode
					</Link>
				</li> */}
				<li className='divider'></li>
				<li>
					<Link to={FLASHCARDS_LIST}>
						<i className='material-icons'>indeterminate_check_box</i>
						Flashcards
					</Link>
				</li>
				<li>
					<Link to={ADD_FLASHCARD}>
						<i className='material-icons'>add</i>
						Add Flashcard
					</Link>
				</li>
				<li className='divider'></li>
				<li>
					<Link to={REPORTS}>
						<i className='material-icons'>analytics</i> Reports
					</Link>
				</li>
			</ul>
		</React.Fragment>
	);
};

export default NavLinksStaff;
