import React from 'react';
import { Link } from 'react-router-dom';
import { FLASHCARDS_LIST } from '../../../utils/ROUTES';
import { FirestoreContext } from '../../../utils/store/context/firestoreContext';
import { scrollToTop } from '../../../utils/helpers';
import Front from './Front';
import Back from './Back';
import ImageUpload from './ImageUpload';
import LoadingFull from '../../Helpers/LoadingFull';

const AddFlashcard = () => {
	const { addFlashcard, getCategories, categories } =
		React.useContext(FirestoreContext);

	const [front, setFront] = React.useState('');
	const [back, setBack] = React.useState('');
	const [image, setImage] = React.useState('');
	const [category, setCategory] = React.useState([]);
	const [isDoneEditing, setIsDoneEditing] = React.useState(false);
	const [submitted, setSubmitted] = React.useState(false);
	const [loading, setLoading] = React.useState(false);

	React.useEffect(() => {
		categories.length <= 0 && getCategories();
	}, []);

	React.useEffect(() => {
		setIsDoneEditing(false);
	}, [front, back, category]);

	const handleSubmit = async (e) => {
		e.preventDefault();
		// setLoading(true);
		let newCard = {
			front,
			back,
			image,
			categories: category,
		};
		await addFlashcard(newCard);
		setSubmitted(true);
		// alert('Flashcard added succesfully.');
		setFront('');
		setBack('');
		setCategory([]);
		setImage(null);
		setSubmitted(false);
		setTimeout(() => {
			setLoading(false);
		}, 1500);
		scrollToTop();
	};

	const handleCategory = (e) => {
		console.info(e.target.id);
		let categoryArray = category.length > 0 ? [...category] : [];
		let target = e.target.id;

		if (category.includes(target)) {
			let index = categoryArray.indexOf(target);
			categoryArray.splice(index, 1);
			setCategory([...categoryArray]);
		} else {
			categoryArray.push(target);
			setCategory([...categoryArray]);
		}
	};

	if (loading) {
		return <LoadingFull message='Flashcard added successfully...' />;
	}

	return (
		<div className='container mt-4'>
			<div className='right-align'>
				{/* <button onClick={() => window.history.back()}>Back to Dashboard</button> */}
				<Link to={FLASHCARDS_LIST}>Devuelta al Dashboard</Link>
			</div>
			<h2 className='txt-brand2'>Crea Tus Flashcards</h2>
			<form onSubmit={handleSubmit}>
				{/* //!THIS NEEDS TO BE A QUILL TEXT INPUT */}
				<div className='my-2'>
					<label htmlFor='front'>
						Parte visible del flashcard - "Pregunta"
					</label>
					<Front
						value={front}
						action={(content) =>
							setFront(content.replace(/(<p><br><\/p>)/g, ''))
						}
						submitted={submitted}
						tabIndex='1'
					/>
				</div>
				<div className='my-2'>
					<label htmlFor='back'>
						Parte escondida del flashcard - "Repuesta"
					</label>
					<Back
						value={back}
						action={(content) =>
							setBack(content.replace(/(<p><br><\/p>)/g, ''))
						}
						submitted={submitted}
						tabIndex='2'
					/>
				</div>
				<div className='my-2'>
					<label htmlFor='back'>Incluye una imagen</label>
					<ImageUpload
						onUpload={(path) => setImage(path)}
						imgFolder='flashcard_images'
					/>
				</div>
				<div className='my-2'>
					<h6>Categorías</h6>
					{categories.map((c) => {
						return (
							<div key={c.id}>
								<label>
									<input
										className={category.includes(c.id) && 'filled-in'}
										id={c.id}
										type='checkbox'
										checked={category.includes(c.id)}
										onChange={handleCategory}
									/>
									<span>{c.name}</span>
								</label>
							</div>
						);
					})}
				</div>
				<div className='right-align mt-4'>
					{front && back && category.length > 0 && (
						// &&
						// !isDoneEditing && (
						// 	<button
						// 		className='btn bg-red'
						// 		onClick={() => setIsDoneEditing(true)}>
						// 		Done Editing?
						// 	</button>
						// )}
						// {isDoneEditing
						<>
							<button
								type='submit'
								className='btn bg-brand'
								onClick={(e) => {
									setLoading(true);
									handleSubmit(e);
								}}>
								Crear Flashcard
								<i className='material-icons right'>send</i>
							</button>
						</>
					)}
				</div>
			</form>
		</div>
	);
};

export default AddFlashcard;
