import React from 'react';
import { AuthContext } from '../../../utils/store/context/authContext.js';
import LoadingSection from '../../Helpers/LoadingSection.js';
import PostTestConsultationSurvey from '../surveys/PostTestConsultationSurvey.js';
import ReviewRequest from '../surveys/ReviewRequest';
import PostTestGradeConsultationResults from './PostTestGradeConsultationResults.js';

const PostTestDashboard = () => {
	const { user } = React.useContext(AuthContext);
	const [loading, setLoading] = React.useState(false);

	// React.useEffect(() => {
	// 	return () => {
	// 	    cleanup
	// 	}
	// }, [user]);

	if (loading) {
		return <LoadingSection />;
	}

	// USER HASN'T SELECTED ESPECIALIDADES A CONSULTAR
	if (!user.post_test_consultation) {
		return <PostTestConsultationSurvey onFormSubmit={setLoading} />;
	}
	// USER SELECTED ESPECIALIDADES BUT HASN'T MADE DESICION ON REVIEW
	if (user.post_test_consultation && !user.post_test_review) {
		return <ReviewRequest />;
	}

	// USER SELECTED ESPECIALIDADES & DECIDED ON REVIEW
	if (user.post_test_consultation && user.post_test_review) {
		return (
			<PostTestGradeConsultationResults
				interests={user.post_test_consultation}
			/>
		);
	}
	return <div>Por favor comunícate con nosotros.</div>;
};

export default PostTestDashboard;
