import React, { useState, useContext, useEffect, useRef } from 'react';
import { Link, Redirect } from 'react-router-dom';
import {
	firebaseAuth,
	firebaseFirestore,
	logError,
	logEvent,
} from '../../../utils/store/config/fbConfig';
import { AuthContext } from '../../../utils/store/context/authContext';
import { AdminContext } from '../../../utils/store/context/adminContext';
import M from 'materialize-css';
import LogoDisplay from '../LogoDisplay';
import {
	// checkValidation,
	showInvalidMessage,
} from '../../../utils/validation_helpers';
import { isMobileScreen, scrollToTop } from '../../../utils/helpers';
import LoadingFull from '../../Helpers/LoadingFull';

const SignUp = (props) => {
	const { user, updateUser } = useContext(AuthContext);
	const { updateTotalSignups } = useContext(AdminContext);
	//~ REFS
	//#region
	const firstNameRef = useRef();
	const lastNameRef = useRef();
	const dobRef = useRef('');
	const cedulaRef = useRef();
	const phoneRef = useRef();
	const uniRef = useRef();
	const gradYearRef = useRef();
	const emailRef = useRef();
	const passwordRef = useRef();
	const passwordConfirmRef = useRef();
	//#endregion

	//~ SIGNUP CODE
	//#region
	//! If code to signup needed again, just change the below to false
	const signupCode = useRef();
	// // code is prepmed157
	const [showSignupForm, setShowSignupForm] = useState(false);
	const [showWrongCode, setShowWrongCode] = useState(false);
	//#endregion

	// ~INPUT FIELDS STATE
	//#region
	const [loading, setLoading] = useState(false);
	const [email, setEmail] = useState('');
	const [password, setPassword] = useState('');
	const [passwordsDontMatch, setPasswordsDontMatch] = useState(false);
	const [firstName, setFirstName] = useState('');
	const [middleName, setMiddleName] = useState('');
	const [lastName, setLastName] = useState('');
	const [secondLastName, setSecondLastName] = useState('');
	// const [cellPhone, setCellPhone] = useState();
	const [uni, setUni] = useState('N/A');
	const [gradYear, setGradYear] = useState('N/A');
	// const [cedula, setCedula] = useState('');
	const [dob, setDoB] = useState('');
	const [dobFocus, setDoBFocus] = useState(false);
	const [showPasswords, setShowPasswords] = useState(false);
	//#endregion

	//~ VALIDATION STATES
	//#region

	const [allFieldsValid, setAllFieldsValid] = useState(false);
	const [failMessage, setFailMessge] = useState('');
	const [signupErrorMessage, setSignupErrorMessage] = useState('');
	const [showErrorMessage, setShowErrorMessage] = useState('');
	// const [showFormatoValidoCedula, setShowFormatoValidoCedula] = useState(false);
	// const [showFormatoValidoTelefono, setShowFormatoValidoTelefono] = useState(
	// 	false
	// );
	//#endregion

	/*eslint-disable */
	useEffect(() => {
		scrollToTop();
		M.AutoInit();
		// const datePicker = document.querySelectorAll('.datepicker');
		// M.Datepicker.init(datePicker, {
		// 	defaultDate: new Date(1970, 1, 1),
		// 	yearRange: [1970, 2010],
		// 	// changeYear: true,
		// 	format: 'mmmm dd, yyyy',
		// 	i18n: {
		// 		months: [
		// 			'Enero',
		// 			'Febrero',
		// 			'Marzo',
		// 			'Abril',
		// 			'Mayo',
		// 			'Junio',
		// 			'Julio',
		// 			'Agosto',
		// 			'Septiembre',
		// 			'Octubre',
		// 			'Noviembre',
		// 			'Diciembre',
		// 		],
		// 		monthsShort: [
		// 			'Ene',
		// 			'Feb',
		// 			'Mar',
		// 			'Abr',
		// 			'May',
		// 			'Jun',
		// 			'Jul',
		// 			'Ago',
		// 			'Set',
		// 			'Oct',
		// 			'Nov',
		// 			'Dic',
		// 		],
		// 		weekdays: [
		// 			'Domingo',
		// 			'Lunes',
		// 			'Martes',
		// 			'Miércoles',
		// 			'Jueves',
		// 			'Viernes',
		// 			'Sábado',
		// 		],
		// 		weekdaysShort: ['Dom', 'Lun', 'Mar', 'Mie', 'Jue', 'Vie', 'Sab'],
		// 		weekdaysAbbrev: ['D', 'L', 'M', 'M', 'J', 'V', 'S'],
		// 	},
		// 	// onClose: handleDateChange(),
		// 	autoClose: true,
		// });
		// console.info(instance);
	}, []);

	useEffect(() => {
		M.AutoInit();
	}, [loading, showSignupForm]);
	/*eslint-enable */

	//~ LOGIC
	//#region
	//~ HELPERS
	//#region
	const checkPasswordsMatch = (e) => {
		if (passwordRef.current?.value === passwordConfirmRef.current?.value) {
			setPasswordsDontMatch(false);
		} else {
			setPasswordsDontMatch(true);
		}
	};

	const handleDateChange = () => {
		if (dob !== '' || dob !== dobRef.current.value) {
			setDoB(dobRef.current.value);
		}
	};

	// const capitalizeFirstLetter = (string) => {
	// 	string.toLowerCase();
	// 	return string.charAt(0).toUpperCase() + string.slice(1);
	// };

	// const openDatepicker = (e) => {
	// 	dobRef.current.focus();
	// 	dobRef.current.M_Datepicker.open();
	// };

	// const openUniSelect = (e) => {
	// 	uniRef.current.focus();
	// 	uniRef.current.M_FormSelect.open();
	// };

	const checkIfAllFieldsValid = () => {
		setShowErrorMessage('');

		// console.info(
		// 	'called checkIfAllFieldsValid',
		// 	'dob.length:',
		// 	dob.length,
		// 	dob.length > 0
		// );
		if (
			firstNameRef.current.validity.valid &&
			lastNameRef.current.validity.valid &&
			emailRef.current.validity.valid &&
			passwordRef.current.validity.valid &&
			passwordRef.current.value === passwordConfirmRef.current.value &&
			// phoneRef.current.validity.valid &&
			// cedulaRef.current.validity.valid &&
			dobRef.current.validity.valid &&
			uni !== 'N/A' &&
			uni !== '' &&
			gradYear !== 'N/A' &&
			gradYear !== ''
		) {
			setSignupErrorMessage(false);
			setAllFieldsValid(true);
			setShowErrorMessage('');
			// console.info('All fields valid.');
			return;
		} else {
			setAllFieldsValid(false);
			if (!firstNameRef.current.validity.valid) {
				setShowErrorMessage(
					'Por favor verifica el campo "Primer Nombre". Nota: No se permiten espacios.'
				);
				return;
			}
			if (!lastNameRef.current.validity.valid) {
				setShowErrorMessage(
					'Por favor verifica el campo "Primer Apellido". Nota: No se permiten espacios.'
				);
				return;
			}
			if (!dobRef.current?.validity.valid) {
				setShowErrorMessage(
					'Por favor verifica el campo "Fecha de Nacimiento". El único formato aceptado es: **/**/**** y el año debe empezar con 1 o 2 (ej. 1990 o 2000).'
				);
				return;
			}
			// if (!cedulaRef.current.validity.valid) {
			// 	setShowErrorMessage('Por favor verifica el campo "Cedula".');
			// 	return;
			// }

			if (uni === 'N/A' || uni === '') {
				setShowErrorMessage('Por favor verifica el campo "Universidad".');
				return;
			}
			if (gradYear === 'N/A' || gradYear === '') {
				setShowErrorMessage(
					'Por favor verifica el campo "Año de Graduacción".'
				);
				return;
			}

			if (!emailRef.current.validity.valid) {
				setShowErrorMessage(
					'Por favor verifica el campo "Correo Electrónico".'
				);
				return;
			}
			// if (!phoneRef.current.validity.valid) {
			// 	setShowErrorMessage(
			// 		'Por favor verifica el campo "Telefono de Contacto".'
			// 	);
			// 	return;
			// }
			if (!passwordRef.current.validity.valid) {
				setShowErrorMessage(
					'La contraseña debe tener al menos 1 letra mayúscula, 1 minúscula, 1 numero y un mínimo de 6 caracteres.'
				);
				return;
			}
			if (passwordRef.current?.value !== passwordConfirmRef.current?.value) {
				setShowErrorMessage('Las contraseñas no son iguales.');
				return;
			}
		}
	};
	//#endregion

	//~ REPORT INVALID FIELDS
	//#region
	const reportInvalid = (e) => {
		e.target.setCustomValidity(
			'Por favor llena este campo con información válida.'
		);
		// e.target.reportValidity();
		e.stopPropagation();
		// setTimeout(() => {
		// 	e.target.setCustomValidity('');
		// }, 3000);
	};

	//#endregion

	const handleSubmitOfSignUpCode = (e) => {
		e.preventDefault();
		if (signupCode.current.value.toLowerCase() === 'prepmed157') {
			setShowSignupForm(true);
		} else {
			setShowWrongCode(true);
		}
	};

	const handleChange = (e) => {
		// console.log('HANDLE CHANGE', e.target.value);
		e.target.setCustomValidity('');
		switch (e.target.id) {
			case 'email':
				// checkValidation(e);
				setEmail(e.target.value.toLowerCase());
				break;
			case 'password':
				// checkValidation(e);
				checkPasswordsMatch(e);
				setPassword(e.target.value);
				break;
			case 'password-confirmation':
				// checkValidation(e);
				checkPasswordsMatch(e);
				break;
			case 'firstName':
				setFirstName(e.target.value);
				break;
			case 'middleName':
				setMiddleName(e.target.value);
				break;
			case 'lastName':
				// checkValidation(e;
				setLastName(e.target.value);
				break;
			case 'secondLastName':
				setSecondLastName(e.target.value);
				break;
			case 'date-of-birth':
				// console.info(dobRef.current?.validity);
				setDoB(e.target.value);
				break;
			case 'universidad':
				setUni(e.target.value);
				break;
			case 'graduando':
				setGradYear(e.target.value);
				break;
			// case 'celular':
			// 	checkValidation(e);
			// 	if (e.target.validity.valid) {
			// 		setCellPhone(e.target.value);
			// 	}
			// 	break;
			// case 'cedula':
			// 	checkValidation(e);
			// 	if (e.target.validity.valid) {
			// 		setCedula(e.target.value);
			// 	}
			// 	break;
			default:
				break;
		}
	};

	const handleSubmit = (e) => {
		e.preventDefault();
		scrollToTop();
		setLoading(true);

		let uuid;

		firebaseAuth
			.createUserWithEmailAndPassword(email, password)
			.then((res) => {
				// uuid = res.user.uid;
				// console.log(res.user.uid);
				updateTotalSignups();

				if (process.env.NODE_ENV !== 'production') {
					console.info(res);
				}

				firebaseFirestore
					.collection('users')
					.doc(res.user.uid)
					.set({
						firstName: firstName,
						middleName: middleName,
						lastName: lastName,
						secondLastName: secondLastName,
						initials: firstName[0] + lastName[0],
						dateOfBirth: dob,
						universidad: uni,
						gradYear: gradYear,
						// telefonoDeContacto: cellPhone,
						// cedula: cedula,
						email: email,
						signUpDate: new Date(),
						isClient: false,
						paymentCompleted: false,
					})
					.then(() => {
						if (process.env.NODE_ENV !== 'production') {
							console.info('NEW_SIGNUP: Creating user document.');
						}
						updateUser(res.user);

						logEvent('NEW_SIGNUP');
					})
					.catch((err) => {
						if (process.env.NODE_ENV !== 'production') {
							console.info('SIGNUP_ERROR: onCreate of user document.', err);
						}

						logEvent(
							`SIGNUP_ERROR: on create of user document. - ${email} - ${err.code}`
						);
						logError(err, `Error creating user doc for ${email}`);
					});
			})
			.catch(async (err) => {
				if (process.env.NODE_ENV !== 'production') {
					console.info(
						'SIGNUP_ERROR: onCreate of user with Email and Password',
						err
					);
				}

				// logEvent(`SIGNUP_ERROR: on createWithEmailAndPassword - ${err.code}`);
				// logEvent('SIGNUP_ERROR: email&pass');
				let errorCode = err.code;
				logEvent(errorCode);
				logError(err, `${err.code} for ${email}`);

				// console.info('SIGNUP_ERROR', err);
				//! HANDLE DIFFERENT ERROR CODES HERE
				// https://firebase.google.com/docs/auth/admin/errors
				if (err.code === 'auth/email-already-exists') {
					setSignupErrorMessage(
						`Ya existe un usuario con este correo. Por favor cambia el correo electrónico o dirigete a la nuestra página de acceso si olvidaste tu contraseña.`
					);
					logEvent('SIGNUP_ERROR: email-already-exists');
					logError(err, email);
				}
				if (err.code === 'auth/email-already-in-use') {
					setSignupErrorMessage(
						`Ya existe un usuario con este correo. Por favor cambia el correo electrónico o dirigete a la nuestra página de acceso si olvidaste tu contraseña.`
					);
					logEvent('SIGNUP_ERROR: email-already-in-use');
					logError(err, email);
				}
				if (err.code === 'auth/invalid-email') {
					setSignupErrorMessage(
						'El formato del correo electrónico es inválido.'
					);
					logEvent('SIGNUP_ERROR: invalid-email');
					logError(err, email);
				}
				if (err.code === 'auth/invalid-password') {
					setSignupErrorMessage(
						'La contraseña debe tener un míninmo de 6 digitos.'
					);
					logEvent('SIGNUP_ERROR: invalid-password');
					logError(err, password);
				}
				if (err.code === 'auth/invalid-phone-number') {
					setSignupErrorMessage('El formato del telefono es incorrecto.');
					logEvent('SIGNUP_ERROR: invalid-phone-number');
				}
				if (err.code === 'auth/phone-number-already-exists') {
					setSignupErrorMessage('El número de telefono ya existe.');
					logEvent('SIGNUP_ERROR: phone-number-exists');
				}
				if (err.message === 'invalid date') {
					setSignupErrorMessage('La fecha de nacimiento es invalida.');
					logEvent('SIGNUP_ERROR: invalid date');
				}
				// window.location.reload();
				setPassword('');
				setPasswordsDontMatch(true);
				setEmail('');
				setFailMessge(
					'Debes re-ingresar tu correo y contraseña para continuar.'
				);
				setAllFieldsValid(false);
				setTimeout(() => {
					setLoading(false);
				}, 1000);
			});

		if (user.uid) {
			setTimeout(() => {
				setLoading(false);
			}, 1000);
			return <Redirect to='/dashboard' />;
		}
	};
	//#endregion
	if (loading) {
		return <LoadingFull message='Creando tu cuenta...' />;
	}

	return (
		<div>
			<div className='container hide-on-small-and-down'>
				<LogoDisplay />
			</div>

			{/* //! CODE FORM */}
			{!showSignupForm && (
				<React.Fragment>
					<div className='container'>
						<div className='center'>
							<h3>¡Importante!</h3>
							<div className='left-align my-4'>
								<p
									style={{ maxWidth: '70%', margin: '0 auto' }}
									className='mb-2'>
									Por el momento no estamos aceptando nuevas inscripciones ya
									que estamos enfocados en completar ciertas mejoras basadas en
									la experiencia de los que tomaron el ENURM 2021. Pero no te
									preocupes, ¡PrepMed vuelve con un aún mejor plataforma y
									contenido!
								</p>
								<p
									style={{ maxWidth: '70%', margin: '0 auto' }}
									className='mb-2'>
									Si quieres prepararte para el ENURM 2022 con nosotros, lo
									mejor que puedes hacer es anotarte en nuestra lista de espera
									visitando este enlace:
								</p>
							</div>
							<Link className='btn bg-brand' to='/lista-de-espera-enurm-2022'>
								<i className='material-icons right'>send</i>
								Lista de Espera ENURM 2022
							</Link>
						</div>
					</div>
					{/* <div className='d-none'>
						<p>
							En estos momentos estamos aceptando aplicaciones limitadas y "en
							orden de llegada". Si estás interesado, te recomendamos
							inscrirbete hoy y estaremos en contacto contigo desde que haya
							cupo.
						</p>
					</div> */}
					<div className='container' hidden>
						<form onSubmit={handleSubmitOfSignUpCode} className='center'>
							<div className='row' style={{ maxWidth: '70%' }}>
								<div className='col s12 m6'>
									<div className='input-field'>
										<label
											htmlFor='this'
											onClick={(e) => signupCode.current.focus()}>
											Código de Inscripción
										</label>
										<input type='text' ref={signupCode} maxLength='10' />
										{showWrongCode && (
											<p className='txt-danger'>Código invalido.</p>
										)}
									</div>
								</div>
								<div className='col s12 m6'>
									<div className='input-field '>
										<button
											className='btn bg-brand'
											onClick={handleSubmitOfSignUpCode}>
											<i className='material-icons right'>send</i>
											Submit
										</button>
									</div>
								</div>
							</div>
						</form>
					</div>
				</React.Fragment>
			)}

			{/* //!SIGNUP FORM */}
			{showSignupForm && (
				<div className='container'>
					<form
						onSubmit={handleSubmit}
						onChange={() => {
							checkIfAllFieldsValid();

							handleDateChange();
						}}
						className='white row'
						autoComplete='off'>
						<h3 className='center s12 mb-3 txt-brand2'>Crea tu Cuenta</h3>
						{/*//~ NAME */}
						<div className='input-field col s12 m6'>
							<i className='material-icons prefix txt-brand2'>account_box</i>
							<label htmlFor='firstName'>Primer Nombre</label>
							<input
								type='text'
								id='firstName'
								onChange={handleChange}
								ref={firstNameRef}
								onInvalid={(event) =>
									showInvalidMessage(
										event,
										'Este campo no permite espacios en blanco.'
									)
								}
								pattern='\S+'
								autoComplete='off'
								required
								autoFocus
							/>
							<span
								className={firstNameRef.current?.validity && 'invalid'}></span>

							<sup className='ml-4'>(requerido)</sup>
						</div>
						<div className='input-field col s12 m6'>
							<i className='material-icons prefix txt-brand2'>account_box</i>
							<label htmlFor='middleName'>Segundo Nombre</label>
							<input type='text' id='middleName' onChange={handleChange} />
							<sup className='ml-4'>(opcional)</sup>
						</div>
						<div className='input-field col s12 m6'>
							<i className='material-icons prefix txt-brand2'>account_box</i>
							<label htmlFor='lastName'>Primer Apellido</label>
							<input
								type='text'
								id='lastName'
								ref={lastNameRef}
								onChange={handleChange}
								onInvalid={(event) =>
									showInvalidMessage(
										event,
										'Este campo no permite espacios en blanco.'
									)
								}
								pattern='\S+'
								autoComplete='off'
								required
							/>
							<span
								className={lastNameRef.current?.validity && 'invalid'}></span>
							<sup className='ml-4'>(requerido)</sup>
						</div>
						<div className='input-field col s12 m6'>
							<i className='material-icons prefix txt-brand2'>account_box</i>
							<label htmlFor='secondLastName'>Segundo Apellido</label>
							<input type='text' id='secondLastName' onChange={handleChange} />
							<sup className='ml-4'>(opcional)</sup>
						</div>

						{/* CEDULA	 */}
						{/* <div className='input-field col s12 m6'>
						<i className='material-icons prefix txt-brand2'>portrait</i>
						<label htmlFor='cedula'>Número de Cédula</label>
						<input
							type='text'
							id='cedula'
							ref={cedulaRef}
							onFocus={(e) => setShowFormatoValidoCedula(true)}
							onBlur={(e) => setShowFormatoValidoCedula(false)}
							onChange={handleChange}
							minLength={11}
							maxLength={13}
							pattern='[0-9]{3}[-][0-9]{7}[-][0-9]|[0-9]{11}'
							autoComplete='off'
						/>
						<span className={cedulaRef.current?.validity && 'invalid'}></span>
						<sup className='ml-4'>(opcional)</sup>
					</div>
					{showFormatoValidoCedula && (
						<div className='col s12'>
							<div className='col offset-m6 m6 s12'>
								<p className='center fsize-1 txt-danger m-0'>
									Formatos Válidos: *** - ******* - * | ***********
								</p>
							</div>
						</div>
					)} */}
						{/* UNIVERSIDADES */}
						<div className='input-field col s12 m6'>
							<i className='material-icons prefix txt-brand2'>apartment</i>
							{!uni && <label htmlFor='universidad'>Universidad</label>}
							<select
								name='universidad'
								id='universidad'
								ref={uniRef}
								onChange={handleChange}
								defaultValue={'N/A'}
								required>
								<option value='N/A' disabled>
									-Selecciona Una-
								</option>
								<option value='INTEC'>INTEC</option>
								<option value={`O&M`}>{`O&M`}</option>
								<option value='PUCMM'>PUCMM</option>
								<option value='UASD'>UASD</option>
								<option value='UCATECI'>UCATECI</option>
								<option value='UCE'>UCE</option>
								<option value='UCNE'>UCNE</option>
								<option value='UNIBE'>UNIBE</option>
								<option value='UNPHU'>UNPHU</option>
								<option value='UTESA'>UTESA</option>
								<option value='otra'>Otra universidad</option>
							</select>
							{uni && <label htmlFor='universidad'>Universidad</label>}
							<sup className='ml-4'>(requerido)</sup>
						</div>
						{/* GRADUACION | GRADYEAR */}
						<div className='input-field col s12 m6'>
							<i className='material-icons prefix txt-brand2'>school</i>
							{!gradYear && (
								<label htmlFor='graduando'>Año de Graduación</label>
							)}
							<select
								name='graduando'
								id='graduando'
								ref={gradYearRef}
								onChange={handleChange}
								defaultValue={'N/A'}
								required>
								<option value='N/A' disabled>
									-Selecciona Uno-
								</option>
								<option value='2003'>2003</option>
								<option value='2004'>2004</option>
								<option value='2005'>2005</option>
								<option value='2006'>2006</option>
								<option value='2007'>2007</option>
								<option value='2008'>2008</option>
								<option value='2009'>2009</option>
								<option value='2010'>2010</option>
								<option value='2011'>2011</option>
								<option value='2012'>2012</option>
								<option value='2013'>2013</option>
								<option value='2014'>2014</option>
								<option value='2015'>2015</option>
								<option value='2016'>2016</option>
								<option value='2017'>2017</option>
								<option value='2018'>2018</option>
								<option value='2019'>2019</option>
								<option value='2020'>2020</option>
								<option value='2021'>2021</option>
								<option value='2022'>2022</option>
								<option value='2023'>2023</option>
								<option value='2024'>2024</option>
								<option value='2025'>2025</option>
								<option value='2026'>2026</option>
								<option value='2027'>2027</option>
								<option value='2028'>2028</option>
								<option value='2029'>2029</option>
								<option value='2030'>2030</option>
								<option value='otro'>Otro año</option>
							</select>
							{gradYear && <label htmlFor='graduando'>Año de Graduación</label>}
							<sup className='ml-4'>(requerido)</sup>
						</div>
						{/* EMAIL */}
						<div className='input-field col s12 m6'>
							<i className='material-icons prefix txt-brand2'>email</i>
							<label htmlFor='email'>Correo Electrónico</label>
							<input
								type='email'
								id='email'
								onChange={handleChange}
								ref={emailRef}
								pattern='[\S]+@[\S]+\.[\S]+'
								value={email}
								required
							/>
							<span className={emailRef.current?.validity && 'invalid'}></span>
							<sup className='ml-4'>(requerido)</sup>
						</div>
						{/* CELULAR	 */}
						{/* <div className='input-field col s12 m6'>
						<i className='material-icons prefix txt-brand2'>phone</i>
						<label htmlFor='celular'>Telefono de Contacto</label>
						<input
							type='tel'
							id='celular'
							onFocus={(e) => setShowFormatoValidoTelefono(true)}
							onBlur={(e) => setShowFormatoValidoTelefono(false)}
							onChange={handleChange}
							minLength={10}
							maxLength={14}
							ref={phoneRef}
							pattern='[0-9]{3}[-][0-9]{3}[-][0-9]{4}|[0-9]{1}[-][0-9]{3}[-][0-9]{3}[-][0-9]{4}|[0-9]{10}|[0-9]{11}'
							onInvalid={(event) =>
								showInvalidMessage(
									event,
									'El formato ingresado es inválido o está incompleto.'
								)
							}
							autoComplete='off'
							required
						/>
						<span className={phoneRef.current?.validity && 'invalid'}></span>
						<sup className='ml-4'>(requerido)</sup>
					</div>
					{showFormatoValidoTelefono && (
						<div className='col s12'>
							<div className='col offset-m6 m6 s12'>
								<ul className='center fsize-1 txt-danger m-0'>
									<li>
										<b>Formatos Válidos:</b>
									</li>
									<li>1 - *** - *** - ****</li>
									<li>*** - *** - ****</li>
									<li>1**********</li>
									<li>**********</li>
								</ul>
							</div>
						</div>
					)} */}
						{/* DATE OF BIRTH */}
						{/* <div className='input-field col s12 m6'>
						<i className='material-icons prefix txt-brand2'>event</i>
						<label htmlFor='fecha-de-nacimiento' onClick={openDatepicker}>
							Fecha de Nacimiento
						</label>
						<input
							type='text'
							id='date-of-birth'
							className='datepicker'
							ref={dobRef}
							onFocus={openDatepicker}
							onInvalid={reportInvalid}
							onBlur={(e) => e.target.setCustomValidity('')}
							required
						/>
						<sup className='ml-4'>(requerido)</sup>
					</div> */}
						<div className='input-field col s12 m6'>
							<i className='material-icons prefix txt-brand2'>event</i>
							<label htmlFor='date-of-birth'>Fecha de Nacimiento</label>
							<input
								type='text'
								id='date-of-birth'
								ref={dobRef}
								onFocus={() => setDoBFocus(true)}
								onBlur={() => setDoBFocus(false)}
								onChange={handleChange}
								pattern='(\d){2}/(\d){2}/(1|2)(\d){3}'
								maxLength='10'
								required
							/>
							<span className={dobRef.current?.validity && 'invalid'}></span>
							<sup className='ml-4' hidden={dobFocus}>
								(requerido)
							</sup>
							{dobFocus && (
								<p
									className={`invalid-text ${
										dobRef.current?.validity?.valid && 'txt-success'
									} d-inline`}>
									{dobRef.current?.validity?.valid
										? 'Válido'
										: 'Formato inválido, debe ser: MM/DD/AAAA'}
								</p>
							)}

							{/* <span hidden={!dobFocus} className='align-right'>
							Formato Válido: **-**-****
						</span> */}
						</div>
						{/* PASSWORD */}
						<div className='input-field col s12 m6'>
							<i className='material-icons prefix txt-brand2'>lock</i>
							<label htmlFor='password'>Contraseña</label>
							<input
								type={showPasswords ? 'text' : 'password'}
								id='password'
								ref={passwordRef}
								onChange={handleChange}
								// minLength={6}
								// pattern='^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).{6,}$'
								autoComplete='off'
								value={password}
								required
							/>
							<i
								className='material-icons'
								hidden={passwordRef.current?.validity?.valid}
								onClick={() => setShowPasswords(!showPasswords)}>
								visibility
							</i>
							<span></span>
							<sup
								className='ml-4'
								hidden={!passwordRef.current?.validity?.valid}>
								(requerido)
							</sup>
							<p
								className='invalid-text'
								hidden={passwordRef.current?.validity?.valid}>
								{/* (requerido)  */}
								Debe tener al menos 1 letra mayúscula, 1 minúscula, 1 numero y
								un mínimo de 6 caracteres.
							</p>
						</div>
						{/* CONFIRMACION DE PASSWORD */}
						<div className='input-field col s12 m6'>
							<i className='material-icons prefix txt-brand2'>lock_open</i>
							<label htmlFor='password-confirmation'>
								Confirma tu contraseña
							</label>
							<input
								type={showPasswords ? 'text' : 'password'}
								id='password-confirmation'
								onChange={handleChange}
								ref={passwordConfirmRef}
								// minLength={6}
								// pattern='^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).{6,}$'
								onInvalid={(event) =>
									showInvalidMessage(event, 'Las contraseñas no son iguales.')
								}
								autoComplete='off'
								required
							/>
							<i
								className='material-icons'
								hidden={!passwordsDontMatch}
								onClick={() => setShowPasswords(!showPasswords)}>
								visibility
							</i>
							<span hidden={passwordsDontMatch}></span>
							<sup className='ml-4' hidden={passwordsDontMatch}>
								(requerido)
							</sup>
							<p
								className='invalid-text'
								hidden={
									passwordConfirmRef.current?.value ===
									passwordRef.current?.value
								}>
								{/* (requerido)  */}
								Las contraseñas no son iguales.
							</p>
						</div>
						{/* VALIDATION ERRORS */}
						<div className='row'>
							<div
								className='txt-danger center-align col s12'
								style={{ display: 'block' }}>
								{signupErrorMessage && (
									<p className='bg-danger p-1 white-text center'>
										{signupErrorMessage}{' '}
										{failMessage && <span>{failMessage}</span>}
									</p>
								)}
								{!allFieldsValid && (
									<p className='bg-danger p-1 white-text center'>
										Por favor completa todos los campos requeridos para
										continuar. {showErrorMessage && showErrorMessage}
									</p>
								)}
							</div>
						</div>
						{/* SIGNUP BUTTON */}
						{allFieldsValid && (
							<div>
								<p className='txt-brand2'>
									Al presionar "Completar Registro" tu cuenta será creada
									inmediatamente. Una vez realices el pago, podrás acceder a la
									plataforma. También, estás aceptando nuestros{' '}
									<Link
										to='/terminos-de-servicio'
										target='_blank'
										className='normal-links'>
										Términos de Servicio
									</Link>
									, y nuestras{' '}
									<Link
										to='/terminos-de-servicio'
										target='_blank'
										className='normal-links'>
										Políticas de Privacidad
									</Link>
									. Tu información no será compartida, ni vendida. ¡Gracias por
									prepararte con nosotros!
								</p>
							</div>
						)}
						<div
							className={
								isMobileScreen ? 'center-align mb-4' : 'right-align mb-4'
							}>
							<button
								className={`${
									isMobileScreen && 'hide-on-small-and-down'
								} btn btn-large bg-secondary mr-2`}
								type='reset'>
								<i className='material-icons left'>refresh</i>
								Volver a Empezar
							</button>
							<button
								disabled={!allFieldsValid}
								className='btn btn-large bg-brand'
								onClick={(e) => setDoB(dobRef.current.value)}
								type='submit'>
								Completar Registro
								<i className='material-icons right'>send</i>
							</button>
						</div>
					</form>
				</div>
			)}
		</div>
	);
};

export default SignUp;
